.content-tile__body {
    display: block;
    width: 300px; 

    .hotspot-item {
        background-color: $styleguide-black;
        box-shadow: -3px 3px 1px $styleguide-grey-400;
        color: $styleguide-white !important;
        display: block;
        font-size: 1.5rem !important;
        line-height: 1.3 !important;
        text-decoration: none !important;
        text-transform: uppercase;
    }

    @include media-breakpoint-down(sm) {
        display: none;
    }
}