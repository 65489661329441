.form-group {
    .form-control-feedback {
        display: none;
    }

    &.has-danger {
        .form-control-feedback {
            display: block;
        }
    }
    &.has-danger {
        &.salutation {
            label {
                color: $error-color;
            }
        }
    }

    &.required .form-control-label::before {
        content: '*';
    }

    > .form-control-feedback {
        display: none;
    }

    &.has-danger,
    &.has-warning {
        > .form-control {
            border-color: $error-color;
        }

        > .form-control-label {
            color: inherit;
        }

        > .form-control-feedback {
            color: $error-color;
            display: block;
        }
    }
}
