$breadcrumb-use-separator:              true !default;
$breadcrumb-separator-color:            $primary-brand-color !default;
$breadcrumb-separator-whitespace:       5px !default;

$breadcrumb-font-type:                  'default' !default;

$breadcrumb-default-color:              $text-color-primary !default;
$breadcrumb-default-underline:          false !default;

$breadcrumb-last-color:                 $text-color-primary !default;
$breadcrumb-last-underline:             false !default;

$breadcrumb-hover-color:                $primary-brand-color !default;
$breadcrumb-hover-underline:            false !default;

$breadcrumbContext: '.breadcrumb';

#{$breadcrumbContext} {
    @include list-reset;

    &__item {
        display: inline-block;
        @include font-type($breadcrumb-font-type);

        @if ($breadcrumb-use-separator) {
            & + & {
                padding-left: $breadcrumb-separator-whitespace;
                
                &::before {
                    color: $breadcrumb-separator-color;
                    content: "/";
                    display: inline;
                    font-family: inherit;
                    font-size: inherit;
                    letter-spacing: inherit;
                    padding-right: $breadcrumb-separator-whitespace;
                }
            }
        }
    }

    &__item:last-child {
        #{$breadcrumbContext}__link {
            color: $breadcrumb-last-color;

            @if ($breadcrumb-last-underline) {
                text-decoration: underline;
            } @else {
                text-decoration: none;
            }
        }
    }

    & &__link {
        color: $breadcrumb-default-color;
        font-family: inherit;
        font-size: inherit;
        letter-spacing: inherit;
        
        @if ($breadcrumb-default-underline) {
            text-decoration: underline;
        } @else {
            text-decoration: none;
        }
        
        &:hover,
        &:focus,
        &:active {
            color: $breadcrumb-hover-color;

            @if ($breadcrumb-hover-underline) {
                text-decoration: underline;
            } @else {
                text-decoration: none;
            }
        }
    }
}

