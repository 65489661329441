.site-search {
    padding: 0;
    
    @include media-breakpoint-up(lg) {
        position: relative;
    }

    .search-icon {
        color: $search-icon-color--mobile;
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        
        @include media-breakpoint-up(lg) {
            color: $search-icon-color--desktop;
            position: relative;
            right: 0;
            right: unset;
            top: unset;
            transform: none;
        }
    }
    
    .search-field {
        @include media-breakpoint-up(lg) {
            @include transition();
            opacity: 0;
            position: absolute;
            right: -10px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
        }
        
        &--container {
            position: relative;
        }
    }

    &.active {
        .search-field {
            @include media-breakpoint-up(lg) {
                opacity: 1;
                width: $search-field-width-active;
            }
        }
    }
}
