// Component variables
$header-box-shadow: 0 2px 4px 0 rgba($styleguide-grey-800, .1) !default;

$country-selector-toggle-pseudo-appearance: none !default;

$country-selector-option-color: $styleguide-white !default;

$country-selector-dropdown-background-color: $background-color !default;
$country-selector-dropdown-border: 0 !default;
$country-selector-dropdown-border-radius: 0 !default;
$country-selector-dropdown-box-shadow: -2px -2px 4px 2px rgba(0, 0, 0, 0.1);

$country-selector-flag-background-size: cover !default;
$country-selector-flag-border-radius: 50% !default;
$country-selector-flag-size: 20px !default;

// Component styles
.country-selector {
    @include media-breakpoint-up(lg) {
        align-items: center;
        display: flex;
        height: inherit;
    }

    &__list {
        @include list-reset;
    }

    &__toggle,
    &__option {
        align-items: center;
        display: flex;
    }

    &__toggle {
        @include media-breakpoint-up(lg) {
            align-items: center;
            display: flex;
            height: 100%;
        }

        &::after {
            display: $country-selector-toggle-pseudo-appearance;
        }
    }

    &__dropdown {
        background-color: $country-selector-dropdown-background-color;
        box-shadow: $country-selector-dropdown-box-shadow;
        border-radius: $country-selector-dropdown-border-radius;
        margin: 10px 0 0 -20px;
        z-index: 1;
        padding: 10px 10px 0px 18px;

        .close-btn-wrapper {
            padding-bottom: 10px;
        }

        .icon {
            font-size: 11px;
        }

        @include media-breakpoint-up(lg) {
            margin: 15px 0 0 0;
            padding: 15px;
            width: initial;
        }

        .dropdown-wrapper {
            display: flex;
            padding: 15px;
        }

        &.dropdown-menu {
            &.dropdown-menu-center {
                &.show {
                    transform: translate3d(-50px, 20px, 0px) !important; // used !important to override inline styling!

                    @include media-breakpoint-up(lg) {
                        transform: translate3d(-86px, 20px, 0px) !important; // same here!
                    }
                }
            }
        }
    }

    &__arrow {
        content: "";
        right: 4.6rem;
        display: none;
        position: absolute;
        top: -10px;
        border-bottom: 15px solid white;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;

        @include media-breakpoint-up(md) {
            display: block;
        }

        @include media-breakpoint-up(lg) {
            right: 5rem;
        }
    }

    &__toggle,
    &__dropdown {
        .link {
            &__label {
                margin: 0 0 0 10px;
            }
        }
    }

    &__list,
    &__toggle,
    &__dropdown {
        .flag-icon {
            background-size: $country-selector-flag-background-size;
            border-radius: $country-selector-flag-border-radius;
            height: $country-selector-flag-size;
            width: $country-selector-flag-size;
        }
    }

    &__option {
        color: $styleguide-white;
        padding: 0 0 22px 0px;

        @include media-breakpoint-up(lg) {
            margin: 0;
        }

        @include media-breakpoint-up(lg) {
            padding: 10px;

            &:first-child {
                padding-top: 0;
            }
        }
    }

    .quicklinks__link {
        .link__label {
            &.country {
                font-size: 13px;
                font-weight: 600;
                line-height: 15.23px;
                margin-left: 15px;
                opacity: 0.5;
                white-space: nowrap;

                &.selected {
                    opacity: 1;
                }
            }
        }
    }

    .dropdown-menu {
        border: 0;
    }
}

// My Account redesign phone number country selector
.form-phone-number {
    position: relative;

    .country-selector {
        flex: 0 0 65px;
        position: static;

        &.show {
            .dropdown-toggle {
                &::after {
                    top: 18px;
                    transform: rotate(-135deg);
                }
            }

            .dropdown-country-selector {
                max-height: 500px;
                opacity: 1;
            }
        }
    }

    .dropdown-toggle {
        position: relative;
        z-index: 10;
    }

    .dropdown-country-selector {
        border: none;
        border-radius: 3px;
        box-shadow: 0 0 5px rgba($styleguide-black, .2);
        display: block;
        margin-top: 0;
        max-height: 42px;
        opacity: 0;
        overflow: hidden;
        padding: 42px 0 0;
        top: -42px !important; // Overriding JS to ensure it always collapses downward
        transition: opacity .3s ease, max-height .3s ease;
        transform: translateY(42px) !important; // Overriding JS to ensure it always collapses downward
        width: 100%;
        z-index: 5;

        ul {
            margin: 0;
            padding: 0;
        }
    }

    .dropdown-item {
        align-items: center;
        display: flex;
        font-size: 14px;
        padding: 10px 18px;
        text-decoration: none;

        &:hover {
            cursor: pointer;
        }
    }

    .flag {
        display: block;
        height: 14px;
        margin-right: 5px;
        width: 14px;
    }
}

.input-field--pickuppoint {
    .country-selector {
        position: absolute;
        cursor: pointer;
        right: 0;
        margin-right: 20px;
        margin-top: 12px;

        .dropdown-toggle {
            min-width: fit-content;

            &::after {
                top: -5px;
                right: 0px;
                left: 5px;
            }
        }
        &__toggle {
            .flag-icon {
                width: 15px;
                height: fit-content;
            }
        }

        &__dropdown {
            margin: .125rem 0 0;
            padding: .5rem 0;

            &.dropdown-menu {
                min-width: 3rem;
                transform: translate3d(-15px, -5px, 0px) !important; // used !important to override inline styling!

                &.dropdown-menu-center {
                    &.show {
                        left: 75px !important;
                        top: -25px !important;
                        transform: translate3d(-90px, 20px, 0px) !important; // same here!
                    }
                }
            }

            .icon {
                margin-right: 5px;
                margin-left: 5px;
            }

            .flag-icon {
                height: fit-content;
                margin-left: 10px;
                margin-bottom: 5px;
                width: 15px;
            }

            .close-btn-wrapper {
                position: absolute;
                right: 0px;
            }
        }

        &__option {
            padding: 0px;
        }
    }
}
