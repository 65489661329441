$background-color: #363e47;
$text-color-primary: #cea362;
$site-min-width: 320px;

.header-cta-login {
    font: 700 12px/1.5rem font-family(primary);
    color: $text-color-primary;
    min-width: $site-min-width;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    @include media-breakpoint-down(sm) {
        padding-right: 0;
        padding-left: 0;
        line-height: 12px;
        font-size: 10px;
    }
}
.header-cta-login-btn {
    cursor: pointer;
    padding-top: 2px;
    padding-bottom: 2px;
    margin: 0 2px;
    line-height: 14px;
    border-radius: 2px;
    border: none;
    font-weight: 700;
    text-transform: uppercase;
    color: $background-color;
    background-color: $text-color-primary;
    @include media-breakpoint-down(sm) {
        line-height: 10px;
    }
}
