$cookie-consent-border:                         $border-medium !default;

$cookie-consent-option-active-subheading:       $primary-brand-color !default;
$cookie-consent-option-inactive-background:     $styleguide-grey-300 !default;

$cookie-consent-feedback-background:            $primary-brand-color !default;
$cookie-consent-feedback-text-color:            $text-color-secondary !default;


.cookie-consent {
    border: $cookie-consent-border;
    border-radius: 4px;
    margin-bottom: 40px;
    overflow: hidden;

    &__option {
        @include transition;
        border-top: $cookie-consent-border;
        cursor: pointer;
        display: block;
        padding: 15px 15px 15px 45px;
        position: relative;

        &:first-child {
            border: none;
        }
    }
    
    &__input {
        top: 13px;
        left: 15px;
        position: absolute;
    }
    
    &__body {
        &::before {
            @include transition(background);
            bottom: 0;
            content: '';
            display: block;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            z-index: -1;
        }

        &.show::before {
            background: $cookie-consent-option-inactive-background;
        }
    }

    &__subheading {
        color: $cookie-consent-option-active-subheading;
    }

    &__button {
        white-space: nowrap;
    }

    &__feedback {
        &__body {
            background: $cookie-consent-feedback-background;
            border-radius: 4px;
            color: $cookie-consent-feedback-text-color;
        }
    }
}
