// Component styles
.home-search {
    background-color: $search-suggestions-background-color;
    padding: $gutter / 2;
}

.site-search {
    .suggestions-wrapper {
        background-color: $search-suggestions-background-color;
        margin: 0 -#{$gutter / 2};

        .container {
            padding: #{$gutter / 2};
        }

        .do-you-mean {
            border-bottom: $search-suggestions-borders;
            padding-bottom: #{$gutter / 2};
            @include media-breakpoint-up(lg) {
                padding-bottom: #{$gutter};
            }
        }

        .suggestions {
            .suggestion {
                &__label {
                    @include font-type(suggestions-label);
                }

                a.suggestions__link {
                    @include font-type(suggestions-link);
                    display: block;
                    text-decoration: none;
                }
            }

            &__heading {
                display: block;
                border-bottom: $search-suggestions-borders;
                padding: 10px 0;
                margin-bottom: 10px;
                color: $styleguide-grey-900;
            }

            a.suggestions__item {
                padding: #{$gutter / 2} 0;
                border-top: $search-suggestions-borders;
                text-decoration: none;

                &:first-child {
                    border: none;
                    padding-top: 0;
                }

                &:hover {
                    .suggestions {
                        &__name {
                            text-decoration: underline;
                        }
                    }
                }
            }

            &__brand {
                @include font-type(suggestions-item-brand);
                color: $suggestion-item-name-color;
            }

            &__name {
                @include font-type(suggestions-item-name);
                color: $suggestion-item-name-color;
            }

            &__price {
                @include font-type(suggestions-item-price);

                *,
                span {
                    font-size: inherit;
                }
            }

            &__show-more {
                border-top: $search-suggestions-borders;
                padding-top: #{$gutter / 2};

                @include media-breakpoint-up(lg) {
                    padding-bottom: #{$gutter / 2};
                }
            }
        }

        .category-results,
        .content-results {
            margin-bottom: 2rem;

            .suggestions__link {
                display: block;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .product-results {
            .suggestions__item {
                .col-img {
                    max-width: 195px;
                }

                .price {
                    font: inherit;
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .suggestions-wrapper {
            background: $search-suggestions-background-color-desktop;
            box-shadow: $search-suggestions-box-shadow;
            margin: 14px -9px 0 0;
            min-width: ($search-field-width-active - 2px); // minus the border
            position: absolute;
            right: 0;
            top: 100%;
            z-index: 1;

            .suggestions {
                overflow: auto;

                &.has-products {
                    width: $search-suggestions-width-products;
                }

                &.has-content {
                    width: $search-suggestions-width-content;
                }

                &.has-products.has-categories,
                &.has-products.has-content {
                    width: $search-suggestions-width-all;
                }

                &__close {
                    color: $search-suggestions-close-button-color;
                    cursor: pointer;
                    font-size: 16px;
                    position: absolute;
                    right: 0;
                    top: 50%;
                }
            }
        }
    }
}
