$sale-price-font-color: $primary-brand-color !default;

.price {
    .sales {
        @include font-type(price-sales);

        display: inline-block;
    }

    .strike-through {
        @include font-type(price-from);

        color: $styleguide-grey-500;
        display: inline-block;
        margin-right: 10px;

        ~ .sales {
            color: $sale-price-font-color;
        }
    }
}

.price__vat-label {
    color: $styleguide-grey-900;
    display: block;
    font-size: 12px;
}
