.video-banner {
    height: var(--videoMobileHeight);
    overflow: hidden;
    position: relative;

    @include media-breakpoint-up(md) {
        height: var(--videoDesktopHeight);
    }
}

.video-banner__video {
    min-height: 100%;
    left: 50%;
    min-width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.video-cta__container {
    display: flex;
    height: 100%;
    left: 0;
    margin: 0 auto;
    padding: 40px;
    position: absolute;
    right: 0;
    top: 0;
    max-width: 1440px;

    // Vertical alignment
    &.top {
        align-items: start;
    }

    &.center-ver {
        align-items: center;
    }

    &.bottom {
        align-items: end;
    }

    // Horizontal alignment
    &.left {
        justify-content: start;
    }

    &.center-hor {
        justify-content: center;
    }

    &.right {
        justify-content: end;
    }

    a.btn {
        background-color: rgba($white, 0.2);
        border-radius: 0;
        border: 1px solid $white;
        color: $white;
        padding: 15px 30px;
        transition: background-color .25s ease-in-out,
                    color .25s ease-in-out;

        @include icon(arrow-right, 'after', 10px);

        &::after {
            padding-left: 10px;
        }

        &:hover {
            background-color: $white;
            color: $styleguide-grey-900;
        }
    }
}

.btn-control {
    background: transparent;
    border: 2px solid $white;
    border-radius: 100%;
    bottom: 15px;
    height: 25px;
    opacity: 0.5;
    position: absolute;
    right: 15px;
    transition: opacity .25s ease-in-out;
    width: 25px;

    @include media-breakpoint-down(md) {
        display: none;
    }

    &:hover {
        opacity: 1;
    }

    &.paused {
        .icon-play {
            display: block;
        }

        .icon-pause {
            display: none;
        }
    }

    .icon-play,
    .icon-pause {
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .icon-play {
        color: $white;
        display: none;
        @include icon(arrow-right, 'after', 10px);
    }

    .icon-pause {
        &::before,
        &::after {
            background-color: $white;
            border-radius: 3px;
            content: '';
            display: block;
            height: 10px;
            width: 3px;
        }

        &::after {
            margin-left: 5px;
        }
    }
}
