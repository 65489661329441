// Component variables

$minicart-quantity-text-align:                    center !default;
$minicart-quantity-color:                         $primary-brand-color !default;

$minicart-icon-size:                              28px !default;
$minicart-icon-color:                             $text-color-primary !default;

$minicart-popover-background-color:               $styleguide-white !default;
$minicart-popover-border:                         0 !default;
$minicart-popover-border-radius:                  0 !default;
$minicart-popover-box-shadow:                     -8px 0 20px 0 rgba($styleguide-grey-800, .15) !default;
$minicart-popover-color:                          $text-color-primary !default;

$product-addtocart-stickybar-height:              65px !default;
$minicart-height-container-whitespace-mobile:     30px !default;
$minicart-height-header-mobile:                   40px !default;
$minicart-height-footer-mobile:                   145px !default;
$minicart-height-body-mobile:                     $minicart-height-container-whitespace-mobile + $minicart-height-header-mobile + $minicart-height-footer-mobile + $product-addtocart-stickybar-height !default;

$minicart-height-container-whitespace-desktop:    50px !default;
$minicart-height-header-desktop:                  45px !default;
$minicart-height-footer-desktop:                  145px !default;
$minicart-height-body-desktop:                    $minicart-height-container-whitespace-desktop + $minicart-height-header-desktop + $minicart-height-footer-desktop !default;

$minicart-header-border-bg:                       $primary-brand-color !default;
$minicart-header-border-size:                     2px !default;
$minicart-body-border:                            0 0 1px solid rgba($styleguide-black, .1) !default;

$minicart-footer-border:                          0 0 1px solid rgba($styleguide-black, .1) !default;
$minicart-footer-font-subtotal:                   'default' !default;
$minicart-footer-font-total:                      'bold' !default;

$minicart-usp-list-item-pseudo-color:             $styleguide-green !default;

$product-line-name-font:                          'h4' !default;

$product-line-item-out-of-stock-background:       $error-color !default;
$product-line-item-out-of-stock-border-radius:    5px !default;
$product-line-item-out-of-stock-border-color:     $text-color-secondary;

$product-color-attribute-background-size:         cover !default;
$product-color-attribute-border-radius:           50%;
$product-color-attribute-size:                    16px !default;



// Component styles
.page {
  &[data-action='Cart-Show'] {
    .minicart-link {
      cursor: default;
      pointer-events: none;
    }
  }
}

a.minicart-link {
  display: inline-block;
  position: relative;
  text-decoration: none;

  .minicart-icon,
  .minicart-quantity {
    display: block;
    line-height: $minicart-icon-size;
    position: relative;
    text-align: $minicart-quantity-text-align;
    transform: translateZ(0); // Prevent jumpy animations on hover for Mac Safari
  }

  .minicart-icon {
    color: $minicart-icon-color;
  }

  .minicart-quantity {
    color: $minicart-quantity-color;
    left: 52.5%;
    margin: -28px 0 0;
    padding: 3px 0 0 1px;
    position: absolute;
    top: 100%;
    transform: translate(-50%, -50%);

    // Both Mac Safari & Chrome have useragent 'Safari'
    [data-useragent*='Macintosh'][data-useragent*='Safari'] &,
    [data-useragent*='Android'] & {
      padding: 4px 0 0;
    }

    [data-useragent*='iPhone'] &,
    [data-useragent*='iPad'] & {
      padding: 5px 0 0;
    }
  }

  .link__label {
    display: none;
  }

}

.minicart-total {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.minicart .popover {
  background: $minicart-popover-background-color;
  border: $minicart-popover-border;
  border-radius: $minicart-popover-border-radius;
  box-shadow: $minicart-popover-box-shadow;
  color: $minicart-popover-color;
  left: auto;
  margin: 0;
  max-width: calc(100vw - #{$gutter});
  min-width: calc(320px - #{$gutter});
  opacity: 0;
  padding: 0;
  position: absolute;
  right: -#{$gutter / 2};
  top: $primary-header-height-mobile;
  transition: visibility 0s $transition-type $transition-time, opacity $transition-time $transition-type;
  visibility: hidden;
  width: 320px;

  @include media-breakpoint-up(md) {
    right: 0;
  }

  @include media-breakpoint-up(lg) {
    top: $primary-header-height-desktop;
  }

  &::before,
  &::after {
    display: none;
  }

  &.show {
    opacity: 1;
    transition-delay: 0s;
    visibility: visible;
  }

  .veil {
    &,
    .underlay,
    .spinner {
      position: absolute !important; // @TODO LROS: remove !important
    }

    // If no minicart-container is present yet...
    &:first-child {
      left: auto;
      right: 0;
      top: 25px;
      width: 60px; // Width of the spinner animation
    }
  }

  .product-summary {
    margin-right: -10px; // Compensate for scrollbar whitespace reservartion
		max-height: calc(100vh - 20px - #{$tertiary-header-height-mobile + $primary-header-height-mobile + $minicart-height-body-mobile}); //20px = extra vertical whitespace
    overflow: auto;
    overflow-x: hidden;
    padding: 0 10px 0 0; // Add whitespace reservation in case the user has a scrollbar

    @include media-breakpoint-up(lg) {
      max-height: calc(100vh - 20px - #{$tertiary-header-height-desktop + $primary-header-height-desktop + $minicart-height-body-desktop}); //20 = extra vertical whitespace
    }

    .row:first-child {
      .product-line-item {
        padding-top: 0;
      }
    }

    .product-line-item,
    .bonus-product-line-item {
      border-bottom: $border-medium;
      padding: 20px 0;

      .line-item-name {
        @include font-type($product-line-name-font);
      }

      .remove-btn {
        cursor: pointer;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      p {
        margin: 0 0 .5em;
      }

      .line-item-attribute {
        &--color {
          align-items: center;
          display: flex;

          .attribute {
            margin: 0;
          }

          .color-swatch {
            &__item {
              background-size: $product-color-attribute-background-size;
              border-radius: $product-color-attribute-border-radius;
              display: inline-block;
              height: $product-color-attribute-size;
              margin: 5px;
              width: $product-color-attribute-size;
            }
          }
        }
      }

      .line-item-availability {
        p:not(:last-child) {
          margin: 0;
        }

        .line-item-attributes {
          display: inline-block;
        }

        .line-item-out-of-stock-notice-wrapper {
          display: inline-block;
        }

        .line-item-out-of-stock-notice {
          background-color: $product-line-item-out-of-stock-background;
          border-radius: $product-line-item-out-of-stock-border-radius;
          color: $product-line-item-out-of-stock-border-color;
          padding: 0 .5em;
        }
      }

      .quantity-label,
      .line-item-total-price-label {
        display: none;
      }

      .quantity__decrease,
      .quantity__amount {
        float: left;
      }

      .quantity__amount {
        line-height: 30px;
        margin: 0 10px;
      }
    }

    .line-item-promo {
      margin-bottom: 20px 0 0 0;
    }
  }
}

.minicart {
  &-body {
    &__wrapper {
      border: $minicart-body-border;
    }
  }

  &-header {
    position: relative;
  }

  &-heading {
    &::after {
      background-color: $minicart-header-border-bg;
      bottom: 0;
      content: '';
      height: $minicart-header-border-size;
      left: 0;
      position: absolute;
      width: 50%;
    }
  }

  &-close {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
  }

  &-footer {
    &__wrapper {
      border: $minicart-footer-border;
    }

    .estimated-total-label,
    .estimated-total-value {
      @include font-type($minicart-footer-font-total);
      margin: 0;
    }
  }

  &-usps {
    .usp-list {
      ul {
        @include list-reset;
      }

      li {
        line-height: .75;

        &::before {
          color: $minicart-usp-list-item-pseudo-color;
          vertical-align: middle;
        }
      }
    }
  }

  &-upsell {
    @include font-type(minicart-upsell);
  }
}

.add-to-cart-messages {
  display: none;
}

.waardecheck-price {
    @include font-type(price-waardecheck);
}
