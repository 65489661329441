.site-footer {
    .icon-list {
		@include list-reset;
		display: flex;

        .list {
            &__item {
                align-items: center;
                display: flex;
                min-height: 50px;

                @include media-breakpoint-up(lg) {
                    text-align: left;
                }
            }

            &__icon {
                font-size: $icon-size;

                &.icon {
                    @each $client-icon, $client-settings in $client-icons {
                        &--#{$client-icon} {
                            @each $key, $value in $client-settings {
                                #{$key}: $value;
                            }
                        }
                    }
                }
            }
        }

        a.list {
            &__heading,
            &__link {
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}
