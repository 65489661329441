@import '~plugin_frontend_core/utils/mixins/fonts'; // JVP @TODO: We've got to find a simpler way to import the fonts, variables and mixins
@import '~plugin_frontend_core/variables/font';

$font-types-overrides: (
    headerUsps: (
        default: (font: 400 12px font-family(primary)),
        md: (font-weight: 700)
    ),
    price-waardecheck: (font: 900 15px font-family(primary), color:#B38E57),
    price-sales-waardecheck: (font: 600 11px font-family(primary), color:#B38E57),
    price-sales: (font: 700 18px font-family(primary)),
    price-from: (font: 400 14px font-family(primary), text-decoration: line-through),
    beautifulH1: (
        default: (font: 400 48px font-family(beautiful)),
        md: (font-size: 64px)
    )
);

$font-types: map-merge($font-types, $font-types-overrides);
