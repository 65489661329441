.site-header {
    .minicart-link {
        display: flex;
        align-items: center;

        .icon-wrapper {
            position: relative;
            order: 2;
        }

        .minicart-icon {
            order: 2;
        }

        .link__label {
            order: 1;
            display: none;
            margin-right: 10px;

            @include media-breakpoint-up(md) {
                display: inline-block;
            }
        }

        .minicart-quantity {
            left: auto;
            font-size: 14px;
            right: 50%;
            margin: -30px 0 0;
            transform: translate(50%, -50%);
        }
    }
}
