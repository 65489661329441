.experience-assets-storeBrandSlider .store-brand-slider-container {
	.store-brand-slider-content {
		h1 {
			@include font-type(beautifulH1)
		}

		@include media-breakpoint-up(lg) {
			p {
				margin-bottom: 0;
				width: 50%;
			}
		}
	}

	.slick-slide {
		margin: 0 20px;

		> div {
			position: relative;
			z-index: 3;
		} 

		.brand-slider-image-container {
			align-items: center;
			background-color: $styleguide-grey-100;
			display: flex !important;
			justify-content: center;
			height: auto;
			width: 125px !important;

			&:hover {
				@include transition;
				background-color: $styleguide-grey-400;
			}

			img {
				padding: 20px;
			}
		}
	} 

	.brand-slider-link {
		&:focus {
			outline: none;
		}
	}

	// Middle slide
	[data-active-slide="4"] {
		margin: 0 80px;

		figure {
			transform: scale(2);
		}
	}

	// last active slides
	.slick-active[data-active-slide="1"],
	.slick-active[data-active-slide="7"]{
		margin: 0 12px;
		
		figure {
			transform: scale(1.1);
		}
	}

	// Middle active slides
	.slick-active[data-active-slide="2"],
	.slick-active[data-active-slide="6"] {
		margin: 0 30px;
		
		figure {
			transform: scale(1.25);
		}
	}

	// first active slides
	.slick-active[data-active-slide="3"],
	.slick-active[data-active-slide="5"] {
		margin: 0 25px;
		
		figure {
			transform: scale(1.35);
		}
	}

	[data-active-slide] {
		figure {
			@include transition($time: 100ms);
		}
	}

	.slick-track {
		align-items: center;
		display: flex;
	}
	
	.slick-list {
		align-items: center;
		display: flex;
		margin: 0 auto;
		padding: 40px 0 !important;
		width: 96%;

		@include media-breakpoint-up(lg) {
			width: 93%;
		}
	}

	.slick-prev {
		left: -7px;
	}
	
	.slick-next {
		right: -7px;
	}

	@include media-breakpoint-up(lg) {
		.slick-prev {
			left: 0;
		}
		
		.slick-next {
			right: 0;
		}
	}

	@include media-breakpoint-up(sm) {
		.slick-prev {
			left: 5px;
		}
		
		.slick-next {
			right: 5px;
		}
	}

	.slick-prev,
	.slick-next {
		background: $store-brand-slider-arrows;
		border: none;

		&::after {
			color: $styleguide-white;
			margin-top: 0;
		}
	}
}