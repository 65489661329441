$refinement-navigation-inactive-color:      $text-color-primary !default;
$refinement-navigation-active-color:        $primary-brand-color !default;
$refinement-navigation-hover-color:         $primary-brand-color !default;
$refinement-navigation-show-more-color:     $primary-brand-color !default;
$refinement-navigation-border:              1px solid $styleguide-grey-400 !default;

$refinementNavigationContext: '.refinements-navigation';

#{$refinementNavigationContext} {
    border-bottom: $refinement-navigation-border;

    .count {
        @include font-type(search-refinement-list-item);
        color: $styleguide-grey-800;
    }

    &__list {
        @include list-reset;
        overflow: hidden;

        &--first-level {
            & #{$refinementNavigationContext} {
                &__list {
                    &-link {
                        @include icon(arrow-right, 'after', .6em);

                        &::after {
                            position: absolute;
                            right: 0;
                            top: 50%;
                            transform: translateY(-72.5%);
                        }
                    }
                }
            }
        }

        &--third-level {
            & #{$refinementNavigationContext} {
                &__list {
                    &-link {
                        &::after {
                            display: none;
                        }
                    }
                }
            }
        }

        &-item {
            border-color: $styleguide-grey-400 !important;
            border-radius: 3px;
            color: $refinement-navigation-inactive-color;
            opacity: 1;
            transition: opacity 1s ease;
            
            &.selected {
                @include font-type(bold);
            }

            &.active {
                > a {
                    @include font-type(bold);
                    color: $refinement-navigation-active-color;
                }
            }

            &.hidden {
                max-height: 0;
                opacity: 0;
            }
        }

        &-link {
            color: $styleguide-grey-800;
            position: relative;
            width: 100%;

            &[href],
            &[href]:hover {
                overflow: hidden;
                text-decoration: none;
                text-overflow: ellipsis;
            }

            &[href]:hover {
                color: $refinement-navigation-hover-color;
            }

            &--show-more {
                @include font-type(search-refinement-list-item);
                -webkit-appearance: none;
                background: none;
                border: 0;
                color: $styleguide-grey-800;
                cursor: pointer;
            }
        }
    }
}
