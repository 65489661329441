@for $index from 1 through 6 {
	h#{$index},
	.h#{$index},
    .heading-#{$index} {
		@include font-type(h#{$index});
		margin: 0;
		padding: 0;
	}

    h#{$index},
    .h#{$index},
    .heading-#{$index} {
		*:not([class*="icon_"]) {
			color: inherit;
			font: inherit;
			letter-spacing: inherit;
			line-height: inherit;
		}
	}

	.richtext--container {
		h#{$index} {
			margin-bottom: 5px;
		}
	}
}

.small {
	@include font-type(small);
}
