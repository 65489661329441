@import 'product-swatches';
@import 'product-badges';
@import 'product-variations';
@import 'product-quickview';
@import 'product-promotions';

$product-tile-column-base-padding:                calc(#{$gutter / 2});

.product-tile {
  position: relative;

  &__column {
    padding: 0 calc($gutter / 2);
  }

  .tile {
    &-image {
      max-width: 100%;
    }
  }

  & &__brand {
    @include font-type(product-tile-product-brand);
  }

  & &__name {
    @include font-type(product-tile-product-name);
  }

  & &__description {
    @include font-type(product-tile-product-description);
  }

  .price {
    text-align: center;

    @include media-breakpoint-up(md) {
      text-align: left;
    }

    .strike-through {
      font-size: inherit;
      margin: 0;

      @include media-breakpoint-up(md) {
        margin-right: 10px;
      }
    }

    .sales {
      @include font-type(price-sales);
    }
  }
}

// Specific Style for WaardeCheque PDP and PLP
.product-tile,
.product-detail__price {

    .price {
        .range__details {
          .waardecheck {
            &.hidden {
              display: none;
            }
          }
          .waardecheck__msg {
            display: none;
          }
        }
        .range__details--waardecheck {

            .waardecheck {
                @include font-type(price-waardecheck);
                display: inline-block;
                margin-left: 9px;

                .product-detail-page & {
                    font-size: 17px;
                }
            }

            .sales {
                text-decoration: line-through;
            }

            .sales,
            .strike-through {
                @include font-type(default);
                color: $text-color-primary;
            }

            .waardecheck__msg {
                @include font-type(price-sales-waardecheck);
                display: block;

                .product-detail-page & {
                    font-size: 11px;
                }
            }
        }
    }
}
