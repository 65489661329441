.header-primary {
    @include transition;
    background: $primary-header-background-color-mobile;
    color: $primary-header-background-text-color-mobile;
    display: flex;
    height: $primary-header-height-mobile;
    position: relative;
    z-index: 2;

    @include media-breakpoint-up(lg) {
        background: $primary-header-background-color-desktop;
        color: $primary-header-background-text-color-desktop;
        height: $primary-header-height-desktop;
    }

    .logo {
        left: $gutter / 2;
        max-width: $primary-header-logo-max-width;
        min-width: $primary-header-logo-min-width;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        @include media-breakpoint-up(lg) {
            left: 50%;
            transform: translate(-50%, -50%);

            img {
                max-height: $primary-header-logo-height-desktop;
            }
		}

		img {
            height: auto;
            max-height: $primary-header-logo-height-mobile;
            width: auto;
        }

    }
}
