@import '~app_schuurman/variables';

@import '~app_clockwork_orange/global';
@import '~plugin_frontend_core/fonts/icon-font';
@import '~int_trustpilot/trustpilot';

// Load new component styling after `app_clockwork_orange/global` because we need the mixins, variables, etc
@import './components/header/header';
@import './components/header/header-primary';
@import './components/header/header-secondary';
@import './components/header/header-tertiary';
@import './components/first-callout';
@import './components/header/components/main-nav';
@import './components/header/components/usps';
@import './components/header/components/search-bar';
@import './components/header/components/quicklinks';
@import './components/header/components/minicart';
@import './components/header/components/navbar-toggler';
@import './components/header/components/customer-service';
@import './components/header/components/search/search-suggestions';
@import './components/footer/components/footer';
@import './components/footer/components/content-links';
@import './components/footer/components/icon-links';
@import './components/footer/components/legal-links';
@import './components/footer/components/payment-methods';
@import './components/footer/components/newsletter';
@import './components/header/components/header-announcement';

// Load all shared components
@import '~app_schuurman_sooco/global';
