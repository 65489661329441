$sale-badge-background-color:                     $primary-brand-color !default;
$sale-badge-font-color:                           $text-color-secondary !default;

.badge {
    display: block;
    position: absolute;
    right: 20px;
    top: 20px;

    &--sale {
        @include font-type(product-tile-badge);

        background-color: $sale-badge-background-color;
        border-radius: 14px;
        color: $sale-badge-font-color;
        padding: 2px 15px;
    }

    &--promotion-discount {
        max-width: 120px;

        @include media-breakpoint-down(md) {
            width: 80px;
        }
    }
}
