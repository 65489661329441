.row {
    &--no-outer-gutter {
        margin-left: 0;
        margin-right: 0;

        & > .col-12 {
            padding-left: 0;
            padding-right: 0;
        }

        @each $bp in map-keys($grid-breakpoints) {
            @include media-breakpoint-up(#{$bp}) {
                & > [class*="col-#{$bp}"] {
                    &.col-#{$bp}-12 {
                        // TODO @JVP: This is being overridden by the not selectors below
                        padding-left: 0;
                        padding-right: 0;
                    }
    
                    &:not(.col-#{$bp}-12):not(:first-child) {
                        padding-left: $gutter / 2;
                    }
    
                    &:not(.col-#{$bp}-12):not(:last-child) {
                        padding-right: $gutter / 2;
                    }
                }
            }
        }
    }
}
