$feedback-error-color:          $styleguide-red !default;
$feedback-success-color:        $primary-brand-color !default;
$feedback-warning-color:        $primary-brand-color !default;

.error {
    color: $feedback-error-color;
}

.success {
    color: $feedback-success-color;
}

.warning {
    color: $feedback-warning-color;
}
