$cookie-notification-background-color:          rgba($styleguide-white, .95) !default;
$cookie-notification-text-color:                $text-color-primary !default;
$cookie-toggler-unchecked:                      $styleguide-grey-400 !default;
$cookie-toggler-button:                         $styleguide-white !default;
$cookie-information-background:                 $cookie-toggler-unchecked !default;
$cookie-notification-modal-background:          rgba(0,0,0,0.5) !default;
$cookie-always-active-color:                    $styleguide-grey-600 !default;
$cookie-line-separator:                         $cookie-notification-text-color !default;
$cookie-label-border:                           rgba(0,0,0, 0.2) !default;
$cookie-green:                                  $styleguide-green !default;
$cookie-green-hover:                            $styleguide-green-dark !default;

// Overlay class appended to the body for the modal
.has-overlay {
    height: 100%;
    overflow-y: hidden;
}

// Modal container spanning full page width/height
.cookie-notification-modal {
    align-items: center;
    background: $cookie-notification-modal-background;
    bottom: 0;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    right: 0;
    top: 0;
    transition: .3s ease opacity;
    width: 100%;
    z-index: 10;

    // Appended to modal container when the page loads
    &.show-modal {
        opacity: 1;
        pointer-events: all;
    }

    // Individual cookie option, for example: analytical cookies
    .cookie__option {
        margin-bottom: 10px;
        width: 100%;

        // Toggle switch container
        label.switch-toggler {
            border-bottom: 2px solid $cookie-label-border;
            min-height: 35px;
            position: relative;

            // Toggle switch
            .switch-slider {
                background-color: $cookie-toggler-unchecked;
                border-radius: 35px;
                bottom: 0;
                cursor: pointer;
                display: inline-block;
                height: 21px;
                left: 0;
                position: relative;
                right: 0;
                top: 0;
                transition: .4s;
                width: 40px;
                -webkit-transition: .4s;

                &::before {
                    background-color: $cookie-toggler-button;
                    border-radius: 50%;
                    bottom: 3px;
                    content: "";
                    height: 15px;
                    left: 4px;
                    position: absolute;
                    transition: .4s;
                    width: 15px;
                    -webkit-transition: .4s;
                }
            }

            // Text within the label tag per cookie input
            .cookie-label {
                font-weight: 600;
                position: relative;

                .collapse-toggler-btn {
                    background: none;
                    border: 0;
                    border-radius: 50%;
                    cursor: pointer;
                    font-size: 20px;
                    line-height: 1;
                    padding: 0;
                    transform: rotate(-90deg);

                    &.active {
                        color: $cookie-green;
                        transition: all .2s ease-in;
                        transform: rotate(0deg);
                    }

                    i {
                        font-weight: bold;
                    }

                    &:hover {
                        i {
                            color: $cookie-green;
                            transition: color .2s ease-in;
                        }
                    }
                }

                span {
                    color: $cookie-always-active-color;
                }
            }
        }

        input[type="checkbox"]:disabled {
            + .switch-toggler .switch-slider {
                opacity: 0.5;
            }
        }

        input[type="checkbox"]:checked {
            + .switch-toggler .switch-slider {
                background-color: $cookie-green;
            }
        }

        input[type="checkbox"]:focus {
            + .switch-toggler .switch-slider {
                border: 2px solid $cookie-green;

                &::before {
                    bottom: 1px;
                }
            }
        }

        input[type="checkbox"]:checked {
            + .switch-toggler {
                .switch-slider::before {
                    -ms-transform: translateX(17px);
                    transform: translateX(17px);
                    -webkit-transform: translateX(17px);
                }
            }
        }

        .input-checkbox__caption {
            &::before,
            &::after {
                display: none;
            }
        }

        input[type="checkbox"] {
            opacity: 0;
        }

        .cookie-information {
            background: $cookie-information-background;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
            padding: 10px 35px;
        }
    }

    // Modal buttons
    .btn-all-cookies {
        background-color: $cookie-green;
        color: $styleguide-white;
        margin-bottom: 10px;
        width: 100%;

        @include media-breakpoint-up(md) {
            height: 45px;
            margin: 0 0 0 15px;
            min-width: 45%;
        }

        &:hover {
            background-color: $cookie-green-hover;
            transition: all .2s ease-in;
        }
    }

    .btn-select-cookies,
    .btn-cookie-settings {
        background-color: transparent;
        border: 1px solid $cookie-green;
        color: $cookie-green;
        width: 100%;

        @include media-breakpoint-up(md) {
            height: 45px;
            max-width: 30%;
        }

        &:hover {
            background-color: $cookie-green;
            color: $styleguide-white;
            transition: all .2s ease-in;
        }
    }
}

// Cookie modal
.cookie-notification {
    @include transition;
    background-color: $cookie-notification-background-color;
    box-shadow: 0 1px 10px $cookie-notification-modal-background;
    color: $cookie-notification-text-color;
    display: block;
    margin-top: 15px;
    max-width: 95%;
    max-height: 90%;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 20;

    @include media-breakpoint-up(lg) {
        margin-top: 0;
        max-width: 660px;
        width: 100%;
    }

    &__close {
        cursor: pointer;
        position: absolute;
        right: $gutter / 2;
        top: 20px;
    }

    &__text {
        @include font-type(small);
        padding-right: 25px;

        a {
            color: inherit;
            cursor: pointer;
            font: inherit;
            white-space: nowrap;
        }
    }

    &--hidden {
        transform: translateY(100%);

        @include media-breakpoint-up(lg) {
            transform: translate(-50%, 100%);
        }
    }
}

