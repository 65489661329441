/**
 *
 */
@function asset-url($filename: " ", $path: "") {
    @return "~#{$path}#{$filename}";
}

/**
  * Return a font-family.
  * @param {string} $name The font family name
  */

  @function font-family($name: primary) {
    @if map_has_key($font-family, $name) {
      @return map_get($font-family, $name);
    } @else {
      @return map_get($font-family, primary);
    }
  }

  /**
   * Import all defined fonts
   */
  @mixin font-imports() {
    @each $font, $settings in $font-import {
      @each $name, $format in $settings {
        @include font-import($font, '#{to-lower-case($font)}-#{to-lower-case($name)}', map_get($format, 'weight'), map_get($format, 'style'));
      }
    }
  }

  /**
   * Return a font face import
   * @param {string} $font The font name
   * @param {string} $filename The file name
   * @param {number} $font-weight The font weight
   * @param {string} $font-style The font style
   */
  @mixin font-import($font, $filename, $font-weight: 400, $font-style: normal) {
    @font-face {
      font-family: $font;
      font-style: $font-style;
      font-weight: $font-weight;
      src: url("~fonts/#{$filename}.woff2") format('woff2'), url("~fonts/#{$filename}.woff") format('woff');
    }
  }

  /**
   * Return a font-type styling with responsive mapping.
   * @param {string} $name The font type styling
   */
  @mixin font-type($name: default) {
    $map: if(map_has_key($font-types, $name), map_get($font-types, $name), map_get($font-types, default));

    @if type-of($map) == map and map_has_key($map, default) {
      @each $breakpoint, $style in $map {
        @if $breakpoint == default {
          @include font-style($style);
        } @else {
          @include media-breakpoint-up($breakpoint) {
            @include font-style($style);
          }
        }
      }
    } @else {
      @include font-style($map);
    }
  }

  /**
   * Return a font-type styling.
   * @param {map|string} $map The font mapping
   */
  @mixin font-style($map: ()) {
    @if type-of($map) == map {
      @each $type, $value in $map {
        #{$type}: $value;
      }
    } @else {
      font: $map;
    }
  }
