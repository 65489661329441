.page-callout {
    background-color: $styleguide-anthracite;
    color: $styleguide-white;
    display: flex;
    justify-content: center;
    align-items: center;
    
    p {
        margin: 10px 0 10px;
        text-transform: uppercase;
        color: $styleguide-white;
    }
}