.site-footer {
    .link-list {
        @include list-reset;

        @include media-breakpoint-up(lg) {
            display: block;
        }
    }

    .footer-customer-service {
        .open {
            color: $styleguide-green;
        }
    }

    #footer-contact {
        li {
			flex: 0 0 35%;
            padding: 0 15px 0 0;

            span.icon {
                color: $styleguide-orange;
            }

            &:nth-child(3) {
                flex-basis: 50%;
            }
        }
    }

    #footer_great_shopping {
        @include media-breakpoint-up(md) {
            margin-right: 50px;

            img {
                width: 125px;
                height: auto;
            }
        }

        @include media-breakpoint-up(lg) {
            margin-right: 0;
        }
    }

    #footer_reviews {
        @include media-breakpoint-up(md) {
            margin-right: 50px;
        }

        @include media-breakpoint-up(lg) {
            margin-right: 0;
        }

        .reviews-item {
            max-width: 20%;

            iframe {
                width: 150px !important;
            }
        }
    }

    #footer_delivery {
        @include media-breakpoint-up(md) {
            margin-right: 50px;
        }

        @include media-breakpoint-up(lg) {
            margin-right: 0;
        }
    }

    #footer_awards {
        @include media-breakpoint-up(md) {
            margin-right: 50px;
        }

        @include media-breakpoint-up(lg) {
            margin-right: 0;
        }

        .awards-list {
            @include list-reset;
            align-items: center;
            display: flex;

            @include media-breakpoint-up(md) {
                img {
                    height: 67px;
                    width: auto;
                }
            }
        }
    }

    #footer_initiatives {
        @include media-breakpoint-up(lg) {
            display: flex;
            flex-direction: column;
            width: 25%;
            align-items: center;
        }
    }
}
