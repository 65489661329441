.header-usp {

    li {
        color: $styleguide-white;
        position: absolute;
        @include icon(check, before, 10px);

        &::before {
            padding-right: 5px;
        }
    }

    @include media-breakpoint-up(lg) {
        ul {
            display: flex;
            justify-content: space-around;
        }

        li {
            color: $styleguide-white;
            position: relative;
            transition: none;
            opacity: 1;
            left: auto;
            top: auto;
            transform: none;
        }
    }

    .trustpilot-widget {
        display: none;
    }
}
