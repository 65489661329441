$collapse-toggler-vpadding: 28px !default;
$collapse-toggler-hpadding: $grid-gutter-width / 2 !default;
$collapse-toggler-size: 32px !default;
$collapse-toggler-icon-size: 12px !default;

$collapse-body-vpadding: 25px !default;
$collapse-body-hpadding: $grid-gutter-width / 2 !default;

$collapse-handle-padding-left: 20px !default;

.collapse-toggler {
    @include icon(arrow-down, after, $collapse-toggler-icon-size);
    background: none;
    border: 1px solid $border-color;
    border-radius: 0;
    border-width: 0 0 1px;
    cursor: pointer;
    position: relative;
    text-align-last: left;
    width: 100%;

    &::after {
        @include transition(transform);
        border: 1px solid $border-color;
        border-radius: 50%;
        height: $collapse-toggler-size;
        padding: (($collapse-toggler-size - $collapse-toggler-icon-size - 2px) / 2); // 2px = 1px border-width * 2
        position: absolute;
        right: $collapse-toggler-hpadding;
        top: 50%;
        transform: translateY(-50%) rotate(180deg);
        width: $collapse-toggler-size;
    }
    
    &.collapsed::after {
        transform: translateY(-50%);
    }

    &__value {
        position: relative;
        display: inline-block;
        padding: $collapse-toggler-vpadding $collapse-toggler-hpadding;
        margin-right: ($collapse-toggler-size + $collapse-toggler-hpadding);

        &::after {
            @include transition(width);
            content: '';
            display: block;
            height: 3px;
            background: $primary-brand-color;
            left: 0;
            margin-top: -1px;
            position: absolute;
            top: 100%;
            width: 0;
        }
    }

    &:not(.collapsed) &__value::after {
        width: 100%;
    }
}

.collapse-handle {
    @include icon(arrow-down, after, $collapse-toggler-icon-size);
    cursor: pointer;
    display: block;
    padding-left: $collapse-handle-padding-left;
    position: relative;

    &::after {
        @include transition(transform);
        color: $primary-brand-color;
        left: 0;
        position: absolute;
        top: 50%;
        transform: translate3d(0, -50%, 0);
    }

    &.collapsed::after {
        transform: translate3d(0, -50%, 0) rotate(-90deg);
    }
}

.collapse-body {
    padding: $collapse-body-vpadding $collapse-body-hpadding;

    p:last-child {
        margin-bottom: 0;
    }
}
