// Styleguide colors
$styleguide-black:          #000 !default;
$styleguide-white:          #fff !default;
$styleguide-grey-100:       #efefef !default;
$styleguide-grey-200:       #dbdada !default;
$styleguide-grey-300:       #eee !default;
$styleguide-grey-400:       #d8d8d8 !default;
$styleguide-grey-500:       #979797 !default;
$styleguide-grey-600:       #7b7b7b !default;
$styleguide-grey-800:       #565656 !default;
$styleguide-grey-900:       #404040 !default;
$styleguide-transparent:    transparent !default;

$styleguide-orange:         #ff5a06 !default;
$styleguide-orange-800:     #e85003 !default;
$styleguide-red:            #a70013 !default;
$styleguide-red-300:        #eb5757 !default;
$styleguide-green:          #2bbd26 !default;
$styleguide-green-dark:     #1d8b19 !default;

$styleguide-loyalty-card:   #444750 !default;
$styleguide-anthracite:     #343e48 !default;
$styleguide-anthracite-dark:#2d3741 !default;

// Role colors

// For `brand-color` variables, only create variations of the _main_ brand color.
// Other brand colors are usually not used that much and should only be used in components/pages scss files
$primary-brand-color:       $styleguide-orange !default;
$secondary-brand-color:     $styleguide-orange-800 !default;

$success-color:             $styleguide-green !default;
$error-color:               $styleguide-red !default;
$warning-color:             $styleguide-orange-800 !default;

$background-color:          $styleguide-white !default;
$text-color-primary:        $styleguide-black !default;
$text-color-secondary:      invert($text-color-primary) !default;

$border-color:              $styleguide-grey-500 !default;
$border-color-light:        $styleguide-grey-300 !default;
$border-color-medium:       $styleguide-grey-400 !default;
$border-color-strong:       $styleguide-grey-600 !default;

// Opt-in Campaign
$color-mongoose:            #BCAA82;
$color-fruit-salad:         #59A964;

// My Account redesign colors
$color-arsenic:             #363E47;
$color-cultured:            #f6f6f6;
$color-philippine-silver:   #b4b4b4;
$color-iron:                #e0e1e3;
$color-copper:              #c6762f;
$color-brandy-punch:        #df8636;
$color-saltpan:             #eef6ef;
$color-fruit-salad:         #59a964;
$color-mongoose:            #bcaa82;
$color-cod-gray:            #1e1e1e;
