// Styleguide colors
$styleguide-black:          #000 !default;
$styleguide-white:          #fff !default;
$styleguide-grey-100:       #efefef !default;
$styleguide-grey-200:       #dbdada !default;
$styleguide-grey-300:       #eee !default;
$styleguide-grey-400:       #d8d8d8 !default;
$styleguide-grey-500:       #979797 !default;
$styleguide-grey-600:       #7b7b7b !default;
$styleguide-grey-800:       #565656 !default;
$styleguide-grey-900:       #404040 !default;
$styleguide-grey-1000:       #3e3e3e !default;
$styleguide-transparent:    transparent !default;

$styleguide-blue:           #4695d2 !default;
$styleguide-green-600:      #32ac5c !default;
$styleguide-green:          #2bbd26 !default;
$styleguide-orange-800:     #de7300 !default;
$styleguide-orange:         #ee8016 !default;
$styleguide-pink:           #e71d72 !default;
$styleguide-red:            #a70013 !default;
$styleguide-red-300:        #eb5757 !default; 
$styleguide-anthracite:     #343e48 !default;
$styleguide-anthracite-dark:#2d3741 !default;

// Role colors
$store-header-background:	$styleguide-anthracite !default;
$store-brand-slider-arrows:	$styleguide-anthracite !default;

// For `brand-color` variables, only create variations of the _main_ brand color.
// Other brand colors are usually not used that much and should only be used in components/pages scss files
$primary-brand-color:       $styleguide-orange !default;
$secondary-brand-color:     $styleguide-orange-800 !default;

$category-color-1:          $styleguide-pink !default;
$category-color-2:          $styleguide-blue !default;
$category-color-3:          $styleguide-green-600 !default;

$success-color:             $styleguide-green !default;
$error-color:               $styleguide-red !default;
$warning-color:             $styleguide-orange-800 !default;

$background-color:          $styleguide-white !default;
$text-color-primary:        $styleguide-grey-900 !default;
$text-color-secondary:      $styleguide-white !default;

$border-color:              $styleguide-grey-500 !default;
$border-color-light:        $styleguide-grey-300 !default;
$border-color-medium:       $styleguide-grey-400 !default;
$border-color-strong:       $styleguide-grey-600 !default;
