@font-face {
    font-family: 'CorporativeSans';
    src: url('~fontsDirectory/fonts/CorporativeSansRdRegular.woff2') format('woff2'),
         url('~fontsDirectory/fonts/CorporativeSansRdRegular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'CorporativeSans';
    src: url('~fontsDirectory/fonts/CorporativeSansRdMedium.woff2') format('woff2'),
         url('~fontsDirectory/fonts/CorporativeSansRdMedium.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'CorporativeSans';
    src: url('~fontsDirectory/fonts/CorporativeSansRdBold.woff2') format('woff2'),
         url('~fontsDirectory/fonts/CorporativeSansRdBold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}
