$product-tile-swatch-size:                          31px !default;
$product-tile-swatch-padding:                       3px !default;
$product-tile-swatch-border-width:                  1px !default;
$product-tile-swatch-border-color:                  $border-color !default;
$product-tile-swatch-border-color-active:           $primary-brand-color;
$product-tile-swatch-icon-size:                     10px !default;
$product-tile-swatch-icon-name:                     check !default;

$product-tile-attribute-error-color:                $warning-color !default;
$product-tile-availability-false:                   $styleguide-red-300 !default;
$product-tile-availability-true:                    $styleguide-green !default;

.product-tile {
    &__variation {
        &--static {
            text-align: center;

            @include media-breakpoint-up(md) {
                text-align: left;
            }
        }

        &--size {
            border: 1px solid transparent;
            border-radius: 100px;
            cursor: default;
            display: inline-block;
            height: $product-tile-swatch-size;
            line-height: $product-tile-swatch-size - 1px;
            margin: 0 2px;
            min-width: $product-tile-swatch-size;
            position: relative;
            text-align: center;

            &[href],
            &[href]:hover {
                text-decoration: none;
            }

            &[href]:hover {
                border-color: $product-tile-swatch-border-color;
            }

            &.selected {
                border-color: $product-tile-swatch-border-color-active;
            }
        }
    }

    .product-attributes {
        &__heading {
            @include font-type(product-tile-attribute-heading);
        }

        &__item {
            @include media-breakpoint-up(md) {
                &:nth-child(n+3) {
                    display: none;
                }
            }
            &__value {
                line-height: 1;
            }
        }

        &__color {
            @include list-reset;
            align-items: flex-start;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 0 -2px;

            @include media-breakpoint-up(md) {
                display: none;
                justify-content: flex-start;
                margin: 0 -4px;
            }

            li {
                width: 28px;
                @include media-breakpoint-up(sm) {
                    width: 32px;
                }
                @include media-breakpoint-up(md) {
                    width: 52px;
                }
                @include media-breakpoint-up(lg) {
                    width: 46px;
                }
                @include media-breakpoint-up(xl) {
                    width: 52px;
                }
            }

            .attribute-color {
                border: 1px solid transparent;
                display: flex;
                margin: 0 2px;
                @include media-breakpoint-up(md) {
                    margin: 0 4px;
                }

                a {
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    display: inline-block;
                    height: 25px;
                    margin: 2px;
                    width: 100%;
                    @include media-breakpoint-up(md) {
                        height: 35px;
                        margin: 2px 3px;
                    }
                }

                &:hover {
                    border: 1px solid $border-color;
                }

                &.selected {
                    border: 1px solid $primary-brand-color;
                }

            }
        }

        &--error {
            color: $product-tile-attribute-error-color;
        }
    }

    &__availability {
        .line-item-availability {
            &--out-of-stock {
                color: $product-tile-availability-false;
            }

            &--available {
                color: $product-tile-availability-true;
            }
        }
    }

    &.product-quickview {
        .product-attributes {
            &__color {
                display: flex;
            }
        }
    }
}
