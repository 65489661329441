.site-footer {
	.payment-method-list {
		@include list-reset;
		display: flex;
		flex-wrap: wrap;

		li {
			margin-bottom: 10px;
		}
	}
}