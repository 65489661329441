@import '~plugin_frontend_core/mixins';

$pagingbarContext:                  '.pagingbar';
$paging-bar-text-color:             $text-color-primary !default;
$paging-bar-inactive-item-border:   $border-medium !default;
$paging-bar-active-item-border:     $border-strong !default;
$paging-bar-border-bottom:          $border !default;

#{$pagingbarContext} {
    @include list-reset;

    text-align: center;
    width: 100%;

    @include media-breakpoint-up(md) {
        display: flex;
        justify-content: flex-end;
        padding: 20px 0 20px 20px;
        text-align: right;
    }

    @include media-breakpoint-down(sm) {
        border-bottom: $border;
        border-top: $border;
        display: flex;
        justify-content: space-between;
    }

    #{$pagingbarContext}__previous,
    #{$pagingbarContext}__next {
        &--inactive {
            cursor: initial;
            display: none;

            &:hover {
                cursor: not-allowed;
                border: $paging-bar-inactive-item-border;
            }
        }

        @include media-breakpoint-down(sm) {
            padding: ($spacer / 2) ($spacer * 2);

            &--inactive {
                display: block;
                opacity: 0;
            }
        }
    }

    &__status,
    &__link {
        padding: 8px 14px;
    }

    &__link {
        border: $paging-bar-inactive-item-border;
        color: $paging-bar-text-color;
        display: inline-block;

        // base uses a[href] so needed same specificity
        &[href] {
            text-decoration: none;

            &:hover {
                text-decoration: none;
            }
        }

        &--active,
        &:hover {
            border: $paging-bar-active-item-border;
            color: $paging-bar-text-color;
        }
    }

    &__item {
        @include font-type(pagination-item);

        + #{$pagingbarContext}__item {
            margin-left: 3px;
        }

        &--gap {
            #{$pagingbarContext}__link {
                border: none;
            }

            &,
            + #{$pagingbarContext}__item {
                display: none;

                @include media-breakpoint-up(md) {
                    display: inline-block;
                }
            }
        }

        a,
        span {
            text-decoration: none;
            color: inherit;
            display: block;
            font-family: inherit;
            font-size: inherit;
            font-weight: inherit;
            line-height: 20px;
        }
    }
}
