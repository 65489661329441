@import '~plugin_frontend_core/fonts/icon-font';
@import '~plugin_frontend_core/utils/mixins/fonts';
@import '~plugin_frontend_core/variables';
@import '~plugin_frontend_core/mixins';
@import '~plugin_frontend_components/components/common/list-links';
@import '~plugin_frontend_components/components/navigation/page-navigation';
@import '~plugin_frontend_components/common/tabs';
@import "../refinements/refinements-navigation";
@import '../shared/components/category-slots';


.underlay {
    position: fixed;
    height: 100%;
}

.grid-header {
    @include media-breakpoint-down(sm) {
        padding-left: 0;
        padding-right: 0;
    }
}

.nav-tabs-wrapper,
.no-search-result {
    padding: 0;
    border: 1px solid $border-color;
    border-radius: 0;
    border-width: 0 0 1px;
    width: 100%;
}
