.has-bg {
	&.bg-red {
		background-color: $styleguide-red;
		color: $styleguide-white;
	}
	
	&.bg-orange {
		background-color: $styleguide-orange;
		color: $styleguide-white;
	}

	&.bg-grey-light {
		background-color: $styleguide-grey-100;
	}

	&.bg-grey-dark {
		background-color: $styleguide-grey-200;
	}

	&.bg-anthracite-light {
		background-color: $styleguide-anthracite;
		color: $styleguide-white;
	}
	
	&.bg-anthracite-dark {
		background-color: $styleguide-anthracite-dark;
		color: $styleguide-white;
	}
}