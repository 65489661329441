$product-quickview-border-color:                    $primary-brand-color !default;
$product-quickview-border:                          2px solid $product-quickview-border-color !default;
$product-quickview-box-shadow:                      0 0 5px 0 rgba($styleguide-grey-800, .15) !default;

$product-quickview-variation-dimensions:            30px !default;
$product-quickview-variation-border-color:          $border-color-light !default;
$product-quickview-slider-arrow-size:               8px !default;

.product-quickview.product-tile {
    @include transition(opacity, .2s);

    background-color: $background-color;
    border-bottom: $product-quickview-border;
    box-shadow: $product-quickview-box-shadow;
    left: 50%;
    max-width: 100%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: calc(100% - #{$gutter});
    z-index: 1;

    @include media-breakpoint-down(sm) {
        display: none;
    }

    &:not(.show) {
        opacity: 0;
        pointer-events: none;
    }

    &__column {
        padding: 0 calc($gutter / 2);
    }

    &:nth-child(0) {
        opacity: 1 !important;
    }

    .tile {
        &-image {
            max-width: 100%;
        }
    }

    &__heading {
        &[href],
        &[href]:hover {
            text-decoration: none;
        }
    }

    & &__brand {
        @include font-type(product-tile-product-brand);
    }

    & &__name {
        @include font-type(product-tile-product-name);
    }

    & &__description {
        @include font-type(product-tile-product-description);
    }

    .price {
        text-align: center;

        @include media-breakpoint-up(md) {
            text-align: left;
        }

        .strike-through {
            margin: 0;

            @include media-breakpoint-up(md) {
                margin-right: 10px;
            }
        }
    }

    .slick {
        &-slider {
            @include list-reset;
        }

        &-prev,
        &-next {
            background: none;
            border: 0;
            height: $product-quickview-variation-dimensions;
            width: $product-quickview-slider-arrow-size;

            &::after {
                font-size: $product-quickview-slider-arrow-size;
                margin-top: 0;
            }
        }

        &-prev {
            left: -11px;

            @include media-breakpoint-up(xl) {
                left: -5px;
            }
        }
        
        &-next {
            right: -11px;

            @include media-breakpoint-up(xl) {
                right: -5px;
            }
        }

        &-arrow + .slick-list {
            @include media-breakpoint-up(xl) {
                margin: 0 10px;
            }
        }

        &-list:first-child {
            .slick-slide {
                justify-content: flex-start;
            }
        }

        &-track {
            margin: 0;
        }

        &-slide {
            align-items: center;
            display: flex;
            height: auto;
            justify-content: center;
            margin-right: 5px;

            [href],
            [href]:hover {
                outline: none;
                text-decoration: none;
            }

            &.disabled {
                [href] {
                    cursor: not-allowed;
                    opacity: .5;
                }
            }

            [href] {
                align-items: center;
                border: 1px solid transparent;
                border-radius: 100px;
                display: flex;
                height: $product-quickview-variation-dimensions;
                justify-content: center;
                min-width: $product-quickview-variation-dimensions;
                border-color: $product-quickview-variation-border-color;

                .product-attributes__item__value {
                    padding: 0 5px;
                }
            }

            &.selected [href],
            [href]:hover {
                border-color: $product-quickview-border-color;
            }
        }
    }
}

