// Component variables
$nav-level-1-height: $primary-header-height-desktop !default;

$nav-level-1-hspace: 10px !default;
$nav-level-1-hspace-xl: 20px !default;
$nav-level-1-text-color: $text-color-primary !default;


// Level 2
$nav-level-2:                                     '.nav-level-2';
$nav-level-2-background-color:                    $styleguide-white !default;
$nav-level-2-border-top:                          none !default;
$nav-level-2-box-shadow:                          $header-box-shadow !default;

$nav-level-2-text-color:                          $text-color-primary !default;
$nav-level-2-text-transform:                      uppercase !default;
$nav-level-2-text-decoration--inactive:           none !default;
$nav-level-2-text-decoration--active:             underline !default;

// Level 3
$nav-level-3:                                     '.nav-level-3';
$nav-level-3-text-color:                          $text-color-primary !default;
$nav-level-3-text-transform:                      none !default;
$nav-level-3-text-decoration--inactive:           none !default;
$nav-level-3-text-decoration--active:             underline !default;

// Search
$search-background-color:                         $styleguide-transparent !default;
$search-background-color--active:                 $styleguide-transparent !default;
$search-border:                                   1px solid $styleguide-white !default;
$search-text-color:                               $styleguide-white !default;
$search-placeholder-color:                        $styleguide-white !default;


// Component styles
.site-header {
    .navbar-nav {
        @include list-reset;
    }

    .nav-level-1-link {
        @include media-breakpoint-up(lg) {
            @include font-type(navigation-heading-link-alt);
            color: $nav-level-1-text-color;
            letter-spacing: 1.5px;
            line-height: $nav-level-1-height; // Assuming text will never go over 2 lines 😅
            padding: 0 $nav-level-1-hspace;
            text-decoration: none;
            text-transform: uppercase;
        }

        @include media-breakpoint-up(xl) {
            padding: 0 $nav-level-1-hspace-xl;
        }
    }

    @include media-breakpoint-up(lg) {
        .navbar-flyout {
            .nav-level-1 {
                &-link {
                    &::after {
                        @include transition;
                        transition-property: left, width;
                        content: '';
                        background-color: $primary-brand-color;
                        border: none;
                        display: block;
                        height: 2px;
                        left: calc(100% - #{$nav-level-1-hspace});
                        margin: 0;
                        padding: 0;
                        position: absolute;
                        top: calc(100% - 1rem);
                        width: 0;
                    }
                }

                &-item {
                    position: static;

                    &:hover {
                        // The z-index is done for the following scenario:
                        // - Have a level-2 menu open from the 2nd level-1 item
                        // - Quickly move left to the 1st level-1 item so it shows it's level-2 menu
                        // - Move downwards to the level-2 menu of the 1st level-1 item
                        //
                        // If it were not because of the below z-index, the level-2 menu of the 2nd level-1 menu would
                        // be selected as the 2nd level-1 menu has a naturally higher z-index (it is lower in the dom)
                        z-index: 15;

                        .nav-level-1-link::after {
                            transition-property: width;
                            left: $nav-level-1-hspace;
                            width: calc(100% - #{$nav-level-1-hspace * 2});

                            @include media-breakpoint-up(xl) {
                                left: $nav-level-1-hspace-xl;
                                width: calc(100% - #{$nav-level-1-hspace-xl * 2});
                            }
                        }

                        .nav-level-2 {
                            opacity: 1;
                            pointer-events: all;
                            transition-delay: 0s;
                            visibility: visible;
                        }
                    }

                    .nav-level-2 {
                        @include transition;
                        background-color: $nav-level-2-background-color;
                        display: block;
                        left: 0;
                        margin: 0 -#{$gutter};
                        min-width: $site-min-width;
                        opacity: 0;
                        padding: 50px 0;
                        position: absolute;
                        right: 0;
                        top: $nav-level-1-height;
                        transition-delay: .15s;
                        visibility: hidden;
                        z-index: 10;

                        &::before {
                            background-color: $nav-level-2-background-color;
                            box-shadow: $nav-level-2-box-shadow;
                            content: '';
                            height: 100%;
                            left: 50%;
                            margin-left: -50vw;
                            pointer-events: none;
                            position: absolute;
                            top: 0;
                            width: 100vw;
                            z-index: -1;
                        }

                        &-item {
                            &:not(.nav-level-2-item--no-children) {
                                @include font-type(navigation-heading-link);
                                background: none;
                                border: 0;
                                clear: none;
                                color: inherit;
                                float: left;
                                padding: 0 #{$gutter / 2};
                                white-space: normal;
                                width: 20%;

                                .nav-level-2-link {
                                    @include font-type(navigation-heading-link);
                                    @include transition;
                                    color: $nav-level-2-text-color;
                                    display: inline-block;
                                    max-width: 100%;
                                    overflow: hidden;
                                    padding-bottom: 15px;
                                    text-decoration: $nav-level-2-text-decoration--inactive;
                                    text-overflow: ellipsis;
                                    text-transform: $nav-level-2-text-transform;

                                    &:hover {
                                        text-decoration: $nav-level-2-text-decoration--active;
                                    }
                                }
                            }

                            &--no-children {
                                clear: none;
                                float: left;
                                padding: 0 #{$gutter / 2};
                                width: 20%;

                                &:hover {
                                    background: none;

                                    .nav-level-2-link {
                                        text-decoration: $nav-level-3-text-decoration--inactive;
                                    }
                                }

                                .nav-level-2-link {
                                    color: $nav-level-3-text-color;
                                    text-decoration: $nav-level-3-text-decoration--inactive;

                                    &:hover {
                                        text-decoration: $nav-level-3-text-decoration--active;
                                    }
                                }
                            }
                        }

                        .header-menu-image {
                            float: right;
                            width: 18%;
                            margin: 0 15px 15px -15px;
                        }
                    }
                }
            }
        }
    }
}
