.header-primary {
  &.search-active {
    .navbar-toggler-icon {
      span {
        background-color: $styleguide-white; // @TODO LROS: move this to its own component?

        &::after,
        &::before {
          background-color: $styleguide-white; // @TODO LROS: move this to its own component?
        }
      }
    }

    a.minicart-link {
      .minicart-quantity {
        color: $styleguide-white; // @TODO LROS: move this to its own component?
      }
    }
  }
}
