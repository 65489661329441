///
/// @param {*} $name
/// @param {*} $breakpoints [$grid-breakpoints]
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

///
/// @param {*} $name
/// @param {*} $breakpoints [$grid-breakpoints]
@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
  $next: breakpoint-next($name, $breakpoints);
  @return if($next, breakpoint-min($next, $breakpoints) - .02px, null);
}

///
/// @param {*} $name
/// @param {*} $breakpoints [$grid-breakpoints]
@function breakpoint-next($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
    $next: index($breakpoint-names, $name);
    @return if($next < length($breakpoint-names), nth($breakpoint-names, $next + 1), null);
}

///
/// @param {*} $name
/// @param {*} $breakpoints [$grid-breakpoints]
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

///
/// @param {*} $name
/// @param {*} $breakpoints [$grid-breakpoints]
@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

///
/// @param {*} $name
/// @param {*} $breakpoints [$grid-breakpoints]
@mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  $max: breakpoint-max($name, $breakpoints);
  @if $min and $max {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

///
/// @param {*} $name
/// @param {*} $breakpoints [$grid-breakpoints]
@mixin media-breakpoint-min-max($nameMin, $nameMax, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($nameMin, $breakpoints);
  $max: breakpoint-max($nameMax, $breakpoints);
  @if $min and $max {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}
