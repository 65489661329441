.experience-component {
	.v-align-top {
		align-self: flex-start;
	}

	.v-align-center {
		align-self: center;
	}

	.v-align-bottom {
		align-self: flex-end;
	}
}