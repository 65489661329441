$refinement-bar-background:                     $styleguide-white !default;
$refinement-bar-border:                         1px solid $styleguide-grey-400 !default;
$refinement-bar-link-color:                     $styleguide-grey-800 !default;
$refinement-bar-active-color:                   $primary-brand-color !default;
$refinement-bar-hover-color:                    $primary-brand-color !default;
$refinement-collapse-toggle-size:               auto !default;
$refinement-bar-mobile-padding-vertical:        50px !default;
$refinement-bar-offset-top-md:                  $primary-header-height-mobile + $tertiary-header-height-mobile - 2px !default;
$refinement-bar-offset-top-lg:                  $primary-header-height-desktop + $secondary-header-height-desktop + $tertiary-header-height-desktop - 2px !default;

@import "./refinements-navigation";
@import './refinements-slider';

.refinement-bar {
    overflow-x: hidden;

    @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: column;
        height: 100%;
        position: relative;
    }

    &__container {
        height: 100%;
        position: relative;

        @include media-breakpoint-up(md) {
            &.sticky-filters {
                overflow: visible;
                height: 100%;

                .refinement-bar {
                    height: 100%;
                    overflow-x: visible;

                    aside {
                        height: 100%;

                        .position-sticky {
                            top: $refinement-bar-offset-top-md;

                            @include media-breakpoint-up(lg) {
                                top: $refinement-bar-offset-top-lg;
                            }
                        }
                    }
                }
            }
        }
    }

    .collapse-toggler::after {
        padding-right: 0;
        right: 0;
    }

    &__wrapper {
        @include media-breakpoint-down(md) {
            background: $refinement-bar-background;
            box-shadow: 0 0 0;
            bottom: 0;
            display: block;
            left: auto;
            margin: 0;
            max-width: calc(100% - #{$gutter});
            overflow: auto;
            padding-left: 0;
            padding-right: 0;
            position: fixed;
            right: 100%;
            top: 0;
            transition: .3s ease right, box-shadow;
            z-index: 100;
        }

        @include media-breakpoint-up(md) {
            display: block;
        }

        &.position-sticky {
            @include media-breakpoint-up(md) {
                top: $refinement-bar-offset-top-md;
            }

            @include media-breakpoint-up(lg) {
                top: $refinement-bar-offset-top-lg;
            }
        }

        &.show {
            box-shadow: 0 10px 10px 0 rgba(0, 0, 0, .04), 0 30px 60px 0 rgba(0, 0, 0, .3);
            right: $gutter;
        }
    }
}

.refinement {
    border-top: $refinement-bar-border;

    &__trigger {

        @include media-breakpoint-down(md) {
            &--filter {
                padding-right: $gutter / 4;
            }

            &--sorting {
                padding-left: $gutter / 4;
            }
        }
    }

    &__label {
        display: inline-block;

        &::first-letter {
            text-transform: uppercase;
        }
    }

    &__header {
        @include font-type(search-refinement-list-item-header-inactive);
        overflow: hidden;
        text-overflow: ellipsis;

        &::after {
            border: 0;
            font-size: .6em;
            height: $refinement-collapse-toggle-size;
            padding-left: 0;
            top: 8px;
            transform: translateY(0) rotate(180deg);
            transform-origin: center;
            width: $refinement-collapse-toggle-size;
        }

        &.active {
            @include font-type(search-refinement-list-item-header-active);
        }

        &.collapsed {
            &::after {
                transform: translateY(0) rotate(0);
            }
        }
    }

    &-size {
        &:hover,
        &.selected {
            background-color: $primary-brand-color;
            color: $text-color-secondary;
        }
    }

    &-maat {
        .refinements-navigation__list-item  {
            flex: 0 0 30%;
            max-width: 30%;
        }
    }

    ul {
        @include list-reset;
    }

    a {
        @include font-type(search-refinement-list-item);
        color: $refinement-bar-link-color;

        .selected {
            @include font-type(bold);
            text-decoration: none;
        }

        .form-control {
            height: auto;
        }
    }
}

.filter-active {
    .agent-search-customer,
    .embeddedServiceHelpButton .helpButton {
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
}

.active-refinements {
    overflow: scroll;

    .refinement-item {
        background: $styleguide-grey-300;
        text-decoration: none;
    }
}


.price-values {
    input[type=number] {
        width: 80px;
    }
}
