// visually-hidden-reset
@mixin visually-hidden-reset {
  border: 0;
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  padding: 0;
  position: static;
  width: auto;
}

// img-container reset
@mixin img-container-reset($display-type: block, $max-width: false) {
  display: block;
  height: auto;
  overflow: visible;
  padding-bottom: 0;

  img,
  .label {
    display: $display-type;
    height: auto;
    left: auto;
    position: relative;
    top: auto;
    transform: none;

    @if $max-width {
      max-width: 100%;
    } @else {
      width: 100%;
    }
  }
}

// slick-carousel-reset
@mixin slick-carousel-reset() {
  touch-action: none;

  .slick-cloned,
  .slick-dots,
  .slick-next,
  .slick-prev {
    display: none !important;
  }

  .slick-list {
    overflow: visible;
    transform: none;
    width: 100%;
  }

  .slick-track {
    left: auto !important;
    opacity: 1 !important;
    transform: none !important;
    width: auto !important;
  }

  .slick-slide {
    float: none !important;
    height: auto !important;
    width: auto !important;
  }
}

@mixin list-reset() {
  list-style: none;
  margin: 0;
  padding: 0;
}
