// Component variables
$navbar-toggler-label-color:            $styleguide-black !default;
$navbar-toggler-icon-color--inactive:   $styleguide-black !default;
$navbar-toggler-icon-color--active:     $styleguide-black !default;
$navbar-toggler-label-text-transform:   uppercase !default;

// Component styles
.navbar-toggler {
  cursor: pointer;
  display: flex;
  height: $primary-header-height-mobile;
  line-height: $primary-header-height-mobile;
  width: 18px;
}

.navbar-toggler-label {
  color: $navbar-toggler-label-color;
  display: block;
  font-size: 14px;
  opacity: 0;
  text-transform: $navbar-toggler-label-text-transform;
  visibility: hidden;
}

.navbar-toggler-icon {
  pointer-events: inherit;
  width: 18px;

  span,
  span::before,
  span::after {
    @include transition($time: .2s);
    background-color: $navbar-toggler-icon-color--inactive;
    content: '';
    display: block;
    height: 2px;
    position: absolute;
    width: inherit;
  }

  span {
    &::before {
      top: -5px;
      will-change: transform, top;
    }

    &::after {
      bottom: -5px;
      will-change: transform, top;
    }
  }

  &.active {
    span {
      background-color: transparent;

      &::before,
      &::after {
        background-color: $navbar-toggler-icon-color--active;
        top: 0;
      }

      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
    }
  }
}
