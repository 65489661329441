@import '../../components/common/list-links';

$listContext: '.list-links';

.page-navigation {
    border-bottom: $border;

    @include media-breakpoint-up(md) {
        border: 0;
    }

    #{$listContext} {
        &__heading {
            @include transition;
            padding-bottom: 10px;
            text-transform: uppercase;
        }

        &__item {
            border-bottom: $border-light;

            &:first-child {
                #{$listContext}__anchor {
                    padding-top: 0;
                }
            }
            &--no-border {
                border: 0;
            }
        }

        &__anchor {
            @include icon(arrow-right, 'after', .6em);
            @include transition(padding);
            display: block;
            padding: 8px 25px 8px 0;
            position: relative;
            text-decoration: none;

            @include media-breakpoint-up(md) {}

            &::after {
                padding-top: inherit;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-72.5%);
            }

            &:hover,
            &:focus,
            &:active {
                text-decoration: underline;
            }
        }
    }
}
