.site-header.hasSuggestions{
    position: fixed;
}

.site-search {

    .category-product-wrapper {
        @include media-breakpoint-down(lg) {
            overflow-y: auto;
            max-height: calc(100vh - 260px);
        }
    }

    .suggestions-wrapper {
        background: $search-suggestions-background-color-desktop;
        box-shadow: $search-suggestions-box-shadow;
        width: 100%;
        position: absolute;
        right: #{$gutter / 2};
        top: calc(100% - #{$gutter / 2});
        z-index: 1;

        @include media-breakpoint-up(lg) {
            width: auto;
            right: -5px;
        }

        .container{
            @include media-breakpoint-up(lg) {
                $padding: 0 #{$gutter / 2};
            }
        }

        .icon {
            margin-right: #{$gutter / 2};
            @include media-breakpoint-up(lg) {
                margin-right: 9px;
            }
        }
    }
}
