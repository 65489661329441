@import './header-variables';

@import './header-primary';
@import './header-secondary';
@import './header-tertiary';

@import './components/menu/_menu-smallscreens';
@import './components/menu/_menu-largescreens';
@import './components/country-selector';
@import './components/minicart';
@import './components/navbar-toggler';
@import './components/quicklinks';
@import './components/search';
@import './components/usps';

// JVP/LROS @TODO: Calculate the padding-top necessary through JS to account for the sticky header
body {
  padding-top: $primary-header-height-mobile + $tertiary-header-height-mobile;

  @include media-breakpoint-up(lg) {
    padding-top: $primary-header-height-desktop + $secondary-header-height-desktop + $tertiary-header-height-desktop;
  }
}

// Component styles
.site-header {
  box-shadow: $header-box-shadow;
  display: flex;
  flex-direction: column;
  left: 0;
  min-width: $site-min-width;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 20;
}
