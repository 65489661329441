$help-color         : #fff;
$trustedshop-color  : #000032;
$brand-color        : #00b67a;

.trustpilot {
    &--default {
        width: 180px;
    }

    .star {
        padding: 0 1px;
    }

    a {
        text-decoration: none;
    }

    &:hover &-popup {
        display: block;
    }

    &-popup {
        background-color: transparent;
        display: none;
        padding-top: 10px;
        position: absolute;

        &-wrapper {
            background-color: $trustedshop-color;
            position: relative;
            z-index: 999;

            &::before {
                background-color: $trustedshop-color;
                border-color: $trustedshop-color;
                content: ' ';
                display: block;
                height: 16px;
                left: 50%;
                position: absolute;
                top: -13px;
                transform: rotate(-34deg) translateX(-50%) skewX(26deg);
                transform-origin: center;
                width: 16px;
            }
        }
    }

    &-help {
        color: $help-color;
    }

    &-write {
        color: $brand-color
    }
}
