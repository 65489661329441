.swatches {
  & &__link {
    text-decoration: none;
  }

  .swatch-circle {
    border-radius: 100%;
    display: inline-block;
    height: 12px;
    overflow: hidden;
    width: 12px;
  }
}
