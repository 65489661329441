
/**
 * CSS Font family names
 *
 * usage:
 * font-family: font-family(primary)
 * font: 12px/24px font-family(secondary)
 */
$font-family: (
  primary: ("Roboto", Arial, serif),
  beautiful: ("HelloBeautiful", Arial, serif),
) !default;

/**
 * Font types, define font styling, optionally combined with breakpoints
 * retrieve font style with the mixin: font-type($name)
 *
 * usage:
 * @include font-type(button)
 */
$font-types: (
  // Base
  default: (font: 400 14px/1.5 font-family(primary)),
  small: (font-size: 12px),
  bold: (font: 700 14px font-family(primary)),
  italic: (font: 400 14px/1.5 font-family(primary), font-style: italic),

  // Buttons
  button: (font: 400 13px font-family(primary), text-transform: uppercase, letter-spacing: 1px),
  button-primary: (font-weight: 700),
  button-disabled: (),
  
  // Headings
  h1: (
    default: (font: 700 20px font-family(primary)),
    md: (font-size: 32px)
  ),
  h2: (
    default: (font: 700 18px font-family(primary)),
    md: (font-size: 24px)
  ),
  h3: (
    default: (font: 700 16px font-family(primary)),
    md: (font-size: 20px)
  ),
  h4: (
    default: (font: 700 14px font-family(primary)),
    md: (font-size: 16px)
  ),
  h5: (
    default: (font: 700 14px font-family(primary)),
    md: (font-size: 16px)
  ),
  h6: (
    default: (font: 700 14px font-family(primary)),
    md: (font-size: 16px)
  ),

  // Forms
  input: (font: 400 14px font-family(primary)),
  formCaption: (font: 400 14px font-family(primary)),

  // Prices
  price-sales: (font: 700 16px font-family(primary)),
  price-from: (font: 400 16px font-family(primary), text-decoration: line-through),

  // Header - Navigation
  navigation-heading-link: (
    default: (font: 700 14px font-family(primary), letter-spacing: 1px)
  ),
  navigation-heading-link-alt: (
    default: (font: 700 14px font-family(primary), letter-spacing: 1px),
    md: (font-size: 13px)
  ),
  navigation-normal-link: (
    default: (font: 400 14px font-family(primary), letter-spacing: 1px)
  ),

  // Header - USPS
  headerUsps: (
    default: (font: 400 11px font-family(primary)),
    md: (font-weight: 700)
  ),

  // Header - Minicart
  minicart-line-item-attribute: (font: 500 12px font-family(primary)),
  minicart-line-item-promo: (font: 700 14px font-family(primary)),
  minicart-line-item-out-of-stock: (font: 700 inherit font-family(primary)),
  minicart-product: (font: 500 inherit/inherit font-family(primary), text-align: right, white-space: nowrap),
  minicart-footer: (font: 500 13px/2.15 font-family(primary)),
  minicart-footer-bold: (font: 700 13px font-family(primary)),
  minicart-upsell: (font: 700 16px/1.5 font-family(primary), text-align: center),

  // Header - Search
  suggestions-label: (font: 700 14px font-family(primary)),
  suggestions-link: (font: 400 14px font-family(primary)),
  suggestions-item-brand: (font: 700 14px font-family(primary)),
  suggestions-item-name: (font: 400 14px font-family(primary)),
  suggestions-item-price: (font: 700 14px font-family(primary)),

  // Product tile
  product-tile-product-brand: (
    default: (font: 700 14px font-family(primary), text-align: center, text-decoration: none, text-transform: none),
    md: (text-align: left)
  ),
  product-tile-product-name: (
    default: (font: 400 14px font-family(primary), text-align: center, text-decoration: none),
    md: (text-align: left)
  ),
  product-tile-attribute-heading: (
    default: (font: 700 14px font-family(primary), text-align: center),
    md: (text-align: left)
  ),
  product-tile-product-description: (font: 500 14px font-family(primary), text-decoration: none),
  product-tile-badge: (font: 700 13px font-family(primary)),

  // Search refinement
  search-refinement-list-header: (font: 700 14px font-family(primary)),
  search-refinement-list-item-header-inactive: (font: 400 14px/1.5 font-family(primary)),
  search-refinement-list-item-header-active: (font: 500 14px/1.5 font-family(primary)),
  search-refinement-list-item: (font: 400 14px/1.5 font-family(primary), text-decoration: none),

  search-header-sort-label: (font: 500 14px font-family(primary)),
  search-header-sort-placeholder: (font: 400 14px font-family(primary)),

  // Cart
  cart-products-header: (font: 700 14px font-family(primary), text-transform: uppercase),

  // Checkout
  checkout-step-text: (font: 700 14px font-family(primary)),
  checkout-line-item-attribute: (font: 500 12px font-family(primary)),
  checkout-line-item-promo: (font: 700 14px font-family(primary)),
  checkout-line-item-out-of-stock: (font: 700 inherit font-family(primary)),
  checkout-product: (font: 500 inherit/inherit font-family(primary), text-align: right, white-space: nowrap),
  checkout-footer: (font: 500 13px/2.15 font-family(primary)),
  checkout-footer-bold: (font: 700 13px font-family(primary)),
  checkout-upsell: (font: 700 16px/1.5 font-family(primary), text-align: center),

  checkout-option-title: (font: 700 14px font-family(primary)),
  checkout-option-price: (font: 500 16px font-family(primary)),

  checkout-order-product-summary-header: (font: 700 14px font-family(primary)),

  // Pagination
  pagination-item: (font: 500 14px font-family(primary), text-decoration: none),
  pagination-item-active: (font: 700 14px font-family(primary), text-decoration: none),

  // Loyalty card
  loyalty-card-account: (
    default: (font: 400 20px font-family(primary)),
    md: (font-size: 24px)
  ),
  loyalty-banner-account: (
    default: (font: 700 32px font-family(primary)),
    md: (font-size: 54px, line-height: 54px),
  )
) !default;
