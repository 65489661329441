.site-footer {
    .legal-list {
        @include list-reset;

        .list {
            &__item {
                display: inline-block;
            }
        }

        a.list {
            &__link {
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}
