$filter-header-desktop-margins: $grid-gutter-width / 2 * -1 !default;

.refinement-bar {
    header {
        z-index: 1;

        @include media-breakpoint-up(md) {
            margin-left: $filter-header-desktop-margins;
            margin-right: $filter-header-desktop-margins;
        }

        .btn-reset {
            letter-spacing: normal;
            text-transform: none;

            .icon {
                font-size: 10px;
            }
        }

        .icon--close {
            font-size: 15px;
        }
    }

    aside {
        flex-grow: 1;
        overflow: auto;
    }

    footer {
        width: 100%;
        background-color: $styleguide-white;
    }
}
