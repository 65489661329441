// Base
$formfield-height:                          42px !default;
$formfield-border-radius:                   3px !default;
$formfield-border-color:                    $border-color !default;
$formfield-border-active-color:             $border-color !default;
$formfield-background-color:                $styleguide-white !default;

// Text
$formfield-text-color:                      $text-color-primary !default;
$formfield-placeholder-color:               $styleguide-grey-600 !default;
$formfield-error-color:                     $error-color !default;
$formfield-caption-color:                   $text-color-primary !default;

// Disabled state
$formfield-disabled-border-color:           $border-color !default;
$formfield-disabled-background:             $styleguide-grey-400 !default;
$formfield-disabled-placeholder:            $styleguide-grey-600 !default;

// Radio buttons
$formfield-radio-size:                      18px !default;
$formfield-radio-border:                    $formfield-border-color !default;
$formfield-radio-selected-color:            $text-color-primary !default;

// Checkboxes
$formfield-checkbox-size:                   18px !default;
$formfield-checkbox-border:                 $formfield-border-color !default;
$formfield-checkbox-border-radius:          $formfield-border-radius !default;
$formfield-checkbox-selected-color:         $primary-brand-color !default;

// Dropdowns
$formfield-select-border-color:             $formfield-border-color !default;
$formfield-select-border-active-color:      $formfield-border-active-color !default;
$formfield-select-background-color:         $formfield-background-color !default;

$formfield-select-text-color:               $formfield-text-color !default;
$formfield-select-placeholder-color:        $formfield-placeholder-color !default;
$formfield-select-error-color:              $formfield-error-color !default;

$formfield-select-disabled-background:      $formfield-disabled-background !default;
$formfield-select-disabled-placeholder:     $formfield-disabled-placeholder !default;
