$context: '.page-title';

body {
    #{$context} {
        @include font-type(h1);

        background: none;
        color: inherit;
        display: block;
        left: auto;
        margin: 0;
        padding: 25px 0;
        position: relative;
        text-align: center;

        &::before {
            display: none;
        }

        @include media-breakpoint-up(md) {
            margin: 0 0 15px;
            padding-bottom: 10px;
            text-align: left;

            &::before {
                background: $primary-brand-color;
                content: '';
                display: block;
                height: 3px;
                left: 0;
                max-width: 100%;
                position: absolute;
                top: 100%;
                width: 150px;
            }
        }
    }

    .justify-content-center > #{$context}::before {
        @include media-breakpoint-up(md) {
            left: 50%;
            transform: translateX(-50%);
        }
    }
}
