.quicklinks__customer-service {
    

    @include media-breakpoint-up(md) {
        position: relative;
    }

    .popover {
        background: $styleguide-white;
        border: 0;
        border-radius:0;
        box-shadow: -8px 0 20px 0 rgba($styleguide-grey-800, .15);
        left: auto;
        max-width: 290px;
        opacity: 0;
        top: 50px;
        right: 0px;
        visibility: hidden;
        width: 290px;

        @include media-breakpoint-up(md) {
            max-width: 340px;
            right: -15px;
            top: 45px;
            width: 340px;
        }

        h3{
            position: relative;
            
            &::after{
                background-color: $styleguide-orange;
                bottom: 0;
                content: "";
                height: 2px;
                left: 0;
                position: absolute;
                width: 50%;
            }
        }

        h4 {
            span {
                font-size: 14px;
                font-style: italic;
                font-weight: normal;
            }
        }

        .icon {
            color: $styleguide-orange;
            font-size: 18px;
            padding: 3px 8px 0 0;
        }

        &.show {
            opacity: 1;
            transition-delay: 0s;
            visibility: visible;
        }
        
        .popover-body {
            padding: 0;
        }

        .popover-close{
            background: none;
            border: none;
            cursor: pointer;
            position: absolute;
            right: 5px;
            top: 10px;
            
            @include media-breakpoint-up(md) {
                right: 15px;
                top: 22px;
            }

            .icon {
                color: $styleguide-black;
            }
        }

    }
}