$buttons:                                   () !default;
$button-height:                             $formfield-height !default;
$button-border:                             0;
$button-border-radius:                      $formfield-border-radius !default;

// Primary button
$button-primary-text-font:                  'button-primary' !default;
$button-primary-text-color:                 $text-color-secondary !default;
$button-primary-background:                 $primary-brand-color !default;
$button-primary-border:                     0 !default;

$button-primary-active-text-color:          $text-color-secondary !default;
$button-primary-active-background:          $secondary-brand-color !default;
$button-primary-active-border:              0 !default;

// Secondary button
$button-secondary-text-font:                false !default;
$button-secondary-text-color:               $primary-brand-color !default;
$button-secondary-background:               $background-color !default;
$button-secondary-border:                   1px solid $primary-brand-color !default;

$button-secondary-active-text-color:        $text-color-secondary !default;
$button-secondary-active-background:        $primary-brand-color !default;
$button-secondary-active-border:            1px solid $primary-brand-color !default;

// Tertiary button
$button-tertiary-text-font:                 false !default;
$button-tertiary-text-color:                $text-color-secondary !default;
$button-tertiary-background:                $styleguide-grey-800 !default;
$button-tertiary-border:                    0 !default;

$button-tertiary-active-text-color:         $text-color-secondary !default;
$button-tertiary-active-background:         $styleguide-grey-900 !default;
$button-tertiary-active-border:             0 !default;

// quaternary button
$button-quaternary-text-font:               false;
$button-quaternary-background:              $background-color !default;
$button-quaternary-border:                  1px solid $styleguide-grey-400 !default;

// Disabled button
$button-disabled-text-color:                $text-color-secondary !default;
$button-disabled-background:                $styleguide-grey-400 !default;
$button-disabled-border:                    0 !default;
