.btn,
a.btn {
    &,
    &[href],
    &:not([href]) {
        @include font-type(button);
        @include transition;
        border-radius: $button-border-radius;
        border: $button-border;
        cursor: pointer;
        display: inline-block;
        padding: 13px 15px 14px;
        overflow: hidden;
        position: relative;
        text-align: center;
        text-decoration: none;
        vertical-align: middle;

        &:focus {
            outline: none;
        }

        &:disabled,
        &[disabled] {
            &,
            &:hover,
            &:active,
            &:focus {
                @include font-type(button-disabled);
                background-color: $button-disabled-background;
                border: none;
                color: $button-disabled-text-color;
                cursor: not-allowed;
            }
        }
    }

    &-primary {
        &,
        &[href],
        &:not([href]) {
            @if ($button-primary-text-font) {
                @include font-type($button-primary-text-font);
            }

            background-color: $button-primary-background;
            border: $button-primary-border;
            color: $button-primary-text-color;

            &:not(:disabled),
            &:not([disabled]) {
                &:hover,
                &:active,
                &:focus {
                    background-color: $button-primary-active-background;
                    border: $button-primary-active-border;
                    color: $button-primary-active-text-color;
                }
            }
        }
    }

    &-secondary {
        &,
        &[href],
        &:not([href]) {
            @if ($button-secondary-text-font) {
                @include font-type($button-secondary-text-font);
            }

            background: $button-secondary-background;
            border: $button-secondary-border;
            color: $button-secondary-text-color;

            &:not(:disabled),
            &:not([disabled]) {
                &:hover,
                &:active,
                &:focus {
                    background-color: $button-secondary-active-background;
                    border: $button-secondary-active-border;
                    color: $button-secondary-active-text-color;
                }
            }
        }

        &.js-open-chat {
            margin-top: 15px;
        }

        &--outline {
            &,
            &[href],
            &:not([href]) {
                background: transparent;
                border: $button-secondary-border;
                color: $button-secondary-text-color;

                &:not(:disabled),
                &:not([disabled]) {
                    &:hover,
                    &:active,
                    &:focus {
                        background-color: transparent;
                        border-color: $color-copper;
                        color: $color-copper;
                    }
                }
            }
        }
    }

    &-tertiary {
        &,
        &[href],
        &:not([href]) {
            @if ($button-tertiary-text-font) {
                @include font-type($button-tertiary-text-font);
            }

            background-color: $button-tertiary-background;
            border: $button-tertiary-border;
            color: $button-tertiary-text-color;

            &:not(:disabled),
            &:not([disabled]) {
                &:hover,
                &:active,
                &:focus {
                    background-color: $button-tertiary-active-background;
                    border: $button-tertiary-active-border;
                    color: $button-tertiary-active-text-color;
                }
            }
        }
    }

    &-quaternary {
        &,
        &[href],
        &:not([href]) {
            @if ($button-quaternary-text-font) {
                @include font-type($button-quaternary-text-font);
            }

            background-color: $button-quaternary-background;
            border: $button-quaternary-border;
            border-radius: 4px;

            &:not(:disabled),
            &:not([disabled]) {
                &:hover,
                &:active,
                &:focus {
                    background-color: $color-philippine-silver;
                    border-color: $color-philippine-silver;
                    color: $styleguide-grey-900;
                }
            }
        }
    }

    &-dark {
        &,
        &[href],
        &:not([href]) {
            background-color: $styleguide-black;
            color: $button-primary-text-color;
        }
    }

    &.btn--outline {
        &,
        &[href],
        &:not([href]) {
            background-color: transparent;
            border: 1px solid $color-philippine-silver;
            color: $color-philippine-silver;
        }

        &:not(:disabled),
        &:not([disabled]) {
            &:hover,
            &:active,
            &:focus {
                background-color: $color-philippine-silver;
                border-color: $color-philippine-silver;
                color: $color-arsenic;
            }
        }
    }

    &.btn-dark--outline {
        &,
        &[href],
        &:not([href]) {
            background-color: transparent;
            border: 1px solid $color-arsenic;
            color: $color-arsenic;
        }

        &:not(:disabled),
        &:not([disabled]) {
            &:hover,
            &:active,
            &:focus {
                background-color: $color-arsenic;
                border-color: $color-arsenic;
                color: $styleguide-white;
            }
        }
    }

    &.btn-icon {
        border-radius: 2px;
        height: 34px;
        line-height: 1;
        padding: 0;
        text-align: center;
        width: 34px;

        .icon {
            font-size: 16px;
        }
    }

    // My Account redesign phone number dropdown toggle button
    &.dropdown-toggle {
        align-items: center;
        appearance: none;
        background-color: $styleguide-white;
        border-radius: 3px 0 0 3px;
        border-right: 1px solid $color-cultured;
        box-shadow: none;
        box-sizing: border-box;
        color: $styleguide-grey-900;
        display: flex;
        height: 42px;
        line-height: 42px;
        outline: none;
        padding: 0 18px;
        width: 100%;
    }
}

.btn-round {
    align-items: center;
    appearance: none;
    background-color: $styleguide-grey-300;
    border: none;
    border-radius: 50%;
    color: $styleguide-grey-900;
    display: flex;
    flex: 0 0 auto;
    height: 36px;
    justify-content: center;
    text-align: center;
    width: 36px;
}

// Disabled generate function as it's not easy to add hover/focus states etc.
// Went for simpler solution by just defining the classes/styling above
//
// @include generateButtons();

button {
    &:focus {
        outline: none;
    }
}

.btn-quantity-changer {
    align-items: center;
    background: $styleguide-white;
    border-radius: 100%;
    border: $border;
    cursor: pointer;
    display: inline-flex;
    height: 30px;
    justify-content: center;
    width: 30px;

    &[disabled] {
        background-color: $button-disabled-background;
        color: $button-disabled-text-color;
        cursor: not-allowed;
    }

    .icon {
        color: $text-color-primary;
        font-size: 11px;
        pointer-events: none;
        vertical-align: baseline;
    }
}
