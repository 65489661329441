// Component variables
$background-color: $styleguide-grey-300 !default;

$icon-size: 25px !default;

$disclaimer-text-color: $styleguide-grey-800 !default;

// Component imports
@import "./content-links";
@import "./icon-links";
@import "./legal-links";

// Component styles
.site-footer {
    position: relative;

    @include media-breakpoint-up(lg) {
        padding-bottom: 0;
        padding-top: 50px;
    }

    .footer {
        &__col {
            @include media-breakpoint-up(xl) {
                width: 75%;
            }
        }
    }

    .col-newsletter {
        flex: 1 1 auto;
    }

    a.list__heading {
        @include font-type(bold);
        text-decoration: none;
    }

    a.list__link {
        @include font-type(footer-link);
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .list {
        &__heading {
            border: 0;
            display: block;

            &::after {
                right: 0;
                transform: translateY(-50%) rotate(180deg);
            }

            &.collapsed {
                &::after {
                    transform: translateY(-50%);
                }
            }
        }

        &__heading {
            > .list__item {
                padding-top: 0;
            }
        }
    }

    .footer {
        &__disclaimer {
            color: $styleguide-white;
        }
    }

    .footer-divider {
        border-bottom: 2px solid $styleguide-grey-600;
        margin: auto;
        width: 93%;

        @include media-breakpoint-up(md) {
            width: 98%;
        }
    }

    .container-fluid {
        padding: 15px 0;

        &.grey-100 {
            background-color: $styleguide-grey-100;
        }

        &.grey-200 {
            background-color: $styleguide-grey-200;
        }

        &.anthracite {
            background-color: $styleguide-anthracite;
            color: $styleguide-white;

            a {
                color: $styleguide-white;
            }
        }

        &.legal {
            background-color: #2d3741;
            a {
                color: $styleguide-white;
            }
        }
    }
}
