$product-cross-sell-containers-max-width:               320px !default;
$product-cross-sell-img-container-padding:              120% !default;
$product-cross-sell-large-mobile-atc-btn:               true !default;

$context: '.product-cross-sell';

#{$context} {
    &__main-product,
    &__actions {
        margin: 0 auto;

        @include media-breakpoint-up(md) {
            max-width: $product-cross-sell-containers-max-width;
        }
    }

    &__recommendations {
        #{$context}__item {
            @include media-breakpoint-down(sm) {
                border-width: 0;
            }

            .btn-primary .icon {
                pointer-events: none;
            }

            .img-container::before {
                padding-bottom: $product-cross-sell-img-container-padding;
            }

            @if $product-cross-sell-large-mobile-atc-btn {
                @include media-breakpoint-down(sm) {
                    .btn-primary {
                        height: 55px;
                        width: 55px;

                        .icon {
                            font-size: 23px;
                        }
                    }
                }
            }
        }
    }

    .product-tile {
        .product-tile__name, // Can't use & because we need more specificity
        .product-tile__brand, // Can't use & because we need more specificity
        .price {
            text-align: left;
        }

        .product-attribute {
            @include font-type(minicart-line-item-attribute);
            color: $styleguide-grey-500;
        }

        &.product-added {
            .product-added {
                &__container {
                    visibility: visible;
                }

                &__label {
                    height: 21px;
                    width: 110px;

                    span {
                        opacity: 1;
                    }
                }

            }
        }

        .product-added {
            &__container {
                background-color: rgba($styleguide-white, .8);
                bottom: 0;
                left: 0;
                position: absolute;
                right: 0;
                top: 0;
                visibility: hidden;
            }

            &__label {
                height: 0;
                overflow: hidden;
                transition: .4s ease-in-out width, .2s ease-in-out height .3s;
                white-space: nowrap;
                width: 0;

                span {
                    opacity: 0;
                    transition: .2s ease opacity .5s;
                }

                .icon {
                    color: $primary-brand-color;
                }
            }

        }
    }

    .product-carousel__heading {
        border-bottom: 2px solid $primary-brand-color;

        @include media-breakpoint-up(md) {
            border-bottom: 0;
        }
    }
}
