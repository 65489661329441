.category-slot {
    &__refinement-bar,
    &__end-page {
        .html-slot-container {
            margin-top: 40px;
        }

        h1,h2,h3,h4,h5,h6 {
            margin-bottom: 15px;
        }
    }

    &__refinement-bar {
        img {
            margin-bottom: 40px;
        }
    }
}
