$search-icon-color--mobile: $styleguide-orange;
$search-icon-color--desktop: $styleguide-orange;

.search-bar {
    margin: 0 0 0 auto;
    flex-grow: 1;

    @include media-breakpoint-up(sm) {
        flex-grow: 0;
    }
}

.site-search {
    &.fit-content {
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    .search-field,
    .simple-search-field {
        background-color: $styleguide-grey-300;
        border: 0;
        height: 34px;
        left: auto;
        line-height: 1;
        position: relative;
        padding: 0 30px 0 10px;
        top: auto;
        right: 0;
        min-width: 245px;

        @include media-breakpoint-up(lg) {
            opacity: 1;
            transform: none;
            width: 300px;

            &:not(.active) {
                border-color: $border-color-medium;
                @include font-type(small);
            }
        }

        .quicklinks__search & {
            background-color: $styleguide-white;
        }
    }

    .search-icon {
        background-color: $styleguide-transparent;
        color: $search-icon-color--mobile;
        border: 0;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        right: -10px;

        @include media-breakpoint-up(lg) {
            color: $search-icon-color--desktop;
            font-size: 18px;
        }

        &.simple-search-icon:hover {
            opacity: .5;
        }
    }
}
