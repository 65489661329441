$modal-default-width:                             500px !default;
$modal-md-width:                                  568px !default;
$modal-background-color:                          $styleguide-white !default;
$modal-box-shadow-color:                          rgba($styleguide-black, .5) !default;
$modal-backdrop-background-color:                 $styleguide-white !default;

body {
	&.modal-open {
		min-height: 100%;
		min-width: 100%;
		position: initial;
		overflow: hidden;
	}

	.modal-backdrop {
		@include transition;
		background: $modal-backdrop-background-color;
		bottom: 0;
		height: 100vh;
		left: 0;
		position: fixed;
		right: 0;
		top: 0;
		width: 100vw;
		z-index: 20;

		&.show {
			opacity: .9;
		}

        // My Account Redesign 2024
        &.my-account {
            background: rgba($styleguide-black, .5);
        }
	}

	.modal {
		&:not(.show) {
			pointer-events: none;
			position: absolute;
		}

		&,
		&.show,
		&.fade {
	 		.modal-dialog {
				background-color: $modal-background-color;
				border-radius: 0;
				box-shadow: 0 10px 25px $modal-box-shadow-color;
				left: 30px;
				margin: 0;
				max-height: 90vh;
				min-width: calc(320px - 60px);
				outline: none;
				overflow-x: hidden;
				overflow-y: auto;
				position: fixed;
				right: 30px;
				top: 50%;
				transform: translateY(-50%);
				transition: none;
				width: calc(100vw - 60px);
				z-index: 1000;

				@include media-breakpoint-up(md) {
					left: 50%;
					max-width: 100%;
					min-width: auto;
					transform: translate(-50%, -50%);
                    width: $modal-default-width;

                    &.modal-md {
                        width: $modal-md-width;
                    }
				}
			}
		}

        // My Account Redesign 2024
        &.my-account {
            .modal-dialog {
                border-radius: 10px;
                box-shadow: none;
            }
        }

		&.modal--fullscreen {
			.modal-dialog {
				height: 100%;
				left: 0;
				max-height: none;
				max-width: none;
				top: 0;
				transform: none;
				width: 100%;

				.modal-content {
					min-height: 100%;
				}
			}
		}

		&.modal--wide {
			.modal-dialog {
				max-width: 1000px;

				@include media-breakpoint-up(md) {
					width: 95vw;
				}
			}
		}

		&.modal--img {
			.modal-body {
				padding: 0;
			}
		}

		.modal-content {
			border: 0;
			border-radius: 0;
			min-height: 200px;

			> .modal-close {
				-webkit-appearance: unset;
				cursor: pointer;
				opacity: 1;
				position: absolute;
				right: 25px;
				top: 25px;
			}
		}

		.modal-header,
		.modal-body,
		.modal-footer {
			border: 0;
			padding: 15px;
			width: 100%;

			@include media-breakpoint-up(sm) {
				padding: 30px;
			}
		}

		.modal-header {
			margin: -1px; // Fixing a weird bug where there's a white border visible, in some resolutions.
			padding-bottom: 10px;
			padding-right: 50px;
			padding-top: 25px;
			width: calc(100% + 2px); // Fixing a weird bug where there's a white border visible, in some resolutions.

			.modal-close {
				-webkit-appearance: unset;
				cursor: pointer;
				opacity: 1;
				position: absolute;
				right: 25px;
				top: 25px;
				border: 0;
				background: none;
			}

			+ .modal-body {
				padding-top: 10px;
			}
		}

		.modal-body {
			padding-top: 45px;

			p:last-child {
				margin-bottom: 0;
			}

			.modal-close {
				-webkit-appearance: unset;
				cursor: pointer;
				opacity: 1;
				position: absolute;
				right: 25px;
				top: 25px;
			}
		}

		.modal-footer {
			display: block;

			@include media-breakpoint-up(sm) {
				padding-top: 0;
				text-align: right;
			}

			.btn {
				display: block;
				margin: 20px 0 0;

				@include media-breakpoint-up(sm) {
					float: right;
					margin: 0 0 0 10px;
				}

				&:first-child {
					margin-top: 0;
				}

				&.btn-cta {
					display: inline-block;
				}
			}
		}
	}

	.modal-pane {
		@include transition(margin);
		flex: 0 0 100%;
		margin-left: -100%;
		min-width: 100%;
		overflow: hidden;

		&-container {
			display: flex;
			overflow: hidden;
		}

		&--active {
			margin-left: 0;

			~ .modal-pane {
				margin-left: 100%;
			}
		}
	}
}
