$copy-to-clipboard-trigger-background:          transparent !default;
$copy-to-clipboard-trigger-border:              $border-light !default;
$copy-to-clipboard-trigger-border-radius:       3px !default;
$copy-to-clipboard-trigger-border-color-active: $primary-brand-color !default;
$copy-to-clipboard-trigger-icon:                'link' !default;
$copy-to-clipboard-trigger-icon-pos:            before !default;
$copy-to-clipboard-trigger-icon-size:           inherit !default;
$copy-to-clipboard-trigger-padding:             5px 10px !default;
$copy-to-clipboard-trigger-text-color:          $text-color-primary !default;

$copy-to-clipboard-message-background:          $primary-brand-color !default;
$copy-to-clipboard-message-border:              0 !default;
$copy-to-clipboard-message-border-radius:       100px !default; // Will create a "pill" shape
$copy-to-clipboard-message-icon:                'clipboard' !default;
$copy-to-clipboard-message-icon-pos:            before !default;
$copy-to-clipboard-message-icon-size:           inherit !default;
$copy-to-clipboard-message-padding:             5px 20px !default;
$copy-to-clipboard-message-position:            translate(-50%, -25%) !default;
$copy-to-clipboard-message-text-color:          $text-color-secondary !default;


body .copy-to-clipboard {
    cursor: pointer;
    display: inline-block;
    position: relative;
    z-index: 1;

    &__trigger {
        background: $copy-to-clipboard-trigger-background;
        border: $copy-to-clipboard-trigger-border;
        border-radius: $copy-to-clipboard-trigger-border-radius;
        color: $copy-to-clipboard-trigger-text-color;
        display: inline-block;
        padding: $copy-to-clipboard-trigger-padding;
        pointer-events: none;
        text-decoration: none;
        
        @if ($copy-to-clipboard-trigger-icon) {
            @include icon($copy-to-clipboard-trigger-icon, $copy-to-clipboard-trigger-icon-pos, $copy-to-clipboard-trigger-icon-size);
            
            &::before {
                padding-right: 6px;
                vertical-align: top;
            }
        }

        &:hover,
        &:focus,
        &:active,
        &.active {
            border-color: $copy-to-clipboard-trigger-border-color-active;
        }
    }
    
    &__message {
        @include transition(opacity);
        background: $copy-to-clipboard-message-background;
        border: $copy-to-clipboard-message-border;
        border-radius: $copy-to-clipboard-message-border-radius;
        color: $copy-to-clipboard-message-text-color;
        left: 50%;
        opacity: 0;
        padding: $copy-to-clipboard-message-padding;
        position: absolute;
        top: 100%;
        transform: $copy-to-clipboard-message-position;
        white-space: nowrap;

        @if ($copy-to-clipboard-message-icon) {
            @include icon($copy-to-clipboard-message-icon, $copy-to-clipboard-message-icon-pos, $copy-to-clipboard-message-icon-size);
        
            &::before {
                padding-right: 6px;
                vertical-align: top;
            }
        }

        &--active {
            opacity: 1;
        }
    }
}
