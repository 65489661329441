$alert-margin-bottom:           25px !default;

$alert-info-background:         $primary-brand-color !default;
$alert-info-border:             $primary-brand-color !default;
$alert-info-text:               $text-color-secondary !default;

$alert-success-background:      $primary-brand-color !default;
$alert-success-border:          $primary-brand-color !default;
$alert-success-text:            $text-color-secondary !default;

$alert-warning-background:      $warning-color !default;
$alert-warning-border:          $warning-color !default;
$alert-warning-text:            $text-color-secondary !default;

$alert-danger-background:       $error-color !default;
$alert-danger-border:           $error-color !default;
$alert-danger-text:             $text-color-secondary !default;


//
// Override alert styles from bootstrap
// @mixin alert-variant($background, $border, $color)
//
.alert {
    @include border-radius(0);
    margin-bottom: $alert-margin-bottom;
}

.alert-success {
    @include alert-variant($alert-success-background, $alert-success-border, $alert-success-text);
}

.alert-info {
    @include alert-variant($alert-info-background, $alert-info-border, $alert-info-text);
}

.alert-warning {
    @include alert-variant($alert-warning-background, $alert-warning-border, $alert-warning-text);
}

.alert-danger {
    @include alert-variant($alert-danger-background, $alert-danger-border, $alert-danger-text);
}
