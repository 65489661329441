$countdown-bg-orange:                       $primary-brand-color;
$countdown-bg-red:                          $styleguide-red !default;
$countdown-bg-antracite:                   #343e48 !default;

.countdown-bg {
    display: none;

    &.timer-on,
    &.edit-mode {
        display: flex;
        flex-direction: column;
    }

    &-black {
        background-color: $styleguide-black;
    }

    &-orange {
        background-color: $countdown-bg-orange;
    }

    &-antracite {
        background-color: $countdown-bg-antracite;
    }

    &-red {
        background-color: $countdown-bg-red;
    }

    &.countdown-text-white, .countdown-text-white {
        color: $text-color-secondary;
    }

    &.countdown-text-black, .countdown-text-black {
        color: $text-color-primary;
    }

    &.page-callout {
        padding: 15px;

        @include media-breakpoint-up(md) {
            align-items: center;
            flex-direction: row;
            justify-content: center;
        }
    }
}

.countdown-title {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;

    .page-callout & {
        padding: 0 15px 0 15px;
    }
}

.countdown-title + .countdown-timer {
    .page-callout & {
        // padding: 10px 15px 15px 15px;
    }
}

.countdown-timer {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    padding: 20px;

    .page-callout & {
        padding: 0;
    }

    .page-designer-ui & {
        display: block;
    }

    &-item {
        display: flex;

        &-value {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .page-callout & {
                flex-direction: row;
            }
        }

        &-label {
            font-size: 0.6rem;
            margin-bottom: 0;
            margin-top: 5px;
            text-transform: uppercase;

            .page-callout & {
                font-size: 0.6rem;
                margin-left: 5px;
                margin-right: 10px;
            }

            @include media-breakpoint-up(md) {
                font-size: 0.8rem;

                .col-md-3 & {
                    font-size: 0.6rem;
                }
                .page-callout & {
                    font-size: 0.6rem;
                }
            }

            @include media-breakpoint-up(lg) {
                font-size: 1.2rem;

                .col-md-3 & {
                    font-size: 0.6rem;
                }

                .col-md-4 &,
                .col-lg-3 & {
                    font-size: 1rem;
                }

                .page-callout & {
                    font-size: 0.7rem;
                }
            }
        }

        .seperator {
            padding: 0 20px;
            
            .page-callout & {
                display: none;
            }

            @include media-breakpoint-up(md) {
                padding: 0 20px;

                .col-md-3 &,
                .col-md-4 & {
                    padding: 0 5px;
                }
            }

            @include media-breakpoint-up(lg) {
                padding: 0 50px;

                .col-md-3 & {
                    padding: 0 20px;
                }

                .col-md-4 & {
                    padding: 0 25px;
                }

                .col-lg-3 & {
                    padding: 0 10px;
                }
            }
        }

        span {
            font-size: 2rem;
            line-height: 1;

            .page-callout & {
                font-size: 1.5rem;
            }

            @include media-breakpoint-up(md) {
                font-size: 3rem;

                .col-md-3 & {
                    font-size: 1rem;
                }

                .col-md-4 &,
                .col-md-6 & {
                    font-size: 2rem;
                }

                .page-callout & {
                    font-size: 1.5rem;
                }
            }

            @include media-breakpoint-up(lg) {
                font-size: 5rem;

                .col-md-3 & {
                    font-size: 2rem;
                }
                
                .col-md-4 & {
                    font-size: 3rem;
                }

                .col-md-6 & {
                    font-size: 4rem;
                }

                .col-lg-3 & {
                    font-size: 2.5rem;
                }

                .page-callout & {
                    font-size: 2rem;
                }
            }
        }
    }
}