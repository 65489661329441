.experience-asets-contentCard {
	display: flex;
	height: 100%;


	.bg-red & {
		color: $styleguide-white;
	}

	.bg-orange & {
		color: $styleguide-white;
	}
}