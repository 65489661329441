.grid-header {
    &__result-count {
        .result-count {
            &__label,
            &__count {
                white-space: nowrap;
            }

            &__count {
                color: $styleguide-grey-800;
            }
        }
    }

    &__btn {
        height: 42px;
        
        &.btn {
            padding: 0;
        }
    }

    &__sorting {
        .sorting {
            &__label {
                .grid-header & {
                    display: none;
                }
            }

            &__select {
                @include font-type(search-header-sort-placeholder);

                background-color: $styleguide-white;
                border: $border-medium;

                .grid-header & {
                    letter-spacing: 1px;
                    text-transform: uppercase;
                }
            }
        }
    }
}

