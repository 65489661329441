$slick-dot-color-inactive:      $styleguide-grey-400 !default;
$slick-dot-color-active:        $styleguide-grey-600 !default;
$slick-dot-size:                10px !default;
$slick-dot-gutter:              6px !default;
$slick-disabled-opacity:        .4 !default;

.slick-track {
    min-width: 100%;
}

.slick-dots {
    @include list-reset;
    display: flex;
    justify-content: center;
    margin: 15px 0 0;
    width: 100%;

    li {
        background: $slick-dot-color-inactive;
        border-radius: 50%;
        cursor: pointer;
        height: $slick-dot-size;
        margin: 0 ($slick-dot-gutter / 2);
        padding: 0;
        width: $slick-dot-size;

        &.slick-active {
            background: $slick-dot-color-active;
        }
    }

    button {
        display: none;
    }
}

.slick-prev {
    @include arrowButtonLeft();
}

.slick-next {
    @include arrowButtonRight();
}

.slick-disabled {
    cursor: not-allowed;
    opacity: $slick-disabled-opacity;
}
