// base checkbox styling and bootstrap overrides
.form-check-input,
[type="checkbox"] {
    margin: 0;
    position: relative;
}

[type="checkbox"],
.icon-checkbox {
    cursor: pointer;
}

.refinements-checkbox {
    height: 0 !important;
}

.input-checkbox {
    opacity: 0.01;
    position: absolute;

    &__container {
        display: inline-block;
        padding-bottom: 5px
    }

    &__caption {
        @include icon(check, after, #{$formfield-checkbox-size - 10px});

        cursor: pointer;
        margin: 0;
        position: relative;

        &::before {
            background: $formfield-background-color;
            border: 1px solid $formfield-checkbox-border;
            border-radius: $formfield-border-radius;
            content: '';
            display: inline-block;
            height: $formfield-checkbox-size;
            margin-bottom: 0;
            margin-right: 8px;
            vertical-align: middle;
            width: $formfield-checkbox-size;
        }

        &::after {
            @include transition;

            color: $formfield-checkbox-selected-color;
            display: block;
            left: calc(#{$formfield-radio-size / 4} - 1px);
            opacity: 0;
            position: absolute;
            top: 10.5px;
            transform: translateY(-50%);
        }
    }

    &:checked {
        ~ .input-checkbox__caption::after {
            opacity: 1;
        }

        &.checkbox-secondary {
            + .input-checkbox__caption {
                &::before {
                    background-color: $color-arsenic;
                }

                &::after {
                    animation: bouncybouncyRotated .3s;
                    opacity: 1;
                }
            }
        }
    }

    // My Account redesign, for consistency
    &.checkbox-secondary {
        + .input-checkbox__caption {
            display: flex;

            &::before {
                border: 1px solid $color-arsenic;
                border-radius: 3px;
                height: 20px;
                transition: background-color .3s ease;
                flex: 0 0 auto;
                width: 20px;
            }

            &::after {
                background-color: transparent;
                border-bottom: 2px solid $styleguide-white;
                border-left: 2px solid $styleguide-white;
                border-radius: 0;
                content: '';
                height: 5px;
                left: 6px;
                opacity: 0;
                top: 6px;
                transform: rotate(-45deg);
                transition: opacity .3s ease;
                width: 9px;
            }
        }
    }
}
