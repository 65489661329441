
@mixin absolute-scaling-image($scale: 1.05) {
  @include transition(transform);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;

  &:hover {
    transform: scale($scale);
  }
}

@mixin absolute-image() {
  @include transition(transform);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

@mixin image-overlay($color: $color-styleguide-black-absolute, $opacity: 0, $opacity-hover: .75) {
  @if $color {
    &::after {
      background: rgba($color, $opacity);
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      transition: all 300ms ease-in-out;
    }

    &:hover {
      &::after {
        background: rgba($color, $opacity-hover);
      }
    }
  } @else {
    @error 'Must pass a $color argument to mixin image-overlay';
  }
}

// Responsive image
@mixin resp-img($display-type: block, $max-width: false) {
	display: $display-type;

	@if $max-width {
		max-width: 100%;
	} @else {
		width: 100%;
	}

	height: auto;
}
