.quicklinks__user {
    position: relative;

    .btn.btn-primary,
    .btn.btn-secondary {
        width: 100%;
        padding: 10px;
    }

    .login-overlay__title {
        font: 700 20px Roboto, Arial, serif;
        font-size: 22px;
    }

    .login-overlay__close {
        position: absolute;
        right: 20px;
        top: 10px;
    }

    // Login Overlay
    .login-overlay {
        display: none;
        z-index: 20;
        position: absolute;
        top: 30px;
        right: -100px;
        width: 340px;
        box-shadow: 10px 10px 2000px 3000px rgba(0, 0, 0, 0.5);

        @include media-breakpoint-up(md) {
            width: 330px;
            right: -115px;
        }
        @include media-breakpoint-down(sm) {
            display: block;
        }
        .cta-account-creation {
            margin: 5px 0;
            font-size: 12px;
        }
        .veil {
            &,
            .underlay,
            .spinner {
                position: absolute !important;
            }
        }
    }

    .login-overlay__arrow {
        content: "";
        display: block;
        position: absolute;
        right: 9rem;
        top: -10px;
        border-bottom: 15px solid white;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;

        @include media-breakpoint-down(sm) {
            display: none;
        }

        &.show {
            @include media-breakpoint-down(sm) {
                display: block;
                right: 5.4rem;
            }
        }
    }

    .login-overlay__success {
        display: none;
    }

    .login-overlay__inner {
        background-color: white;
        position: absolute;
        padding: 20px;
        width: 100%;
        &.success {
            box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.25);
            color: unset;
        }
    }

    .login__wrapper {
        margin-bottom: 30px;
    }

    .login-overlay__account {
        display: flex;
        flex-flow: column;
    }

    .login-overlay__account-link,
    .login-overlay__subtitle,
    .login-overlay__account-description {
        display: block;
    }

    .login-overlay__subtitle {
        margin-bottom: 12px;
        font-size: 12px;
    }

    .login-overlay__account-link {
        cursor: pointer;
        margin-top: 10px;
        font-size: 12px;
        text-decoration: underline;
    }
    .login-overlay-hide {
        @include media-breakpoint-down(sm) {
            left: 340px;
            box-shadow: none;
            transition: all 0.6s ease-in-out;
        }
    }
    .login-overlay-hide.show {
        @include media-breakpoint-down(sm) {
            left: -195px;
            transition: all 0.6s ease-in-out;
            box-shadow: 10px 10px 2000px 3000px rgba(0, 0, 0, 0.5);
        }
    }
}

.promoteLoginOverlay {
    div.html-slot-container {
        .cta-account-creation {
            font-size: 12px;
            margin: 5px 0;
        }
    }

    .btn.btn-primary,
    .btn.btn-secondary {
        width: 100%;
        padding: 10px;
    }

    .modal#loginPromoteModal {
        align-items: center;
        bottom: 0;
        display: flex;
        height: 100%;
        justify-content: center;
        left: 0;
        opacity: 1;
        position: fixed;
        right: 0;
        top: 0;
        transition: 0.3s ease opacity;
        width: 100%;
        z-index: 20;
        pointer-events: auto;
        background-color: rgba(0, 0, 0, 0.512);

        .modal-dialog {
            max-width: 350px;
            box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.25);
            width: auto;

            .modal-content {
                height: 100%;
                padding: 20px;
            }
        }
    }

    position: relative;

    .promoteModalTitle {
        font-family: Roboto;
        font-size: 22px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: 20px;
    }

    .promoteModalText {
        font-family: Roboto;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;
    }

    .login-overlay__close {
        position: absolute;
        right: 20px;
        top: 10px;
        cursor: pointer;
    }

    // Login Overlay
    .login-overlay {
        z-index: 20;
    }

    .login-overlay__success {
        display: none;
    }

    .login-overlay__inner {
        width: 100%;
        &.success {
            color: unset;
        }
    }

    .login__wrapper {
        margin-bottom: 25px;
    }

    .login-overlay__account {
        display: flex;
        flex-flow: column;
    }

    .login-overlay__account-link,
    .login-overlay__subtitle,
    .login-overlay__account-description {
        display: block;
    }

    .login-overlay__subtitle {
        margin-bottom: 10px;
        font-size: 12px;
    }

    .login-overlay__account-link {
        font-size: 12px;
        text-decoration: underline;
        margin-top: 5px;
        cursor: pointer;
    }

    .login-overlay__title {
        font: 700 20px Roboto, Arial, serif;
        font-size: 22px;
    }
}
