$wishlist-cta-color:                $button-primary-background !default;
$wishlist-cta-color-active:         $button-primary-active-background !default;
$wishlist-cta-color-disabled:       $button-disabled-background !default;
$wishlist-cta-border:               1px solid $wishlist-cta-color  !default;
$wishlist-cta-border-radius:        3px !default;
$wishlist-cta-container-size:       42px !default;
$wishlist-cta-font-size:            24px !default;
$wishlist-cta-added-animation:      pulse .7s normal linear forwards !default;

.product__addtowishlist {
    position: absolute;
    left: 15px;
    top: 15px;

    .add-to-wishlist {
        @include transition();
        color: $wishlist-cta-color;
        display: inline-block;
        font-size: $wishlist-cta-font-size;
        height: $wishlist-cta-font-size;
        line-height: $wishlist-cta-font-size - 1px;
        position: relative;
        text-decoration: none;

        @if (global-variable-exists(wishlist-cta-use-border) and $wishlist-cta-use-border) {
            .product-main-content & {
                border: $wishlist-cta-border;
                border-radius: $wishlist-cta-border-radius;
                display: inline-block;
                height: $wishlist-cta-container-size;
                line-height: $wishlist-cta-container-size - 1px;
                position: relative;
                text-align: center;
                width: $wishlist-cta-container-size;

                .icon--heart,
                .icon--heart-slim,
                .icon--heart-filled {
                    left: 50%;
                    position: absolute;
                    top: 52%;
                    transform: translate(-50%, -50%);
                }
            }
        }

        &[disabled] {
            &,
            &:hover,
            &:active,
            &:focus {
                border-color: $wishlist-cta-color-disabled;
                color: $wishlist-cta-color-disabled;
                cursor: not-allowed;
            }
        }

        &:not([disabled]) {
            &:hover,
            &:active,
            &:focus {
                border-color: $wishlist-cta-color-active;
                color: $wishlist-cta-color-active;
            }
        }

        .icon--heart,
        .icon--heart-slim,
        .icon--heart-filled {
            display: inline-block;
        }

        .icon--heart-filled {
            display: none;
        }

        &.is-in-wishlist {
            .icon--heart,
            .icon--heart-slim {
                display: none;
            }

            .icon--heart-filled {
                display: inline-block;
            }
        }

        &.added-to-wishlist {
            @include icon(heart-filled, after, inherit);

            &::after {
                animation: $wishlist-cta-added-animation;
                color: $wishlist-cta-color;
                left: 50%;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}
