@import '~plugin_frontend_core/utils/mixins/fonts'; // JVP @TODO: We've got to find a simpler way to import the fonts, variables and mixins
@import '~plugin_frontend_core/variables';
@import '~plugin_frontend_core/mixins';

select,
select.form-control,
.custom-select,
.select-style {
    @include select;

    margin-right: 20px; // should match background position to avoid overlap of icon
}

.custom-select-options {
    @extend select;
    background: $white;
    height: auto;
    max-height: 50vh;
    overflow: auto;
    padding: 0;
    position: absolute;
    width: auto;
    z-index: 1;
    @include media-breakpoint-down(sm) {
        width: 100%;
    }

    &__option {
        @include font-type(bold);
        line-height: 24px;
        white-space: nowrap;
        z-index: 1;
        padding: 0 24px 0 18px;
        &:hover {
            background: $styleguide-grey-400;
        }

        span {
            display: inline-block;
        }

        .select-custom-label__date {
            min-width: 160px;
        }

        @include media-breakpoint-down(sm) {
            white-space: normal;
        }
    }
}

// Added below to over-ride specificity of bootstrap.
// Tried to do so with a scss variable but no luck. :(
// Added defaulted variables above that can be over-ridden in _variables.scss
select.select-custom-normalize,
select.form-control {
    &:not([size]):not([multiple]) {
        $select-border-width: ($border-width * 2);
        height: $formfield-height;
    }

    &:focus::-ms-value {
        background-color: $formfield-background-color;
        color: $input-color;
    }
}

.select-custom-label {
    &__select {
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
    }

    &__label {
        @include font-type(bold);
        line-height: $formfield-height;
        white-space: nowrap;
        z-index: 1;
    }

    &__time {
        color: $styleguide-green;
    }

    &__price {
        @include font-type(small);
    }
}

// My Account redesign select dropdowns
.select-box,
.dropdown-toggle {
    position: relative;

    &:focus-within {
        &::after {
            top: 18px;
            transform: rotate(-135deg);
        }
    }

    &::after {
        border: 0;
        border-bottom: 2px solid $styleguide-grey-900;
        border-right: 2px solid $styleguide-grey-900;
        content: '';
        display: block;
        height: 7px;
        position: absolute;
        right: 10px;
        top: 15px;
        transform: rotate(45deg);
        transform-origin: center;
        transition: transform .3s ease, top .3s ease;
        width: 7px;
        z-index: 0;
    }
}

.select-box {
    background: $styleguide-white;
    border-radius: 3px;

    &.dark {
        background-color: $styleguide-grey-300;

        &::after {
            right: 15px;
        }
    }

    select {
        background: transparent;
        padding-right: 30px;
        position: relative;
        z-index: 1;

        &:focus {
            background-color: transparent;
        }
    }
}

// Separate styling for required dropdowns that have validation applied, as the select box can't have a wrapper in such cases
.form-group {
    &.select-box {
        background-color: transparent;

        &::after {
            z-index: 1;
        }


        select {
            background-color: $styleguide-white;

            &.is-invalid {
                border: none;

                &:focus {
                    box-shadow: 0 0 5px rgba($styleguide-black, .2);
                }
            }
        }
    }
}
