// $event-calendar-header: loyalty-banner-account !default;

.store-events {
    background-color: $styleguide-grey-100;
}

.event-modal-backdrop {
    background-color: $styleguide-black;
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    transition: opacity 0.2s ease-in;
    width: 100%;
    z-index: 888;

    &.show {
        opacity: 0.5;
    }
}

.event-modal {
    background-color: $styleguide-white;
    display: flex;
    left: 10px;
    min-height: 100px;
    position: absolute;
    transition: opacity 0.2s ease-in;
    width: calc(100% - 20px);
    z-index: 999;
    opacity: 0;

    &.show {
        opacity: 1;
    }


    @include media-breakpoint-up(md) {
        bottom: -35px;
        left: auto;
        right: -40px;
        transform: translate(100%, 0%);
        width: 540px;
    }

    @include media-breakpoint-up(lg) {
        width: 650px;
        bottom: -25px;
    }

    @include media-breakpoint-up(xl) {
        width: 720px;
    }

    .event-description {
        flex-grow: 2;
        text-align: left;
        padding: 15px;

        h3 {
            @include font-type(beautifulH1);
        }
    }

    .event-image {
        display: none;
        margin: 0 0 0 auto;

        @include media-breakpoint-up(md) {
            align-items: center;
            display: flex;
            flex-grow: 1;
            max-width: 40%;
        }
    }

    @include media-breakpoint-up(md) {
        &::before {
            border-width: 40px;
            border-style: solid;
            border-color: transparent $styleguide-white transparent transparent;
            bottom: 20px;
            content: "";
            height: 0;
            left: -80px;
            position: absolute;
            width: 0;
        }
    }

    @include icon(close, after, 16px);

    &::after {
        cursor: pointer;
        height: 25px;
        position: absolute;
        right: 10px;
        top: 10px;
        width: 25px;
    }

    .hover & {
        &::after {
            display: none;
        }
    }

}

.events-calendar {
    margin: 10px 0 10px 0;
    position: relative;

    .event-calendar-controls {
        position: absolute;
        right: 10px;
        top: 0;

        button {
            background-color: transparent;
            border: none;
            cursor: pointer;
            transition: opacity 0.3s ease;

            &:hover {
                opacity: 0.6;
            }
        }

        .btn-prev {
            @include icon(arrow-left-light, after, 22px);
        }

        .btn-next {
            @include icon(arrow-right-light, after, 22px);
        }
    }

    table,
    tr,
    td {
        border: none;
    }

    table {
        width: 100%;
    }

    td {
        padding: 0;
        text-align: center;
    }

    thead {
        td {
            padding: 5px;
            text-transform: uppercase;
        }
    }

    tbody {
        td {
            border: 1px solid $styleguide-grey-100;
            background-color: $styleguide-white;

            &.other-month {
                background-color: transparent;

                .day {
                    display: none;
                }
            }
        }

        .day {
            align-items: center;
            display: flex;
            justify-content: center;
            padding: 5px;

            @include media-breakpoint-up(lg) {
                padding: 10px 5px;
            }

            .day-nr {
                border-radius: 100%;
                height: 30px;
                line-height: 30px;
                width: 30px;

                @include media-breakpoint-up(sm) {
                    height: 40px;
                    line-height: 40px;
                    width: 40px;
                }

                @include media-breakpoint-up(lg) {
                    height: 50px;
                    line-height: 50px;
                    width: 50px;
                }
            }

            &.has-event {
                cursor: pointer;
                z-index: 99999;

                @include media-breakpoint-up(md) {
                    position: relative;
                }

                .day-nr {
                    background-color: $styleguide-anthracite-dark;
                    color: $styleguide-white;
                }
            }
        }
    }

    h2 {
        font-weight: normal;
        font-size: 28px;
        margin-bottom: 10px;
    }

    .wrong-month {
        display: none;
    }
}

.events-list {
    h2 {
        @include font-type(beautifulH1);
        margin-bottom: 15px;
    }

    ul {
        @include list-reset;
    }

    li {
        margin: 0 0 8px 0;
    }

    a {
        font-size: 20px;
        text-decoration: none;
    }
}
