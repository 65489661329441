[type="date"],
[type="email"],
[type="number"],
[type="password"],
[type="search"],
[type="tel"],
[type="text"],
textarea {
	&,
	&.form-control {
		@include inputStyling;
		height: $formfield-height;
		line-height: $formfield-height;
		width: 100%;

		&.inline {
			width: auto;
		}

		&[class*="error"] {
			border-color: $error-color;
		}
	}
}

[type="number"] {
	width: auto;
}

textarea {
	&,
	&.form-control {
		height: auto;
		line-height: inherit;
		min-height: 6.6rem;
		resize: none;
		padding-top: 6px;
	}
}

/* clears the 'X' from Internet Explorer */
input[type="search"]::-ms-clear {  display: none; width : 0; height: 0; }
input[type="search"]::-ms-reveal {  display: none; width : 0; height: 0; }

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }
