/// Made into mixin so that resulting file can be imported without generating code
@mixin icon-font-face() {
  @font-face {
    font-family: "icon-font";
    font-display: swap;
    src: url("~fontsDirectory/fonts/icon-font.eot?b6eb146d6e7276070b58266ec04fd317?#iefix") format("embedded-opentype"),
url("~fontsDirectory/fonts/icon-font.woff2?b6eb146d6e7276070b58266ec04fd317") format("woff2"),
url("~fontsDirectory/fonts/icon-font.woff?b6eb146d6e7276070b58266ec04fd317") format("woff"),
url("~fontsDirectory/fonts/icon-font.ttf?b6eb146d6e7276070b58266ec04fd317") format("truetype");
  }
}

@mixin char($character-code) {
    // Escaping in Sass is a bit of a mess…
    // https://github.com/sass/sass/issues/659
    @if function-exists(random) {
        @if function-exists(selector-nest) {
            // Sass 3.4
            content: #{'\"\\' + $character-code + "\""};
        } @else {
            // LibSass
            // Escaping:
            // https://github.com/sass/libsass/issues/1271
            //
            // Outputs a unicode entity instead of a code such as "\f102":
            // https://github.com/sass/libsass/issues/1231
            content: "#{'\"\\' + $character-code + "\""}";
        }
    } @else {
        // Sass 3.3
        content: str-slice("\x", 1, 1) + $character-code;
    }
}

/* Icons */
$icons: (
    delivery: 'f101',
    dhl-plate: 'f102',
    form: 'f103',
    servicepoint: 'f104',
    store: 'f105',
    truck: 'f106',
    warehouse: 'f107',
    arrow-down: 'f108',
    arrow-left-light: 'f109',
    arrow-left: 'f10a',
    arrow-right-light: 'f10b',
    arrow-right: 'f10c',
    arrow-up: 'f10d',
    chat-balloons-solid: 'f10e',
    chat-balloons: 'f10f',
    chat: 'f110',
    check: 'f111',
    checkbox-active: 'f112',
    checkbox-inactive: 'f113',
    clipboard: 'f114',
    close: 'f115',
    collapse-down: 'f116',
    collapse-up: 'f117',
    cross: 'f118',
    customerservice: 'f119',
    email: 'f11a',
    hamburger-menu: 'f11b',
    heart-filled: 'f11c',
    heart-slim: 'f11d',
    heart: 'f11e',
    link: 'f11f',
    location: 'f120',
    mail: 'f121',
    minus: 'f122',
    afterpay: 'f123',
    bancontact: 'f124',
    cbc: 'f125',
    express: 'f126',
    fashioncheque: 'f127',
    homepay: 'f128',
    ideal: 'f129',
    kbc: 'f12a',
    maestro-alt: 'f12b',
    maestro: 'f12c',
    mastercard-alt: 'f12d',
    mastercard: 'f12e',
    paypal: 'f12f',
    sofort: 'f130',
    visa: 'f131',
    vvv-giftcard: 'f132',
    phone: 'f133',
    plus: 'f134',
    search: 'f135',
    dhl: 'f136',
    shopping-cart-add: 'f137',
    shopping-cart: 'f138',
    social: 'f139',
    facebook-messenger: 'f13a',
    facebook: 'f13b',
    google-plus: 'f13c',
    instagram: 'f13d',
    pinterest: 'f13e',
    snapchat: 'f13f',
    twitter: 'f140',
    vimeo: 'f141',
    whatsapp: 'f142',
    youtube: 'f143',
    star: 'f144',
    trash: 'f145',
    user: 'f146',
    wishlist: 'f147',
    zoom-in: 'f148',
);

.icon {
    display: inline-block;
    font-family: 'icon-font';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    speak: none;
    text-decoration: inherit;
    text-transform: none;
    text-rendering: auto;
    transition: .2s linear color;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@each $name, $value in $icons {
    @if $value {
        .icon--#{$name}::before {
            @include char($value);
        }
    } @else {
        @warn 'icon ' + $name + ' does not exist';
    }
}

@mixin icon($name, $pos: 'before', $size: null) {
    $value: map-get($icons, $name);

    @if $value {
        &::#{$pos} {
            @include char($value);
            font-family: 'icon-font';
            font-weight: normal;

            @if $size {
                font-size: $size;
            }
        }
    } @else {
        @warn '@mixin icon: ' + $name + ' does not exist'
    }
}
