$input-radio-size-after:	(#{$formfield-radio-size / 2 - 1px}) !default;
$input-radio-bullet-offset:	calc((#{$formfield-radio-size} - #{$input-radio-size-after}) / 2) !default;

// base radio styling
[type="radio"],
.icon-radio {
	cursor: pointer;
}

.input-radio {
	display: inline-block;
	position: relative;
	visibility: hidden;
	height: $formfield-radio-size;
	width: $formfield-radio-size;

	&__container {
		display: flex;
    }

    &:checked {
        + .input-radio__label {
            &::after {
                background-color: $button-primary-background;
                animation: bouncybouncy .3s;
            }
        }
        &.radio-secondary {
            + .input-radio__label {
                &::after {
                    background-color: $styleguide-grey-800;
                }
            }
        }
        &.radio-tertiary {
            + .input-radio__label {
                &::before {
                    background-color: $color-arsenic;
                }

                &::after {
                    animation: bouncybouncyRotated .3s;
                    opacity: 1;
                }
            }
        }
    }

    &.radio-secondary {
        + .input-radio__label {
            &::before {
                border: 1px solid $styleguide-grey-600;
            }
        }
    }

    // My Account redesign, looks like checkbox but behaves like radio button
    &.radio-tertiary {
        display: none;

        + .input-radio__label {
            display: flex;
            padding: 0;

            &::before {
                border: 1px solid $color-arsenic;
                border-radius: 3px;
                flex: 0 0 auto;
                height: 20px;
                margin-right: 8px;
                position: static;
                width: 20px;
            }

            &::after {
                background-color: transparent;
                border-bottom: 2px solid $styleguide-white;
                border-left: 2px solid $styleguide-white;
                border-radius: 0;
                height: 5px;
                left: 6px;
                opacity: 0;
                top: 7px;
                transform: rotate(-45deg);
                transition: opacity .3s ease;
                width: 9px;
            }
        }

        &.rounded {
            + .input-radio__label {
                &::before {
                    border-radius: 50%;
                }
            }
        }
    }
}

.input-radio__container {
    display: inline-flex;
}

.input-radio__label {
    cursor: pointer;
    margin: 0 15px 0 0;
    position: relative;
    padding: 0 0 0 5px;

    &::after,
    &::before {
        visibility: visible;
        @include transition;
        border-radius: 50%;
        content: '';
        position: absolute;
    }

    &::before {
        background: $formfield-background-color;
        border: 2px solid $button-primary-background;
        height: $formfield-radio-size;
        width: $formfield-radio-size;
        left: -18px;
        top: 0;
    }

    &::after {
        background-color: $formfield-background-color;
        height: $input-radio-size-after;
        width: $input-radio-size-after;
        left: calc(-18px + #{$input-radio-bullet-offset});
        top: $input-radio-bullet-offset;
        transform-origin: center;
    }
}

@keyframes bouncybouncyRotated {
    0% {
        transform: scale(0) rotate(-45deg);
    }
    50% {
        transform: scale(1.2) rotate(-45deg);
    }
    100% {
        transform: scale(1) rotate(-45deg);
    }
}
