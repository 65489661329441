// Component variables
$nav-link-color:       $primary-brand-color !default;
$nav-text-color:       $text-color-primary !default;

.nav-tabs { 
    @include list-reset;

    .nav-link {
        color: $nav-text-color;
        display: block;
        padding: 0px 15px ($gutter / 2);
        position: relative;
        text-decoration: none;

        &::after {
            @include transition;
            background: $nav-link-color;
            bottom: 0;
            content: '';
            display: block;
            height: 3px;
            left: 0;
            position: absolute;
            right: 100%;
            margin-bottom: -2px;
        }

        &.active::after {
            color: $nav-link-color;
            right: 0;
        }
    }
}

.tab-content {
    >.tab-pane {
        display: none;

        &.active {
            display: block;
        }
    }
}
