.site-header {
    .quicklinks__link {
        align-items: center;
        display: flex;
        line-height: 24px;

        .quicklinks__icon {
            &.user-loggedin-icon {
                width: 20px;
                height: 20px;
            }
            order: 2;
            font-size: 20px;

            &.icon--customerservice {
                &.open {
                    color: $styleguide-green;
                }
            }
        }

        .link__label {
            display: none;
            margin: 0 10px 0 0;
            order: 1;

            @include media-breakpoint-up(md) {
                display: inline-block;
            }
        }
    }

    .quicklinks__menu {
        margin: 0 0 0 0;
    }
}
