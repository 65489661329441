@mixin inputStyling(
	$input-background-color: $formfield-background-color,
	$input-text-color: $formfield-text-color,
	$input-placeholder-color: $formfield-placeholder-color,
	$input-error-color: $formfield-error-color,
	$input-border-radius: $formfield-border-radius,
	$input-border-color: $formfield-border-color,
	$input-border-active-color: $formfield-border-active-color,
	$input-disabled-background: $formfield-disabled-background,
	$input-disabled-placeholder: $formfield-disabled-placeholder,
	$input-disabled-border-color: $formfield-disabled-border-color
) {
	@include transition;
	@include font-type(input);
	-webkit-appearance: none;
	background-color: $input-background-color;
	border: 1px solid $input-border-color;
	border-radius: $input-border-radius;
	box-shadow: none;
	box-sizing: border-box;
	color: $input-text-color;
	outline: none;
	padding: 0 18px;

	&:active,
	&:focus,
	&:hover {
		border-color: $input-border-active-color;
		box-shadow: none;
		outline: none;
	}

	&::placeholder {
		color: $input-placeholder-color;
	}

	.error &,
	&.is-error,
	&.is-invalid,
	&.error {
		border-color: $input-error-color;
		color: $input-error-color;
		transition: none;
		&::placeholder {
			color: $input-error-color;
		}
	}

	+ .invalid-feedback {
		color: $input-error-color;
	}

	&.disabled,
	[disabled] {
		background-color: $input-disabled-background;
		border-color: $input-disabled-border-color;

		&::placeholder {
			color: $input-disabled-placeholder;
		}
	}
}
