$pdp-promo-badge-color-bg: $primary-brand-color !default;
$pdp-promo-badge-color-text: $error-color !default;
$pdp-promo-badge-font: bold !default;
$pdp-promo-badge-posx: 25px !default;
$pdp-promo-badge-posy: 25px !default;
$pdp-promo-badge-paddingB: 5px !default;

.product-detail__promotions {
    .callout,
    .promotion_callout {
        @include font-type($pdp-promo-badge-font);
        color: $pdp-promo-badge-color-text;
        padding-bottom: $pdp-promo-badge-paddingB;
        position: relative;
    }

    &.promotions_tile {
        .promotion_callout {
            @include media-breakpoint-down(sm) {
                text-align: center;
            }
        }
    }
}
