// Component variables
$quicklinks-link-hspace:                          15px !default;

$quicklinks-link-color--mobile:                   $text-color-primary !default;
$quicklinks-link-color--desktop:                  $text-color-primary !default;
$quicklinks-link-color--desktop-alt:              $text-color-primary !default;
$quicklinks-link-icon-color:                      $text-color-primary !default;
$quicklinks-highlight-color:                      $primary-brand-color !default;

$quicklinks-badge-size:                           16px !default;
$quicklinks-badge-background-color:               $primary-brand-color !default;
$quicklinks-badge-text-color:                     $text-color-secondary !default;
$quicklinks-badge-text-size:                      10px !default;

$quicklinksContext: '.quicklinks';

.site-header {
  #{$quicklinksContext} {
    &--desktop {
      display: flex;
      justify-content: flex-end;
      position: absolute;
      right: $gutter / 2;
      top: 50%;
      transform: translateY(-50%);

      @include media-breakpoint-up(lg) {
        margin-right: -#{$quicklinks-link-hspace / 2};

        #{$quicklinksContext} {
          &__item {
            &#{$quicklinksContext}__search {
              padding-right: $quicklinks-link-hspace;
            }
          }
        }
      }
    }

    &--mobile {
      #{$quicklinksContext} {
        &__item {
          padding: 0;
          margin-right: 30px;
        }

        &__link {
          &:hover {
            background-color: $styleguide-transparent;
            text-decoration: none;
          }

          .link {
            &__label {
              color: $quicklinks-link-icon-color;
              display: inline-block;
              padding: 0 0 0 10px;
            }
          }
        }

        &__icon {
          color: $quicklinks-link-icon-color;

          &.icon--highlight {
            color: $quicklinks-highlight-color;
          }
        }
      }
    }

    &__link {
      @include font-type(default);
      color: $quicklinks-link-color--mobile;
      line-height: initial;
      margin: 0;
      position: relative;
      text-decoration: none;

      @include media-breakpoint-up(lg) {
        color: $quicklinks-link-color--desktop;
      }

      #{$quicklinksContext} {
        &__icon {
          color: $quicklinks-link-icon-color;

          &.icon--highlight {
            color: $quicklinks-highlight-color;
          }
        }
      }

      .link {
        &__label {
          display: none;

          @include media-breakpoint-up(lg) {
            color: $quicklinks-link-color--desktop-alt;
            margin: 0 0 0 10px;
          }
        }
      }
    }

    &__icon {
      cursor: pointer;
      font-size: 18px;

      &.icon--highlight {
        color: $quicklinks-highlight-color;
      }
    }

    &__badge {
      background: $quicklinks-badge-background-color;
      border-radius: 100%;
      bottom: 100%;
      color: $quicklinks-badge-text-color;
      font-size: $quicklinks-badge-text-size;
      height: $quicklinks-badge-size;
      left: 100%;
      line-height: $quicklinks-badge-size;
      margin: -#{$quicklinks-badge-size / 2};
      position: absolute;
      text-align: center;
      width: $quicklinks-badge-size;
    }

    &--nav {
      height: 100%;
    }

    &__item {
      cursor: pointer;
      padding: 0 ($quicklinks-link-hspace / 2);
    }

    &__wishlist {
      #{$quicklinksContext}__link {

        &.added-to-wishlist {
          @include icon(heart-filled, after, inherit);

          &::after {
              animation: pulse .7s normal linear forwards;
              color: inherit;
              left: 50%;
              position: absolute;
              top: 50%;
              transform: translate(-50%, -50%);
          }
        }
      }
    }

    &__menu {
      box-sizing: content-box;
      flex-basis: 18px;
      flex-grow: 0;
      flex-shrink: 0;
      margin-right: -10px;

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  }

  .header-primary {
    &.active {
      #{$quicklinksContext}--mobile {
        #{$quicklinksContext} {
          &__item {
            display: flex;
            padding-bottom: 10px;
          }
        }
      }
    }
  }
}

