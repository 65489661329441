$product-carousel-preview-next-slide: true;
$product-carousel-preview-space: 20%;
$product-carousel-vertical-offset-top: 70px;
$product-carousel-vertical-offset-bottom: 200px;
$product-quickview-variation-dimensions: 30px;

.product-carousel {
    &__container {
        position: relative;
        z-index: 1; // Necessary to have the carousel content be placed on top of .site-footer

        &.hover {
            z-index: 2;
            > .slick-list {
                // The top/bottom paddings and negative margins are there to make sure the quickview is
                // actually visible due to slick slider having `overflow: hidden;`
                margin-bottom: -#{$product-carousel-vertical-offset-bottom};
                margin-top: -#{$product-carousel-vertical-offset-top};
                padding-bottom: $product-carousel-vertical-offset-bottom;
                padding-top: $product-carousel-vertical-offset-top;
            }

            > .slick-arrow {
                margin-top: $product-carousel-vertical-offset-top / 2;
            }
        }

        > .slick-list {
            @if ($product-carousel-preview-next-slide == true ) {
                padding-left: $product-carousel-preview-space;
                padding-right: $product-carousel-preview-space;

                @include media-breakpoint-up(md) {
                    padding-left: 0;
                    padding-right: 0;
                }
            }

            .slick-arrow {
                margin-top: 0;
            }
        }

        .slick-slide {
            position: relative;
            
            .product-quickview.product-tile {
                width: 100%;
            }
        }

        .sick-dots {
            position: relative;
            z-index: 1;
        }
    }

    &__item {
        &:first-child {
            border-left: 1px solid transparent; // required in order for slick to work properly
        }
        &:not(:first-child) {
            border-left: $border-light;
        }
    }
}
