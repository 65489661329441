$header-box-shadow:                               0 2px 4px 0 rgba($styleguide-grey-800, .1) !default;

$primary-header-height-mobile:                    50px !default;
$primary-header-height-desktop:                   50px !default;
$primary-header-logo-height-mobile:               $primary-header-height-mobile - 20px !default;
$primary-header-logo-height-desktop:              $primary-header-height-desktop - 20px !default;
$primary-header-logo-max-width:                   calc(100vw - #{$gutter} - 160px) !default; // 160px = roughly the width of 4 icons in the header
$primary-header-logo-min-width:                   calc(#{$site-min-width} - #{$gutter} - 145px) !default; // 140px = based on 160px but with some extra space
$primary-header-background-color-mobile:          $styleguide-white !default;
$primary-header-background-color-desktop:         $styleguide-white !default;
$primary-header-background-text-color-mobile:     $styleguide-grey-900 !default;
$primary-header-background-text-color-desktop:    $styleguide-grey-900 !default;

$secondary-header-height-desktop:                 50px !default;
$secondary-header-background-color-mobile:        $styleguide-white !default;
$secondary-header-background-color-desktop:       $styleguide-white !default;
$secondary-header-box-shadow:                     0 1px 0 0 $styleguide-grey-300 inset !default;

$tertiary-header-height-mobile:                   20px !default;
$tertiary-header-height-desktop:                  20px !default;
$tertiary-header-background-color-mobile:         $primary-brand-color !default;
$tertiary-header-background-color-desktop:        $primary-brand-color !default;
