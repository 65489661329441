// Component variables
$search-icon-color--mobile:                       $text-color-primary !default;
$search-icon-color--desktop:                      $styleguide-black !default;
$search-field-width-active:                       300px !default;

$search-suggestions-width-products:               300px !default;
$search-suggestions-width-content:                300px !default;
$search-suggestions-width-all:                    $search-suggestions-width-products + $search-suggestions-width-content !default;

$search-suggestions-background-color:             $styleguide-white !default;
$search-suggestions-background-color-desktop:     $styleguide-white !default;
$search-suggestions-box-shadow:                   $header-box-shadow !default;
$search-suggestions-borders:                      $border-medium !default;
$search-suggestions-close-button-color:           $text-color-primary !default;

$suggestion-item-name-color:                      $styleguide-black !default;
$suggestion-show-more-label-color:                $styleguide-black !default;


@import './search/_search-suggestions';
@import './search/_search-bar';
