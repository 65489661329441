$refinement-navigation-slider-base-color:   $text-color-primary !default;
$refinement-navigation-slider-handle-color: $styleguide-grey-800 !default;
$refinement-slider-handle-size:             10px !default;

.price-slider {
    position: relative;

    .noUi-base {
        height: 21px;
        padding: 10px 0;
        position: relative;
        width: 100%;

        &::before {
            background-color: $refinement-navigation-slider-base-color;
            content: '';
            display: block;
            height: 1px;
            width: 100%;
        }
    }

    .noUi-origin {
        height: 1px;
        outline: 0;
        position: absolute;
        top: 10px;
        width: 1px;

        &:first-child {
            transform: translateX(4px);
        }

        &:last-child {
            transform: translateX(-5px);
        }
    }

    .noUi-handle {
        @include transition(opacity, .3s);

        cursor: pointer;
        left: 0;
        opacity: 1;
        outline: 0;
        padding: 10px;
        position: absolute;
        top: 0;
        transform: translate(-50%, -50%);

        &::before {
            background-color: $refinement-navigation-slider-handle-color;
            content: '';
            display: block;
            height: $refinement-slider-handle-size;
            width: $refinement-slider-handle-size;
        }
    }
}
