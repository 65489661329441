$img-lazyload-img:					$loader-img !default;

img {
	@include resp-img($max-width: true);
	border: none;
	
	&.lazyload,
	&.lazyloading {
		background: url($img-lazyload-img) center center no-repeat;
		min-height: 10px;
		min-width: 32px;
	}
}

// Image-container
// Useful for displaying multiple portrait/landscape images inside the same canvas dimensions.
// Usage: <span class='img-container'><img src='[..]' alt=' /></span>
.img-container {
	display: block;
	overflow: hidden;
	position: relative;
	text-align: center;

	&::before {
		content: '';
		display: block;
		height: 0;
		padding-bottom: 100%;
	}

	&.loading {
		background: url($img-lazyload-img) center center no-repeat;

		img,
		source {
			opacity: 0;
		}
	}

	.placeholder-svg,
	img,
	source {
		// scss-lint:disable VendorPrefix
		-webkit-backface-visibility: hidden; // To fix a Chrome layout bug when using transition
		// scss-lint:enable VendorPrefix
		display: inline-block;
		height: auto;
		left: 50%;
		max-height: 100%;
		max-width: 100%;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		width: auto;
	}
}
