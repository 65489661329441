$form-feedback-color:                           $styleguide-red-300 !default;
$form-control-background-color:                 $styleguide-white !default;
$form-control-placeholder-color:                $styleguide-white !default;
$form-control-disabled-color:                   $styleguide-grey-400 !default;
$form-valid-color:                              $styleguide-green !default;
$form-focus-color:                              $styleguide-orange !default;

.form-group {
    &.form-group-labeled {
        position: relative;

        .form-control {
            background-position: calc(100% - 10px) center;
            background-repeat: no-repeat;

            &.disabled {
                background-color: $form-control-background-color !important;

                + .form-control-label {
                    color: $form-control-disabled-color;
                }
            }

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus {
                -webkit-box-shadow: 0 0 0px 1000px $form-control-background-color inset;
                @include transition(background-color, 5000s, ease-in-out);

                &:placeholder-shown {
                    + .form-control-label {
                        visibility: hidden;
                    }
                }
            }

            &:not(:placeholder-shown),
            &:focus:not(.disabled) {
                + .form-control-label {
                    background-color: $form-control-background-color;
                    font-size: 10px;
                    left: 18px;
                    padding: 1px 12px 1px 6px;
                    top: -8px;
                }
            }

            &:valid:not(:placeholder-shown):not(.validation-error) {
                background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAABfSURBVDhPYxgFpAPb+bbszgvc++zm2/FDhYgHIM1OC9y3AQ145zrfVR8qTBwY5Jrt57upOy9wu4eugGibsSkk2dnIGlzme5iTpBkGkAz5T7JmGAAZAtS8jCzNAwAYGACR9U5edM2P8QAAAABJRU5ErkJggg==') !important;
                color: $formfield-text-color;

                &:not(:focus) {
                    border-color: $form-valid-color;

                    + .form-control-label {
                        color: $form-valid-color;
                    }
                }
            }

            &:invalid:not(:focus):not(:placeholder-shown),
            &.error:not(:valid):not(:focus),
            &.validation-error:not(:focus):not(.disabled) {
                border-color: $form-feedback-color;
                color: $form-feedback-color;

                + .form-control-label {
                    color: $form-feedback-color;
                }
            }

            &:focus:not(.disabled) {
                border-color: $form-focus-color;

                + .form-control-label {
                    color: $form-focus-color;
                }
            }

        }

        .form-control-label {
            @include transition(all, .3s);
            background-color: rgba(255, 255, 255, 0);
            display: block;
            left: 13px;
            padding: 1px 5px;
            position: absolute;
            top: 10px;
        }

        .extra {
            font-size: 10px;
            left: 18px;
            margin-top: 2px;
            position: relative;
        }
    }
}
