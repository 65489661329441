// JVP @TODO: Removed !default because it wasn't working. Investigate why and fix it with !default
// Overwrite existing Bootstrap variables
$site-min-width:            320px !default;
$site-max-width:            1440px !default;

$grid-gutter-width-mobile:  15px;
$grid-gutter-width-desktop: 30px;
$grid-gutter-widths: (
  xs: $grid-gutter-width-mobile,
  sm: $grid-gutter-width-mobile,
  md: $grid-gutter-width-desktop,
  lg: $grid-gutter-width-desktop,
  xl: $grid-gutter-width-desktop
);
$gutter:                    $grid-gutter-width-desktop;
$gutter-small:              $grid-gutter-width-mobile;
$gutter-large:              $grid-gutter-width-desktop;

$grid-gutter-width:         $gutter;

$grid-columns:              12;

$grid-breakpoints: (
  xs: 0px,
  sm: 520px,
  md: 720px,
  lg: 992px,
  xl: 1200px
);

$order-range:               $grid-columns;
