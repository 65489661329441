@mixin list-heading-styling {
	@include icon(arrow-up, 'after', .6em);
	cursor: pointer;
	padding-right: 30px;
	position: relative;

	&::after {
		display: block;
		position: absolute;
		right: 2px;
		top: 40%;
		transform: translateY(-72.5%);
	}
	
	&.collapsed {
		@include icon(arrow-down, 'after', .6em);
		
	}
}

.list-links {
	&[data-collapse="xs"] {
		@include media-breakpoint-only(xs) {
			.list-links__heading {
				@include list-heading-styling;
			}
		}
	}
	
	&[data-collapse="xs-sm"] {
		@include media-breakpoint-down(sm) {
			.list-links__heading {
				@include list-heading-styling;
			}
		}
	}
	
	.list-links__list {
	    @include list-reset;
	}
	
	a:not([class]),
	.list-links__anchor {
		display: block;
		max-width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}
