$button-arrow-name-prev: arrow-left !default;
$button-arrow-name-next: arrow-right !default;
$button-arrow-size: 32px !default;
$button-arrow-icon-size: 12px !default;
$button-arrow-border-size: 1px !default;

@mixin generateButtons ($buttons: $buttons){
    @each $button, $style in $buttons {
        .btn-#{$button} {
            @each $key, $val in $style {
                #{$key} : $val;
            }
        }
    }
}

@mixin arrowButtonBase {
    background: $background-color;
    border: $button-arrow-border-size solid $border-color;
    border-radius: 100%;
    cursor: pointer;
    display: block;
    height: $button-arrow-size;
    outline: none;
    overflow: hidden;
    position: absolute;
    text-indent: -999px;
    top: 50%;
    transform: translateY(-50%);
    width: $button-arrow-size;
    z-index: 10;

    &::after {
        left: 50%;
        margin-top: $button-arrow-border-size;
        position: absolute;
        text-indent: 0;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

@mixin arrowButtonLeft {
    @include arrowButtonBase();
    @include icon($button-arrow-name-prev, after, $button-arrow-icon-size);
    left: $gutter / 2;
}

@mixin arrowButtonRight {
    @include arrowButtonBase();
    @include icon($button-arrow-name-next, after, $button-arrow-icon-size);
    right: $gutter / 2;
}
