.header-tertiary {
    @include transition;
    background: $tertiary-header-background-color-mobile;
    height: $tertiary-header-height-mobile;
    position: relative;
    z-index: 1;

    @include media-breakpoint-up(lg) {
        background: $tertiary-header-background-color-desktop;
        height: $tertiary-header-height-desktop;
    }
}
