
.site-header {
    .navbar {
        @include media-breakpoint-up(lg) {
            margin-left: -5px;
        }
        @include media-breakpoint-up(xl) {
            margin-left: -20px;
        }
    }

    @include media-breakpoint-up(lg) {
        .navbar-flyout {
            .nav-level-1-link {
                line-height: 25px;
    
                &::after{
                    top: auto;
                    bottom: -1px;
                }
            }

            .nav-level-1-item {
                .nav-level-2{
                    padding: 30px;
                }
            }
        }
    }
 
    .menu-toggleable-right {
        @include media-breakpoint-down(lg) {
            top: 134px;
        }
    }
}