.quickrefinement {
    overflow: auto;

    &:not(.slick-initialized) {
        visibility: hidden;
    }

    .slick-slide {
        margin: 0 5px;
    }

    .slick-arrow {
        border: none;
        margin-top: -4px;
    }

    .slick-prev {
        left: 0;
    }

    .slick-next {
        right: 0;
    }

    .refinements-navigation__list-item.selected {
        border-color: $primary-brand-color;
    } 
}

.quickrefinements {
    .refinements-navigation__list-item {
        width: 60px;
    }
}
