.header-secondary {
  @include transition;
  background: $secondary-header-background-color-mobile;
  position: relative;
  z-index: 1;
  
  @include media-breakpoint-up(lg) {
    background: $secondary-header-background-color-desktop;
    box-shadow: $secondary-header-box-shadow;
    height: $secondary-header-height-desktop;
  }
}
