$veil-underlay-opacity:             .75 !default;
$veil-underlay-background:          $styleguide-white !default;

$veil-spinner-img:					$loader-img !default;
$veil-spinner-width:				60px !default;
$veil-spinner-height:				60px !default;
$veil-spinner-border-radius:		8px !default;
$veil-spinner-box-shadow:           0 0 8px 0 rgba($styleguide-grey-800, .25) !default;

body > .veil {
    height: 100vh;
    width: 100vw;
}

.veil {
    height: 100%;
    left: 0;
    position: fixed;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 1060;

    + .veil {
        display: none !important;
    }
	
	&.show {
		.underlay {
			opacity: $veil-underlay-opacity;
		}
		
		.spinner {
			opacity: 1;
		}
	}
	
    .underlay {
		@include transition(opacity, .2s);
        background-color: $veil-underlay-background;
        height: 100%;
        left: 0;
        opacity: 0;
        position: fixed;
        top: 0;
        width: 100%;
    }
	
	.spinner {
		@include transition(opacity, .2s);
		background: url($veil-spinner-img) center center no-repeat $styleguide-white;
        border-radius: $veil-spinner-border-radius;
        box-shadow: $veil-spinner-box-shadow;
		height: $veil-spinner-height;
		left: 50%;
		opacity: 0;
		position: fixed;
		top: 50%;
		transform: translate(-50%, -50%);
        animation: none;
        margin: 0;
        width: $veil-spinner-width;
	}
}
