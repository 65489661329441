.inspiration-page {
	.inspiration-page-title {
		h1 {
			font-size: 42px;
		}
	}

	.grid {
		width: 100%;
	}

	.grid-item {
		height: auto;
		max-width: 100%;
		padding: 0 0 14px 0;
		width: 100%;

		@include media-breakpoint-up(sm) {
			max-width: 50%;
			padding: 0 7px 14px 7px;
			width: 50%;
		}
		
		@include media-breakpoint-up(lg) {
			height: 342.5px;
			max-width: 25%;
			width: 25%;
		}

		&:nth-child(4),
		&:nth-child(7) {	
			@include media-breakpoint-up(sm) {
				height: 685px;
			}
		}

		&:nth-child(5),
		&:nth-child(8) {
			@include media-breakpoint-up(lg) {
				max-width: 50%;
				width: 50%;
			}
		}

		img {
			height: 100%;
			object-fit: cover;
			width: 100%;
		}

		&:hover {
			filter: brightness(0.8);
			transition: all .2s ease-in-out;
			transform: scale(1.02);
		}
	}

	.grid-item-large {
		img {
			height: 100%;
			object-fit: cover;
			object-position: left;
			width: 100%;
		}
	}

	.social-icons {
		font-size: 24px;
		margin-top: 25px;
		text-align: center;

		@include media-breakpoint-up(lg) {
			margin-top: 50px;
		}

		a {
			text-decoration: none;

			&:hover {
				color: $styleguide-black;
			}
		}
	}
}