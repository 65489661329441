
.header-primary {

    height: 56px;

    @include media-breakpoint-up(sm) {
        height: 54px;
    }

    .logo {
        left: 0;
        position: relative;
        transform: none;
        top: 0;


        img {
            width: 180px;
            height: auto;
            @include media-breakpoint-up(sm) {
                width: auto;
            }
        }
    }
}