$client-icons: (
  ideal: (
    font-size: 25px
  ),
  afterpay: (
    font-size: 20px
  ),
  visa: (
    font-size: 20px
  ),
  mastercard: (
    font-size: 35px
  ),
  maestro: (
    font-size: 35px
  ),
  paypal: (
    font-size: 20px
  ),
  sofort: (
    font-size: 18px
  ),
  bancontact: (
    font-size: 30px
  ),
  kbc: (
    font-size: 25px
  ),
  cbc: (
    font-size: 25px
  ),
  dhl: (
    font-size: 18px
  ),
  vvv-giftcard: (
    font-size: 40px
  )
);
