
@mixin select(
    $select-background-color: $formfield-select-background-color,
    $select-text-color: $formfield-select-text-color,
    $select-border-color: $formfield-select-border-color,
    $select-border-active-color: $formfield-select-border-active-color,
    $select-placeholder-color: $formfield-select-placeholder-color,
    $select-error-color: $formfield-select-error-color,
    $select-disabled-background: $formfield-select-disabled-background,
    $select-disabled-placeholder: $formfield-select-disabled-placeholder
) {
    @include transition;
    @include font-type(default);
    appearance: none;
    background: $select-background-color url('~staticDirectory/images/icon-chevron-down.png') right 7px center no-repeat;
    border: 1px solid $select-border-color;
    border-radius: $formfield-border-radius;
    color: $select-text-color;
    cursor: pointer;
    height: $formfield-height;
    line-height: $formfield-height;
    outline: none;
    padding: 0 24px 0 18px;
    text-align: left;
    width: 100%;

    &:active,
    &:focus,
    &:hover {
      border-color: $select-border-active-color;
      box-shadow: none; 
      outline: none;
    }

    option[selected] {
      color: $select-placeholder-color;
    }

    &::-ms-expand {
        display: none;
    }

    .error &,
    &.is-error,
    &.is-invalid,
    &.error {
        border-color: $select-error-color;
        color: $select-error-color;

        &::placeholder {
            color: $select-error-color;
        }
    }

    &.disabled,
    [disabled] {
        background-color: $select-disabled-background;
        cursor: not-allowed;
    }
}
