.site-footer {
    .newsletter-input-group {
        .newsletter-email {
            @include media-breakpoint-up(lg) {
                border-bottom-right-radius: 0;
                border-right: none;
                border-top-right-radius: 0;
            }
        }

        .newsletter-submit button {
            width: 100%;

            @include media-breakpoint-up(lg) {
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
                height: 42px;
            }
        }
    }
}
