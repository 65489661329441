$highlight-info-color:              $primary-brand-color !default;
$highlight-success-color:           $success-color !default;
$highlight-warning-color:           $warning-color !default;
$highlight-danger-color:            $error-color !default;

.highlight {
    &-success {
        color: $highlight-success-color;
    }
    
    &-info {
        color: $highlight-info-color;
    }
    
    &-warning {
        color: $highlight-warning-color;
    }
    
    &-danger {
        color: $highlight-danger-color;
    }
}
