$liveagent-button-done-color:                           $success-color !default;
$liveagent-button-background-color:                     $primary-brand-color !default;
$liveagent-text-color:                                  $styleguide-white !default;
$liveagent-button-hover-background-color:               $secondary-brand-color;
$liveagent-text-font:                                   'default' !default;
$liveagent-button-border:                               1px solid $liveagent-button-background-color !default;
$liveagent-form-input-border:                           7px solid $liveagent-text-color !default;
$liveagent-button-focus-box-shadow:                     0 0 2px 0 $liveagent-button-background-color !default;

body {
    .helpButtonEnabled {
        &:focus {
            .helpButtonLabel {
                text-decoration: none;
            }
        }
    }

    .embeddedServiceHelpButton {
        .helpButton {
            bottom: 15px;
            right: 15px;
            width: auto !important;

            .uiButton {
                @include font-type($liveagent-text-font);
                background-color: $liveagent-button-background-color;
                border-radius: 3px;
                color: $liveagent-text-color;
                height: 48px;
                min-width: auto;
                width: auto;
                
                &:focus, &:hover{
                    opacity: 0.9;
                    &::before{
                        display: none;
                    }
                }

                @include media-breakpoint-down(md) {
                    width: 48px;
                }
            }
        }

        .helpButtonLabel {
            @include media-breakpoint-down(md) {
                border: 0;
                clip: rect(0, 0, 0, 0);
                height: 0;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 0;
            }
        }

        .embeddedServiceIcon {
            &::before{
                background-image: url("data:image/svg+xml,%3Csvg id='Laag_1' data-name='Laag 1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect width='24' height='24' fill='none'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg clip-path='url(%23clip-path)'%3E%3Cpath d='M14.16,15.37a8.56,8.56,0,0,0,3.44-2.5A5.33,5.33,0,0,0,17.6,6a8.46,8.46,0,0,0-3.44-2.5,12.09,12.09,0,0,0-4.73-.92,12.14,12.14,0,0,0-4.74.92A8.43,8.43,0,0,0,1.26,6,5.4,5.4,0,0,0,0,9.43a5.3,5.3,0,0,0,1,3A7.89,7.89,0,0,0,3.56,14.8c-.09.21-.18.41-.27.59A3.48,3.48,0,0,1,3,15.9l-.3.39a4.25,4.25,0,0,1-.35.4L2,17l-.06.06c0,.05-.05.07-.06.07l0,.07-.05.07,0,.07s0,.05,0,.08,0,0,0,.08a.29.29,0,0,0,0,.09.45.45,0,0,0,.43.39h0a10.78,10.78,0,0,0,1.15-.22,11.09,11.09,0,0,0,3.72-1.71,13.79,13.79,0,0,0,2.36.21A12.08,12.08,0,0,0,14.16,15.37Z' fill='%23fff'/%3E%3Cpath d='M23.05,15.86A5.23,5.23,0,0,0,23,9.78a7.92,7.92,0,0,0-2.74-2.39,6.78,6.78,0,0,1,.31,2,6.87,6.87,0,0,1-.9,3.4,8.82,8.82,0,0,1-2.57,2.84,12,12,0,0,1-3.53,1.73,14.2,14.2,0,0,1-4.14.6c-.27,0-.66,0-1.18-.05a11.3,11.3,0,0,0,6.32,1.76,13.79,13.79,0,0,0,2.36-.21,11.28,11.28,0,0,0,3.72,1.71,10.78,10.78,0,0,0,1.15.22.38.38,0,0,0,.3-.1.46.46,0,0,0,.17-.29c0-.05,0-.08,0-.09s0,0,0-.08l0-.08,0-.07,0-.08-.05-.06-.06-.07L22,20.45l-.3-.33c-.16-.17-.28-.31-.35-.4l-.3-.39a3.48,3.48,0,0,1-.34-.51c-.09-.18-.18-.38-.27-.59A7.8,7.8,0,0,0,23.05,15.86Z' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E");
                display: block;
                content: '';
                height: 24px;
                width: 24px;
            }
        }
    }

    .embeddedServiceSidebar {
        &.layout-docked {
            .dockableContainer {
                bottom: 3px;
            }
        }

        .embeddedServiceLiveAgentStateChatInputFooter{
            textarea {
                min-height: auto;
            }

            .chatActionButton {
                padding: 0 8px 0 8px;

                svg {
                    fill: #585652;
                }
            }
        }
    }

    .sidebarHeader {
        button.minimizeButton {
            cursor: pointer;
            padding-left: 0;
            padding-right: 0;
        }

        button.closeButton {
            cursor: pointer;
            padding-left: 0;
            padding-right: 0;
        }

        .slds-icon {
            height: 20px;
            width: 20px;
        }
    }

    .embeddedServiceSidebarButton {
        background-color: $liveagent-button-background-color !important;
        text-decoration: none !important;

        &:hover{
            &:hover {
                background-color: $liveagent-button-hover-background-color !important;
                color: $liveagent-text-color;
            }
        }

        &.uiButton--inverse {
            background-color: $liveagent-text-color !important;
            
            .label {
                color: $liveagent-button-hover-background-color !important;
            }

            &:hover{
                .label {
                    color: $liveagent-text-color !important;
                }
            }
        }
    }

    .embeddedServiceSidebarMinimizedDefaultUI {
        background-color: $liveagent-button-background-color;
        border: none;
        min-height: 48px;    
        text-decoration: none;
        width: auto;

        &.sidebarHeader{
            bottom: 15px;
            height: auto;
            border-radius: 3px;
        }
        
        .minimizedText{
            font-size: 0.875rem !important;
            text-decoration: none !important;
        }

        &.minimizedContainer {
            text-decoration: none !important;
            &:hover {
                background-color: $liveagent-button-hover-background-color;
            }
        }
    }
}