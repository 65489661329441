$notification-default-background:       rgba($primary-brand-color, .95) !default;
$notification-default-text:             $text-color-secondary !default;

$notification-success-background:       rgba($primary-brand-color, .95) !default;
$notification-success-text:             $text-color-secondary !default;

$notification-error-background:         rgba($error-color, .95) !default;
$notification-error-text:               $text-color-secondary !default;

.notification {
    background: $notification-default-background;
    bottom: 0;
    color: $notification-default-text;
    display: none;
    left: 0;
    min-height: 30px;
    opacity: 0;
    position: fixed;
    right: 0;
    top: auto;
    z-index: 1000;

    &__close {
        @include icon(close);
        background: transparent;
        border: 0;
        color: inherit;
        cursor: pointer;
        height: 0;
        padding: 0;
        position: absolute;
        right: $gutter / 2;
        top: 0;

        &::after {
            color: inherit;
        }
    }

    &__message {
        @include font-type(bold);
        color: inherit;
        padding-right: 25px;
        text-align: center;
    }

    &.is-success {
        background: $notification-success-background;
        color: $notification-success-text;
    }

    &.is-error {
        background: $notification-error-background;
        color: $notification-error-text;
    }
}
