$waardecheck-bullet-size: 20px !default;
$waardecheck-icon-name: check !default;
$waardecheck-icon-size: 20px !default;
$waardecheck-icon-color: $styleguide-green !default;
$modal-text-fontsize-mobile: 10px;
$modal-title-fontsize-mobile: 14px;

#loginSuccessModal {
    display: none;
    pointer-events: all;
}

.waardecheck-bullet-icon {
    @include icon($waardecheck-icon-name, after, $waardecheck-icon-size);
    margin-bottom: 23px;
    padding-left: $waardecheck-icon-size + 10px;
    position: relative;

    &::before,
    &::after {
        height: $waardecheck-icon-size;
        left: 0;
        position: absolute;
        top: 0;
        width: $waardecheck-icon-size;
    }

    &::before {
        background: $waardecheck-icon-color;
        border-radius: 50%;
        content: "";
        display: block;
    }

    &::after {
        color: $text-color-secondary;
        line-height: $waardecheck-icon-size;
        text-align: center;
        content: "\F111";
        font-family: "icon-font";
        font-weight: 400;
        font-size: 8px;
    }
}
p {
    &.modal-text {
        font-size: 13px;
        @include media-breakpoint-down(sm) {
            font-size: $modal-text-fontsize-mobile;
        }
    }
}
h4 {
    &.modal-title {
        font-weight: bold;
        font-size: 18px;
        @include media-breakpoint-down(sm) {
            font-size: $modal-title-fontsize-mobile;
        }
    }
}
