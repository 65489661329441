.brand-landing {
    .page-navigation {
        border-bottom: 1px solid $styleguide-grey-200;
        @include media-breakpoint-up(md) {
            border-bottom: 0;
        }
    }

    .brand-list {
        display: none;

        &.initiated {
            display: block;
        }
    }

    .letter-nav {
        @include make-col(12);
        display: flex;
        flex-wrap: wrap;
        margin: 10px 0 10px 0;
        padding-bottom: 10px;

        @include media-breakpoint-up(md) {
            border-bottom: 1px solid $styleguide-grey-200;
        }

        a {
            min-width: 24px;
            padding: 0 4px 8px 0;
            text-decoration: none;
            white-space: nowrap;
            
            &.inactive {
                opacity: .4;
                pointer-events: none;
            }
        }
    }

    .cat-nav {
        li {
            border-bottom: 0;
        }

        a {
            padding: 8px 25px 8px 0;
            text-decoration: none;
        }
    }
    
    .letter-section {
        @include make-col(12);
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin: 10px 0 5px;
        transition: opacity .5s ease;
        padding-bottom: 15px;

        @include media-breakpoint-up(md) {
            border-bottom: 1px solid $styleguide-grey-200;
            flex-direction: row;
            margin: 15px 0 10px;
        }

        &.unFocus {
            opacity: .3;
            pointer-events: none;
        }
    
        .letter-section-header {
            border-bottom: 1px solid $styleguide-grey-200;
            font-weight: bold;
            margin: 0 0 10px 0;
            padding: 0 0 5px 0;

            @include media-breakpoint-up(md) {
                border: 0;
                margin: 0;
                width: 10%;                
            }
        }
    
        .letter-section-brands {
            display: flex;
            flex-wrap: wrap;
            width: 90%;

            a {
                margin: 0 0 6px 0; 
                text-decoration: none;
                padding-right: 16px;
                width: 50%;

                @include media-breakpoint-up(lg) {
                    width: 33.333%;
                }
            }
        }
    }
}