$smallMobilePadding : 5;
$smallPadding : 10;
$mediumMobilePadding : 10;
$mediumPadding : 15;
$largeMobilePadding : 25;
$largePadding : 30;
.experience-component {
	.hs-none {
		.row {
			margin-left: 0;
			margin-right: 0;
		}

		[ class^="col-" ] {
			padding-left: 0;
			padding-right: 0;
		}
		
	}

	.vs-none {
		[ class^="col-" ] {
			margin-bottom: 0;
		}
	}

	.hs-small {
		.row {
			margin-left: -#{$smallMobilePadding}px;
			margin-right: -#{$smallMobilePadding}px;
			@include media-breakpoint-up(md) {
				margin-left: -#{$smallPadding}px;
				margin-right: -#{$smallPadding}px;
            }
		}

		[ class^="col-" ] {
			padding-left: #{$smallMobilePadding}px;
			padding-right: #{$smallMobilePadding}px;
			@include media-breakpoint-up(md) {
				padding-left: #{$smallPadding}px;
				padding-right: #{$smallPadding}px;
            }
		}
	}

	.vs-small {
		[ class^="col-" ] {
			margin-bottom: #{$smallMobilePadding*2}px;
			@include media-breakpoint-up(md) {
				margin-bottom: #{$smallPadding*2}px;
            }
		}
	}

	.hs-medium {
		.row {
			margin-left: -#{$mediumMobilePadding}px;
			margin-right: -#{$mediumMobilePadding}px;
			@include media-breakpoint-up(md) {
				margin-left: -#{$mediumPadding}px;
				margin-right: -#{$mediumPadding}px;
            }
		}

		[ class^="col-" ] {
			padding-left: #{$mediumMobilePadding}px;
			padding-right: #{$mediumMobilePadding}px;
			@include media-breakpoint-up(md) {
				padding-left: #{$mediumPadding}px;
				padding-right: #{$mediumPadding}px;
            }
		}
	}

	.vs-medium {
		[ class^="col-" ] {
			margin-bottom: #{$mediumMobilePadding*2}px;
			@include media-breakpoint-up(md) {
				margin-bottom: #{$mediumPadding*2}px;
            }
		}
	}

	.hs-large {
		.row {
			margin-left: -#{$largeMobilePadding}px;
			margin-right: -#{$largeMobilePadding}px;
			@include media-breakpoint-up(md) {
				margin-left: -#{$largePadding}px;
				margin-right: -#{$largePadding}px;
            }
		}

		[ class^="col-" ] {
			padding-left: #{$largeMobilePadding}px;
			padding-right: #{$largeMobilePadding}px;
			@include media-breakpoint-up(md) {
				padding-left: #{$largePadding}px;
				padding-right: #{$largePadding}px;
			}
		}
	}

	.vs-large {
		[ class^="col-" ] {
			margin-bottom: #{$largeMobilePadding*2}px;
			@include media-breakpoint-up(md) {
				margin-bottom: #{$largePadding*2}px;
			}
		}
	}
}