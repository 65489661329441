.blog-card {
    margin-left: 25px;
    margin-right: 70px;

    @include media-breakpoint-down(lg) {
        margin-left: 20px;
        margin-right: 20px;
    }

    .blog-content-card {
        &__heading{
            font-size: 4em;
    
            .arrow-icon {
                @include icon(arrow-right, 'after', .8em);
            }
    
            @include media-breakpoint-down(lg) {
                font-size: 3em;
            }
        }
    }

    .blog-content-card__body {
        font-weight: 500;
        text-align: justify;
        line-height: 1.3em;
        min-height: 7em;

        &.hascaps {
            text-transform: uppercase;
        }
    }
}

