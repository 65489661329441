@mixin collapse-reset {
    display: block !important;
    height: auto !important;
    overflow: visible !important;
}

[data-collapse="xs"] {
    @include media-breakpoint-up(sm) {

        .list-links__heading,
        .list-links__holder {
            @include collapse-reset;
        }
    }
}

[data-collapse="xs-sm"] {
    @include media-breakpoint-up(md) {

        .list-links__heading,
        .list-links__holder {
            @include collapse-reset;
        }
    }
}

[data-collapse="xs-sm-md"] {
    @include media-breakpoint-up(lg) {

        .list-links__heading,
        .list-links__holder {
            @include collapse-reset;
        }
    }
}

[data-collapse="xs-sm-md-lg"] {
    @include media-breakpoint-up(xl) {

        .list-links__heading,
        .list-links__holder {
            @include collapse-reset;
        }
    }
}

.collapse-indication {
    @include icon(arrow-down, before, 10);
    cursor: pointer;
    margin: 0;

    &.collapsed {
        @include icon(arrow-right, before, 10);
    }

    &::before {
        display: inline-block;
        margin-right: 4px;
        vertical-align: top;
    }
}

[class*='collapse__heading'][data-toggle="collapse"] {
    div:not([data-collapse^="xs"])>& {
        @include icon(arrow-up, before, .6em);
        cursor: pointer;
        padding-left: 20px;
        position: relative;

        &::before {
            display: block;
            left: 0;
            position: absolute;
            top: 0;
        }

        &.collapsed {
            @include icon(arrow-down, before);
        }
    }
}

[class*='collapse__heading--inverted'][data-toggle="collapse"] {

    div:not([data-collapse^="xs"])>& {
        @include icon(arrow-up, after);
        cursor: pointer;
        padding-left: 20px;
        position: relative;

        &::before,
        &::after {
            font-size: .6em;
        }

        &::before {
            display: none;
        }

        &::after {
            display: block;
            left: auto;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }

        &.collapsed {
            @include icon(arrow-down, after);
        }
    }
}

.collapse__heading--underline {
    border-bottom: $border-strong;
}

.collapse__heading--topline {
    border-top: 1px solid $border-color-strong;
}
