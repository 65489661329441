$locale-notification-background-color:          rgba($styleguide-white, .95) !default;
$locale-notification-text-color:                $text-color-primary !default;
$locale-toggler-unchecked:                      $styleguide-grey-400 !default;
$locale-toggler-button:                         $styleguide-white !default;
$locale-information-background:                 $locale-toggler-unchecked !default;
$locale-notification-modal-background:          rgba(0,0,0,0.5) !default;
$locale-always-active-color:                    $styleguide-grey-600 !default;
$locale-line-separator:                         $locale-notification-text-color !default;
$locale-label-border:                           rgba(0,0,0, 0.2) !default;
$locale-green:                                  $styleguide-green !default;
$locale-green-hover:                            $styleguide-green-dark !default;
$locale-buttons:                                $styleguide-grey-100;
$locale-default-padding: 40px;
$locale-country-border:                         1px solid $styleguide-grey-100;
// Overlay class appended to the body for the modal
.has-overlay {
    height: 100%;
    overflow-y: hidden;
}

// Modal container spanning full page width/height
.locale-notification-modal {
    align-items: center;
    background: $locale-notification-modal-background;
    bottom: 0;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    right: 0;
    top: 0;
    transition: .3s ease opacity;
    width: 100%;
    z-index: 30;

    // Appended to modal container when the page loads
    &.locale-show-modal {
        opacity: 1;
        pointer-events: all;
    }

    // Individual locale option, for example: analytical locales
    .locale__option {
        margin-bottom: 10px;
        width: 100%;

        // Toggle switch container
        label.switch-toggler {
            border-bottom: 2px solid $locale-label-border;
            min-height: 35px;
            position: relative;

            // Toggle switch
            .switch-slider {
                background-color: $locale-toggler-unchecked;
                border-radius: 35px;
                bottom: 0;
                cursor: pointer;
                display: inline-block;
                height: 21px;
                left: 0;
                position: relative;
                right: 0;
                top: 0;
                transition: .4s;
                width: $locale-default-padding;
                -webkit-transition: .4s;

                &::before {
                    background-color: $locale-toggler-button;
                    border-radius: 50%;
                    bottom: 3px;
                    content: "";
                    height: 15px;
                    left: 4px;
                    position: absolute;
                    transition: .4s;
                    width: 15px;
                    -webkit-transition: .4s;
                }
            }

            // Text within the label tag per locale input
            .locale-label {
                font-weight: 600;
                position: relative;

                .collapse-toggler-btn {
                    background: none;
                    border: 0;
                    border-radius: 50%;
                    cursor: pointer;
                    font-size: 20px;
                    line-height: 1;
                    padding: 0;
                    transform: rotate(-90deg);

                    &.active {
                        color: $locale-green;
                        transition: all .2s ease-in;
                        transform: rotate(0deg);
                    }

                    i {
                        font-weight: bold;
                    }

                    &:hover {
                        i {
                            color: $locale-green;
                            transition: color .2s ease-in;
                        }
                    }
                }

                span {
                    color: $locale-always-active-color;
                }
            }
        }

        input[type="checkbox"]:disabled {
            + .switch-toggler .switch-slider {
                opacity: 0.5;
            }
        }

        input[type="checkbox"]:checked {
            + .switch-toggler .switch-slider {
                background-color: $locale-green;
            }
        }

        input[type="checkbox"]:focus {
            + .switch-toggler .switch-slider {
                border: 2px solid $locale-green;

                &::before {
                    bottom: 1px;
                }
            }
        }

        input[type="checkbox"]:checked {
            + .switch-toggler {
                .switch-slider::before {
                    -ms-transform: translateX(17px);
                    transform: translateX(17px);
                    -webkit-transform: translateX(17px);
                }
            }
        }

        .input-checkbox__caption {
            &::before,
            &::after {
                display: none;
            }
        }

        input[type="checkbox"] {
            opacity: 0;
        }

        .locale-information {
            background: $locale-information-background;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
            padding: 10px 35px;
        }
    }

    // Modal buttons
    .btn-all-locales {
        background-color: $locale-green;
        color: $styleguide-white;
        margin-bottom: 10px;
        width: 100%;

        @include media-breakpoint-up(md) {
            height: 45px;
            margin: 0 0 0 15px;
            min-width: 45%;
        }

        &:hover {
            background-color: $locale-green-hover;
            transition: all .2s ease-in;
        }
    }

    .btn-select-locales,
    .btn-locale-settings {
        background-color: transparent;
        border: 1px solid $locale-green;
        color: $locale-green;
        width: 100%;

        @include media-breakpoint-up(md) {
            height: 45px;
            max-width: 30%;
        }

        &:hover {
            background-color: $locale-green;
            color: $styleguide-white;
            transition: all .2s ease-in;
        }
    }
}

// locale modal
.locale-notification {
    @include transition;
    background-color: $locale-notification-background-color;
    box-shadow: 0 1px 10px $locale-notification-modal-background;
    color: $locale-notification-text-color;
    display: block;
    margin-top: 15px;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 30;
    padding: 15px;
    min-width: 353px;
    @include media-breakpoint-up(lg) {
        margin-top: 0;
    }

    &__close {
        cursor: pointer;
        position: absolute;
        right: $gutter / 2;
        top: 20px;
    }

    &__text {
        @include font-type(small);
        padding-right: 25px;

        a {
            color: inherit;
            cursor: pointer;
            font: inherit;
            white-space: nowrap;
        }
    }

    &--hidden {
        transform: translateY(100%);

        @include media-breakpoint-up(lg) {
            transform: translate(-50%, 100%);
        }
    }
    &__close-btn-wrapper {
        display: flex;
        justify-content: end;
    }
    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        :first-child {
            font-weight: 400;
        }
        .flag-icon {
            width: 42px;
            margin: 20px 0 50px 0;
            border-radius: 50%;
        }
        .more-options {
            padding-bottom: 30px;
        }
    }

    &__btn-wrapper {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding-bottom: $locale-default-padding;
        button {
            font-weight: 400;
        }
        .flag-icon {
            width: 20px;
            margin: 0px 0px 0px 10px;
            border-radius: 50%;
        }
        .btn, a.btn {
            font-size: 12px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: $locale-buttons;
            text-transform: none;
            color: $locale-notification-text-color;
        }
        a span:last-child{
            font-weight: 600;
        }
        a span:nth-child(1) {
            text-align: start;
        }
    }
    .country-links-container {
        padding: 37px 50px;
        h2 {
            margin: 0px 20px 30px;
            text-align: center;
        }
        a {
            display: flex;
            align-items: center;
            gap: 25px;
            text-decoration: none;
            padding: 20px 35px;

            .flag-icon {
                width: 32px;
                border-radius: 50%;
            }
            span {
                margin-right: 20px;
                font-weight: 600;
            }
            @include media-breakpoint-down(md) {
                font-size: 12px;
            }
        }
        > a:nth-of-type(1),
        > a:nth-of-type(2) {
            border-bottom: $locale-country-border;
        }
    }
}

