// Global transition settings & mixin
$transition-time: .3s !default;
$transition-prop: all !default;
$transition-type: ease !default;

@mixin transition($prop: $transition-prop, $time: $transition-time, $type: $transition-type) {
	transition: $prop $time $type;
}

// Keyframe animations
@keyframes pulse {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
		transform: scale(2) translate(-25%, -25%);
	}
}

// Animates the label in an input field shifting it up or down based on focus state of the field
@mixin animate-input-label {
	// Animate input labels for all forms except the search form
	form:not([name="simpleSearch"]) {

		// Hide all placeholders as we want to use labels instead
		input::placeholder {
			opacity: 0; // Display none is overwritten by display block as its a browser inline style on the placeholder by default
		}

		.form-group {
			position: relative;

			&.remember-me {
				position: static;
			}
		}

		label {
			&.label-animation {
				font-size: inherit;
				left: 0;
				padding: 10px 0 0 19px;
				position: absolute;
				top: 0; // needs to be set for the transition to be smooth like butter
				transition: all .3s ease-in-out;
				transition-property: top, font-size;
			}
		}

		.active-label {
			padding-top: 10px;
			transition: padding-top .2s ease-in-out;

			& ~ label { // using ~ to select label for when the Lastpass icon is in between
				color: $styleguide-grey-600;
				font-size: 10px;
				top: -7px;
			}
		}

		input:not(:placeholder-shown) {
			padding-top: 10px;
			transition: none;

			& ~ .label-animation { // using ~ to select label for when the Lastpass icon is in between
				color: $styleguide-grey-600;
				font-size: 10px;
				top: -7px;
			}
		}

		input:-webkit-autofill{
			padding-top: 10px;
			transition: none;

			& ~ label { // using ~ to select label for when the Lastpass icon is in between
				color: $styleguide-grey-600;
				font-size: 10px;
				top: -7px;
			}
		}

		input:autofill {
			padding-top: 10px;
			transition: none;

			& ~ label { // using ~ to select label for when the Lastpass icon is in between
				color: $styleguide-grey-600;
				font-size: 10px;
				top: -7px;
			}
		}
	}
}
