html,
body {
    &.scroll-lock {
        min-height: 100vh;
        min-width: 100vw;
        overflow: hidden;
        position: fixed;
    }
}

body {
    @include font-type(default);
    background: $background-color;
    color: $text-color-primary;
    min-width: $site-min-width;
    -webkit-font-smoothing: antialiased;

    // Useful to keep your scroll position. See https://css-tricks.com/almanac/properties/o/overflow-anchor/
    // A usecase for this is to add this class after clicking on a PLP 'load more' button which loads products above the 'load more' button
    &--overflow-anchor-none {
        overflow-anchor: none;
    }
}

a {
    &[href],
    &:not([href]).link {
        cursor: pointer;
        text-decoration: underline;

        &:hover,
        &:focus,
        &:active {
            text-decoration: none;
        }
    }

    &.read-more {
        color: $primary-brand-color;
        text-decoration: none;

        &::after {
            content: '»'
        }

        &:hover,
        &:focus,
        &:active {
            text-decoration: underline;
        }
    }
    
    &.btn-use-my-location {
        @include icon(location, before, 22px);
        color: $primary-brand-color;
        font-size: 12px;
        letter-spacing: 1.8px;
        text-decoration: none;
        text-transform: uppercase;

        &::before {
            display: inline-block;
            margin-right: 2px;
            vertical-align: middle;
        }
    }
}

.container,
.width-container {
    max-width: $site-max-width;
}

.container-fluid {
    position: relative;
}
