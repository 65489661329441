// Component variables
$navbar-toggler-label-color:            $styleguide-black !default;
$navbar-toggler-icon-color--inactive:   $styleguide-black !default;
$navbar-toggler-icon-color--active:     $styleguide-black !default;
$navbar-toggler-label-text-transform:   uppercase !default;

.navbar-toggler {
  display: flex;
  flex-direction: column;
  width: 40px;
  height: auto;
  line-height: 1;
}

.navbar-toggler-icon {
  height: 20px;
  width: 22px;
  position: relative;
  span {
    top: 0px;
    &::before {
      top: 6px;
    }
    &::after {
      top: 12px;
    }
  }

  &.active {
    span {
      &::after{
        transform: rotate(-45deg) translateY(3px) translateX(-3px);
      }
      &::before{
        transform: rotate(45deg) translateY(3px) translateX(3px);
      }
    }
  }
}

.navbar-toggler-label {
  color: $navbar-toggler-label-color;
  display: block;
  font-size: 8px;
  line-height: 1;
  opacity: 1;
  text-transform: $navbar-toggler-label-text-transform;
  visibility: visible;
}