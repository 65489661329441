@import '~plugin_frontend_core/variables'; // Necessary for getting our variables that are used in `header-variables`
@import '~plugin_navigation/components/header/header-variables';

// Header - Logo
$primary-header-logo-height-mobile:                 $primary-header-height-mobile - 8px;
$primary-header-logo-height-desktop:                $primary-header-height-desktop - 8px;

// Header - USP bar
$tertiary-header-height-mobile:                     30px;
$tertiary-header-height-desktop:                    $tertiary-header-height-mobile;
$usp-list-item-line-height:                         $tertiary-header-height-mobile;
$usp-list-item-height:                              $tertiary-header-height-mobile;
