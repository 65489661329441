$usp-list-item-font-color:                        $styleguide-white !default;
$usp-list-item-line-height:                       normal !default;
$usp-list-item-height:                            auto !default;

.header-usp {
  ul {
    @include list-reset;
    position: relative;
  }

  li {
    @include transition(.5s, opacity);
    @include font-type(headerUsps);

    color: $usp-list-item-font-color;
    height: $usp-list-item-height;
    line-height: $usp-list-item-line-height !important; // needs important in order to override the font line-height
    left: 50%;
    opacity: 0;
    position: absolute;
    top: -50%;
    transform: translate(-50%, 0);
    white-space: nowrap;

    * {
      color: inherit; // iPhone converts phone numbers to links, with blue link styling
    }

    &.active {
      opacity: 1;
    }
  }
}
