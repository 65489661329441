hr {
  border-color: $border-color-light;

  &.color--medium {
    border-color: $border-color-medium;
  }

  &.color--strong {
    border-color: $border-color-strong;
  }

  &.size--medium {
    border-width: 2px 0 0;
  }
}
