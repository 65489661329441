@mixin generateOrder() {
    @for $i from 1 through $order-range {
        &-#{$i} {
            order: #{$i}
        }
    }
}

.order {
    @include generateOrder();

    @each $breakpoint, $value in $grid-breakpoints {
        &-#{$breakpoint} {
            @include media-breakpoint-up($breakpoint) {
                @include generateOrder();
            }
        }
    }
}
