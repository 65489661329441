// Component variables
$nav-border:                            $border-medium !default;
$nav-link-color:                        $text-color-primary !default;
$nav-link-background-color:             $styleguide-transparent !default;
$nav-link-icon-size:                    8px !default;
$nav-link-text-decoration--inactive:    none !default;
$nav-link-text-decoration--active:      none !default;
$nav-background-color:                  $styleguide-white !default;

$searchbar-background-color:            $styleguide-white !default;
$search-background-color:               $styleguide-grey-300 !default;
$search-background-color--active:       $styleguide-transparent !default;
$search-border:                         $border !default;
$search-text-color:                     $text-color-primary !default;


// Component styles
@include media-breakpoint-down(md) {
    .site-header {
        .menu-toggleable-right {
            @include transition(left, .4s);
            background-color: $primary-header-background-color-mobile;
            bottom: 0;
            box-shadow: $secondary-header-box-shadow;
            left: 100%;
            min-width: $site-min-width;
            overflow: scroll;
            overflow-x: hidden;
            position: fixed;
            top: $primary-header-height-mobile + $tertiary-header-height-mobile;
            z-index: 1;

            &.active {
                left: 0;
            }
        }

        .multilevel-dropdown {
            .search {
                background: $searchbar-background-color;
                padding: 20px ($gutter / 2);

                &__icon {
                    position: absolute;
                    right: 2.5%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }

                .search-field {
                    background: $search-background-color;
                }
            }

            .collapse,
            .collapsing {
                background: $search-background-color;
                bottom: 0;
                display: block;
                height: auto;
                left: 100%;
                overflow: auto;
                position: absolute;
                right: 0;
                top: 0;
                transition: .3s ease left;
                z-index: 2;

                &.show {
                    left: 0;

                    &::after {
                        background: $nav-background-color;
                        bottom: 0;
                        content: '';
                        display: block;
                        left: 0;
                        position: fixed;
                        right: 0;
                        top: 0;
                        z-index: -1
                    }
                }
            }

            .nav-link {
                &--has-children {
                    @include icon(arrow-left, after, $nav-link-icon-size);
                }

                &.collapsed {
                    @include icon(arrow-right, after, $nav-link-icon-size);
                }
            }

            .nav-level-1,
            .nav-level-2,
            .nav-level-3 {
                @include transition($time: .4s);
                border: 0;
                border-radius: 0;
                min-width: 0;
                overflow: auto;
                padding-bottom: 30px;

                &-item {
                    background: none;
                    border: $nav-border;
                    border-width: 0 0 1px;
                    padding: 0;
                    position: static;

                    &.has-subitems {
                        .nav-item-toggler {
                            @include icon(arrow-left, after, $nav-link-icon-size);
                            color: $nav-link-color;
                            cursor: pointer;
                            padding: 12px ($gutter / 2) 14px $gutter; // Add a bit of extra "hit area" space on the left
                            position: absolute;
                            right: 0;
                            top: 0;
                            z-index: 1;

                            &.nav-item--back {
                                left: 0;
                                padding: 12px $gutter 14px ($gutter / 2); // Add a bit of extra "hit area" space on the left
                                right: auto;
                            }

                            &.collapsed {
                                @include icon(arrow-right, after, $nav-link-icon-size);
                            }
                        }
                    }

                    &:first-child {
                        border-width: 1px 0;
                    }
                }
            }

            .nav-link {
                @include font-type(navigation-normal-link);
                color: $nav-link-color;
                display: block;
                padding: ($gutter / 2);
                text-decoration: $nav-link-text-decoration--inactive;

                &:hover {
                    background-color: $nav-link-background-color;
                    text-decoration: $nav-link-text-decoration--active;
                }

                &.nav-link--back {
                    padding: 15px ($gutter / 2) 15px ($gutter + 10px);
                }
            }
        }
    }

    .modal-background {
        &.active {
            display: block;
        }
    }
}
